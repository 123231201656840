package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
@Serializable
data class UpdateUserDataResponse(
    val data: UpdateUserData,
)
@Serializable
data class UpdateUserData(
    @SerialName("_id")
    val id: String = "",
    @SerialName("_rev")
    val rev: String = "",
    val coachName: String = "",
    val email: String = "",
    val firstName: String = "",
    val lastName: String = "",
    val organization: String = "",
    val role: String = "",
    val type: String = "",
    val nationality: String = "",
    val ethnicity : String = "",
    val height: String = "",
    val age: String = "",
    val weight: String = "",
    val job: String = "",
    val number: String = "",
    val position: String = "",
    val level: String = "",
    val secondaryPosition: String = "",
    val foot: String = "",
    val uid: String = "",
    @SerialName("userID")
    val userId: String = "",
)