package org.example.zone360.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.theme.Palette
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button

@Composable
fun CommonButton(
    isSecondary: Boolean = false,
    text: String? = null,
    backgroundColor: com.varabyte.kobweb.compose.ui.graphics.Color? = null,
    borderColor: com.varabyte.kobweb.compose.ui.graphics.Color? = backgroundColor,
    textColor: com.varabyte.kobweb.compose.ui.graphics.Color? = null,
    textSpanColor: com.varabyte.kobweb.compose.ui.graphics.Color? = null,
    textSpanFontWeight: Int = 400,
    progressIndicatorColor: com.varabyte.kobweb.compose.ui.graphics.Color? = null,
    onCLick: () -> Unit,
    isLoading: Boolean = false,
    pointerCursor: Boolean = true,
    width: CSSLengthOrPercentageNumericValue = 350.px,
    height: CSSLengthOrPercentageNumericValue = 44.px,
    byPassEmailVerification: Boolean = true,
    icon: (@Composable () -> Unit)? = null,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    // Determine the final colors to use
    val resolvedBackgroundColor = backgroundColor ?: if (isSecondary) {
        sitePalette.commonSecondaryButtonColor.rgb
    } else {
        sitePalette.primary.rgb
    }

    val resolvedTextColor = textColor ?: if (isSecondary) {
        sitePalette.textFieldTextColor.rgb
    } else {
        Palette.White.rgbLight
    }

    val resolvedTextSpanColor = textSpanColor ?: resolvedTextColor

    Button(
        attrs = Modifier
            .width(width)
            .minHeight(height)
            .margin(bottom = 2.px, top = 2.px)
            .backgroundColor(resolvedBackgroundColor)
            .color(resolvedTextColor)
            .border(color = borderColor,  width = 1.px, style = LineStyle.Solid)
            .borderRadius(r = 6.px)
            .fontWeight(FontWeight.Normal)
            .fontSize(13.px)
            .fontFamily(Constants.FONT_FAMILY)
            .cursor(if(pointerCursor)Cursor.Pointer else Cursor.Default)
            .onClick {
                println("button clicked")
                onCLick()
            }
            .toAttrs()
    ) {
        if (isLoading) {
            Box(
                modifier = Modifier.fillMaxWidth(),
                contentAlignment = Alignment.Center
            ) {
                ProgressIndicator(color = progressIndicatorColor)
            }
        } else {
            Row(
                modifier = Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                icon?.let {
                    it()
                    CustomSpacer(width = 10.px)
                }
                SpanText(
                    text = text!!,
                    modifier = Modifier.color(resolvedTextSpanColor)
                        .fontWeight(textSpanFontWeight).fontFamily(Constants.FONT_FAMILY)
                )
            }
        }
    }
}


//
@Composable
fun CommonButtonIcon(
    isSecondary: Boolean = false,
    onCLick: () -> Unit,
    icon: @Composable () -> Unit,
    isLoading: Boolean = false,
    width: CSSLengthNumericValue = 50.px,
    marginLeft: CSSLengthNumericValue = 0.px,
    marginRight: CSSLengthNumericValue = 0.px,
) {
    val sitePalette = ColorMode.current.toSitePalette()

    Button(
        attrs = Modifier
            .width(width)
            .height(44.px)
            .margin(bottom = 3.px, left = marginLeft, right = marginRight)
            .backgroundColor(if (isSecondary) sitePalette.commonSecondaryButtonColor.rgb else Palette.White.rgbLight)
            .color(if (isSecondary) sitePalette.textFieldTextColor.rgb else Palette.White.rgbLight)
            .border(color = sitePalette.primary.rgb)
            .borderRadius(r = 6.px)
            .fontWeight(FontWeight.Normal)
            .fontSize(13.px)
            .fontFamily(Constants.FONT_FAMILY)
            .cursor(Cursor.Pointer)
            .onClick {
                if (!isLoading) {
                    println("button clicked")
                    onCLick()
                }


            }
            .toAttrs()
    ) {
        if (isLoading) Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            ProgressIndicator()
        } else
            icon()
    }
}