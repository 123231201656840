package org.example.zone360.pages.players_Details

import PlayerInfo
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.stevdza.san.kotlinbs.models.ToastPlacement
import com.stevdza.san.kotlinbs.models.ToastStyle
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaArrowLeft
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import getSinglePlayer
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.components.BSToastBasicCustom
import org.example.zone360.components.BSToastGroupCustom
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetSinglePlayerData
import org.example.zone360.pages.players.VideosSection
import org.example.zone360.pages.players_Details.assistAI.AssistAI
import org.example.zone360.pages.players_Details.coachesRecommendation.CoachRecommendation
import org.example.zone360.pages.players_Details.playerRecentAssessment.PlayersRecentAssessment
import org.example.zone360.sections.DashboardHeaderSection
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px


@Page("/player-details")
@Composable
fun PlayersDetails() {
    val ctx = rememberPageContext()
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val sitePalette = ColorMode.current.toSitePalette()

//
    var getPlayerInfo by remember { mutableStateOf(GetSinglePlayerData()) }
    var message by remember { mutableStateOf("") }
    val userVideos = remember { mutableStateListOf<String>() }

//
    val params = ctx.route.params
    val imageUrl = params["imageUrl"] ?: ""
    val userId = params["userId"] ?: AppGlobal.userData.uid

//
    var languageType by remember { mutableStateOf(SelectedLanguage.English.name) }
    var sessionDataType by remember { mutableStateOf(SelectedData.Training.name) }



//
    var selectedLanguage by remember {
        mutableStateOf(
            when (localStorage.getItem("selectedLanguage")) {
                "it" -> SelectedLanguage.Italian
                else -> SelectedLanguage.English
            }
        )
    }
    var selectedDataType by remember {
        mutableStateOf(
            when (localStorage.getItem("selectedDataType")) {
                "match" -> SelectedData.Matches
                else -> SelectedData.Training
            }
        )
    }
    scope.launch {
        val getPlayerResponse = getSinglePlayer(
            domainType = "soccer",
            language = "en",
            userID = userId
        )
        getPlayerInfo = getPlayerResponse.response
        userVideos.clear()
        userVideos.addAll(getPlayerResponse.response.userVideos.map { "${Res.Audio.base_Audio_URL}$it" })
    }
    LaunchedEffect(selectedDataType, selectedLanguage) {
        val languageCode = when (selectedLanguage) {
            SelectedLanguage.English -> "en"
            SelectedLanguage.Italian -> "it"
        }
        languageType = languageCode

        localStorage.setItem("selectedLanguage", languageCode)
        println("selectedLanguage: $selectedLanguage")
        val sessionType = when (selectedDataType) {
            SelectedData.Training -> "training"
            SelectedData.Matches -> "match"
        }
        sessionDataType = sessionType

        localStorage.setItem("selectedDataType", sessionType)
        println("sessionType: $sessionType")

    }
    Box(Modifier.fillMaxSize()) {
        Column(
            Modifier.fillMaxSize().backgroundColor(sitePalette.bodyBackground.rgb)
                .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Auto)
                .id(Res.Id.playersPage)
        ) {

            BSToastGroupCustom(
                placement = ToastPlacement.TopRight,
            ) {
                BSToastBasicCustom(
                    id = "toastSuccess",
                    text = message,
                    style = ToastStyle.Success,
                    autoHide = true,
                    closeButtonDark = false,
                    onCloseClick = {}
                )
                BSToastBasicCustom(
                    id = "toastError",
                    text = message,
                    style = ToastStyle.Danger,
                    autoHide = true,
                    closeButtonDark = false,
                    onCloseClick = {}
                )
            }

            DashboardHeaderSection()
            Column(
                modifier = Modifier.fillMaxHeight()
                    .fillMaxWidth(if (window.screen.width > 1800) 85.percent else 100.percent)
                    .padding(leftRight = if (breakpoint >= Breakpoint.LG) 44.px else 20.px)
                    .align(Alignment.CenterHorizontally),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().margin(top = 30.px, bottom = 10.px),
                    verticalAlignment = Alignment.CenterVertically,
                ) {
                    if (AppGlobal.userData.role != "player") {
                        FaArrowLeft(
                            modifier = Modifier.onClick {
                                if (window.history.length > 1) {
                                    window.history.back()
                                } else {
                                    ctx.router.navigateTo("/home")
                                }

                            }
                                .cursor(Cursor.Pointer)
                        )
                    }
                    FieldTitleTextNormalSimple(
                        text = "Players Details",
                        fontWeight = 500,
                        fontSize = 16.px,
                        marginLeft = 15.px
                    )
                }
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .borderRadius(16.px)
                        .padding(topBottom = 16.px, leftRight = 24.px)
                        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                ) {

                    Row(
                        modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                            .flexWrap(FlexWrap.Wrap),
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        PlayerInfo(breakpoint, sitePalette, userId, imageUrl, getPlayerInfo)
                        VideosSection(
                            breakpoint,
                            sitePalette,
                            userId,
                            message = { message = it },
                            userVideos
                        )
                    }
                    Row(
                        modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                            .flexWrap(FlexWrap.Wrap),
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {

                        Column(
                            modifier = Modifier
                                .width(
                                    if (breakpoint >= Breakpoint.LG) 68.percent
                                    else if (breakpoint > Breakpoint.MD) 54.percent else  100.percent
                                )
                                .gap(24.px)
                        ) {
//                            PlayerInfo(breakpoint, sitePalette, userId ,imageUrl, getPlayerInfo)
                            PlayersRecentAssessment(breakpoint,
                                sitePalette, userId,
                                message = { message = it }, getPlayerInfo = getPlayerInfo)
                            CoachRecommendation(breakpoint, sitePalette, getPlayerInfo)
                        }

                        Column(modifier = Modifier
                            .width(
                                if (breakpoint >= Breakpoint.LG) 31.percent
                                else if (breakpoint > Breakpoint.MD) 44.percent
                                else 100.percent)
                            .gap(24.px)) {
//                            VideosSection(breakpoint, sitePalette, userId, message = {message = it}, userVideos)
                            AssistAI(breakpoint, sitePalette, selectedDataType, selectedLanguage,  getPlayerInfo)}

                    }

                }
            }
        }
    }
}
