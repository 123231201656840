package org.example.zone360.pages.dashboard.playerAssessment

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import createPlayer
import getPlayerAggregate
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CommonDropdown
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.InputField
import org.example.zone360.components.showToast
import org.example.zone360.componentss.FieldTitleTextNormal
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetAllTeamsData
import org.example.zone360.models.GetPlayerData
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.AppGlobal.Companion.clubPlayersList
import org.example.zone360.utils.Res
import org.example.zone360.utils.isValidEmail
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLInputElement

@Composable
fun AddNewPlayer(onDialogDismiss: (Long) -> Unit,
                 breakpoint: Breakpoint,
                 modalNumber: Long,
                 toastMessageCall: (String) -> Unit,
                 teamData: List<GetAllTeamsData>,
                 onPlayerAdded: (List<GetPlayerData>) -> Unit) {
    val scope = rememberCoroutineScope()
    val sitePalette = ColorMode.current.toSitePalette()
    var isLoading by remember { mutableStateOf(false) }
    var serverError by remember { mutableStateOf("") }
    var isError by remember { mutableStateOf(false) }
    var isEmailExistError by remember { mutableStateOf(false) }

    var selectedTeam by remember { mutableStateOf("Select Team") }


    Box(
        modifier = Modifier
            .width(if (breakpoint < Breakpoint.MD) 400.px else 550.px)
            .height(620.px)
            .id("add-Player-Modal-$modalNumber")
            .position(Position.Fixed)
            .zIndex(9)
            .top(50.percent)
            .left(50.percent)
            .transform { translate((-50).percent, (-50).percent) },
        contentAlignment = Alignment.Center,
    ) {
        Box(
            modifier = Modifier
                .width(90.percent)
                .height(90.percent)
                .onClick { }
        )
        Box(
            modifier = Modifier
                .padding(all = 24.px)
                .width(90.percent)
                .margin(top = 10.px)
                .height(90.percent)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .border(
                    width = 1.px,
                    color = sitePalette.textFieldBorderColor.rgb,
                    style = LineStyle.Solid
                )
                .borderRadius(4.px)
                .overflow(Overflow.Auto),
            contentAlignment = Alignment.Center,
        ) {
            Column(modifier = Modifier.fillMaxSize()) {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 20.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Column {
                        FieldTitleTextNormalSimple(
                            "Add New Player",
                            fontSize = 20.px,
                            fontWeight = 600,
                            onClick = {}
                        )
                    }

                    FaXmark(
                        modifier = Modifier
                            .color(sitePalette.commonButtonSupportiveTextColor.rgb)
                            .cursor(Cursor.Pointer)
                            .onClick { onDialogDismiss(modalNumber) },
                        size = IconSize.LG
                    )

                }
                Column(
                    Modifier.fillMaxWidth()
                        .maxHeight(550.px)
                        .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden),
                    verticalArrangement = Arrangement.Center
                ) {
                    FieldTitleTextNormal(text = "Email", onClick = {})
                    InputField(
                        errorText = "Please enter an email (e.g. name@domain.com).",
                        isError = isEmailExistError || isError,
                        inputType = InputType.Email,
                        placeHolder = "",
                        marginBottom =  3.px,
                        id = "${Res.Id.emailInput}-$modalNumber",
                        width = if (breakpoint < Breakpoint.MD) 290.px else 430.px
                    )
                     CustomSpacer(height = 20.px)
                    FieldTitleTextNormal(text = "First Name", onClick = {})
                    InputField(
                        errorText = "Please enter first name",
                        isError = isError,
                        inputType = InputType.Text,
                        placeHolder = "",
                        marginBottom =  3.px,
                        id = "${Res.Id.firstName}-$modalNumber",
                        width = if (breakpoint < Breakpoint.MD) 290.px else 430.px
                    )
                    CustomSpacer(height = 20.px)

                    FieldTitleTextNormal(text = "Last Name", onClick = {})
                    InputField(
                        inputType = InputType.Text,
                        placeHolder = "",
                        marginBottom = 3.px,
                        errorText = "Please enter last name",
                        isError = isError,
                        id = "${Res.Id.lastName}-$modalNumber",
                        width = if (breakpoint < Breakpoint.MD) 290.px else 430.px

                    )
                    CustomSpacer(height = 20.px)

                    FieldTitleTextNormal(text = "Coach", onClick = {})
                    InputField(
                        inputType = InputType.Text,
                        placeHolder = "",
                        marginBottom =  3.px,
                        errorText = "Please enter coach name",
                        isError = isError,
                        id = "${Res.Id.coachName}-$modalNumber",
                        width = if (breakpoint < Breakpoint.MD) 290.px else 430.px

                    )
                    CustomSpacer(height = 20.px)

                    Box (modifier = Modifier.margin(bottom = 20.px)){
                        CommonDropdown(
                            data = teamData.map { it.teamName },
                            onSelect = { selectedTeam = it },
                            selectedItem = selectedTeam,
                            width = if (breakpoint < Breakpoint.MD) 290.px else  430.px,
                        )
                    }

                }


                Box {
                    CommonButton(
                        byPassEmailVerification = true,
                        text = "Save",
                        isLoading = isLoading,
                        onCLick = {
                            scope.launch {
                                val emailInput =
                                    (document.getElementById("${Res.Id.emailInput}-$modalNumber") as HTMLInputElement).value
                                val firstNameInput =
                                    (document.getElementById("${Res.Id.firstName}-$modalNumber") as HTMLInputElement).value
                                val lastNameInput =
                                    (document.getElementById("${Res.Id.lastName}-$modalNumber") as HTMLInputElement).value
                                val coachNameInput =
                                    (document.getElementById("${Res.Id.coachName}-$modalNumber") as HTMLInputElement).value

                                // Reset errors before validation
                                isError = false
                                isEmailExistError = false

                                when {
                                    emailInput.isEmpty() -> {
                                        isError = true
                                    }
                                    !isValidEmail(email = emailInput) -> {
                                        isError = true
                                    }
                                    firstNameInput.isEmpty() -> {
                                        isError = true
                                    }

                                    lastNameInput.isEmpty() -> {
                                        isError = true
                                    }

                                    coachNameInput.isEmpty() -> {
                                        isError = true
                                    }

                                    else -> {
                                        isLoading = true
                                        isError = false

                                        try {
                                            val response = createPlayer(
                                                email = emailInput,
                                                firstName = firstNameInput,
                                                lastName = lastNameInput,
                                                imageUrl = "",
                                                organization = AppGlobal.userData.organization,
                                                coachName = coachNameInput,
                                                team = selectedTeam
                                            )
                                            println("response: $response")
                                            if (response.data!="") {
                                                val updatedPlayers = getPlayerAggregate(
                                                    organization = AppGlobal.userData.organization,
                                                    domainType = "soccer",
                                                    language = "en",

                                                    )
                                                onPlayerAdded(updatedPlayers.data)
                                                println("Sending updated player list with ${updatedPlayers.data.size} players")
                                                toastMessageCall("Player created successfully")
                                                showToast("toastSuccess")

                                                onDialogDismiss(modalNumber)
                                            } else {
                                                isError = true
                                                serverError =response.error
                                            }
                                        } catch (e: Exception) {
                                            console.error("Error creating player:", e)
                                            isError = true
                                            serverError =
                                                "Failed to create player. Please try again."
                                        } finally {
                                            isLoading = false
                                        }
                                    }
                                }
                            }
                        },
                        width = if (breakpoint < Breakpoint.MD) 290.px else 430.px,
                        height = 45.px
                    )
                }
                Row (modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center){
                        FieldTitleTextNormal(
                            text = serverError,
                            color = sitePalette.textErrorColor.rgb,
                            fontSize = 12.px,
                            marginTop = 10.px,
                            textAlign = TextAlign.Center,
                            onClick = {})
                }
            }
        }
    }
}