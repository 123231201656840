package org.example.zone360.pages.dashboard.teamSection

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import createClubTeam
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.InputField
import org.example.zone360.components.showToast
import org.example.zone360.componentss.FieldTitleTextNormal
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.theme.SitePalette
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Res
import org.example.zone360.utils.isValidEmail
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.HTMLInputElement

@Composable
fun CreateClubTeam(
    onDialogDismiss: (Long) -> Unit,
    breakpoint: Breakpoint,
    modalNumber: Long,
    sitePalette: SitePalette,
    toastMessageCall: (String) -> Unit,
){
    val scope = rememberCoroutineScope()
    var isLoading by remember { mutableStateOf(false) }
    var serverError by remember { mutableStateOf("") }
    var isError by remember { mutableStateOf(false) }
    Box(
        modifier = Modifier
            .width(if (breakpoint < Breakpoint.MD) 350.px else 450.px)
            .height(280.px)
            .id("create-Club-Team$modalNumber")
            .position(Position.Fixed)
            .zIndex(9)
            .top(50.percent)
            .left(50.percent)
            .transform { translate((-50).percent, (-50).percent) },
        contentAlignment = Alignment.Center,
    ) {
        Box(
            modifier = Modifier
                .width(90.percent)
                .height(90.percent)
                .onClick { }
        )
        Box(
            modifier = Modifier
                .padding(all = 24.px)
                .width(90.percent)
                .margin(top = 10.px)
                .height(90.percent)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .border(
                    width = 1.px,
                    color = sitePalette.textFieldBorderColor.rgb,
                    style = LineStyle.Solid
                )
                .borderRadius(4.px)
                .overflow(Overflow.Auto),
            contentAlignment = Alignment.Center,
        ) {
            Column(modifier = Modifier.fillMaxSize()) {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 20.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Column {
                        FieldTitleTextNormalSimple(
                            "Create Team",
                            fontSize = 20.px,
                            fontWeight = 600,
                            onClick = {}
                        )
                    }

                    FaXmark(
                        modifier = Modifier
                            .color(sitePalette.commonButtonSupportiveTextColor.rgb)
                            .cursor(Cursor.Pointer)
                            .onClick { onDialogDismiss(modalNumber) },
                        size = IconSize.LG
                    )

                }
                Column(
                    Modifier.fillMaxWidth()
                        .maxHeight(550.px)
                        .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden),
                    verticalArrangement = Arrangement.Center
                ) {

                    FieldTitleTextNormal(text = "Team Name", onClick = {})
                    InputField(
                        errorText = "Please enter team name",
                        isError = isError,
                        inputType = InputType.Text,
                        placeHolder = "",
                        marginBottom =  3.px,
                        id = "${Res.Id.teamName}-$modalNumber",
                        width = if (breakpoint < Breakpoint.MD) 240.px else 350.px
                    )
                    CustomSpacer(height = 20.px)

                }

                Box {
                    CommonButton(
                        byPassEmailVerification = true,
                        text = "Save",
                        isLoading = isLoading,
                        onCLick = {
                            scope.launch {
                                val teamName =
                                    (document.getElementById("${Res.Id.teamName}-$modalNumber") as HTMLInputElement).value

                                isError = false

                                when {

                                    else -> {
                                        isLoading = true
                                        isError = false

                                        try {
                                            val response = createClubTeam(
                                                clubName = AppGlobal.userData.organization,
                                                teamName = teamName

                                            )
                                            println("response: $response")
                                            if (response.data!="") {
                                                toastMessageCall("Team added successfully")
                                                showToast("toastSuccess")
                                                onDialogDismiss(modalNumber)
                                            } else {
                                                isError = true
                                                toastMessageCall("Team added Failed")
                                                showToast("toastError")
                                            }
                                        } catch (e: Exception) {
                                            console.error("Error adding team:", e)
                                            isError = true
                                            serverError =
                                                "Failed to create team. Please try again."
                                        } finally {
                                            isLoading = false
                                        }
                                    }
                                }
                            }
                        },
                        width = if (breakpoint < Breakpoint.MD) 240.px else 350.px,
                        height = 45.px
                    )
                }
                Row (modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center){
                    FieldTitleTextNormal(
                        text = serverError,
                        color = sitePalette.textErrorColor.rgb,
                        fontSize = 12.px,
                        marginTop = 10.px,
                        textAlign = TextAlign.Center,
                        onClick = {})
                }
            }
        }
    }
     
}