package org.example.zone360.pages.dashboard.teamSection

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.FaTrash
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import getAllTeams
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CustomDivider
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetAllTeamsData
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.AppGlobal.Companion.teamDataList
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun TeamSections(
       breakpoint: Breakpoint,
       sitePalette: SitePalette,
       teamData : List<GetAllTeamsData>,
       message: (String) -> Unit,
){
    val scope = rememberCoroutineScope()

    var teamSingleData by remember { mutableStateOf(GetAllTeamsData()) }
   var isClubTeam by remember { mutableStateOf(false) }
    var deleteTeam by remember { mutableStateOf(false) }

    if (deleteTeam){
        DeleteTeam(
            breakpoint = breakpoint,
            sitePalette = sitePalette,
            modalNumber = 2,
            onDialogDismiss = {
                scope.launch {
                    val response = getAllTeams(
                       clubName = AppGlobal.userData.organization
                    )
                    teamDataList.clear()
                    teamDataList.addAll(response.data)

                }
                deleteTeam = false},
            toastMessageCall = message,
            teamData = teamSingleData
        )
    }
    if (isClubTeam){
        CreateClubTeam(
            breakpoint = breakpoint,
            sitePalette = sitePalette,
            modalNumber = 1,
            onDialogDismiss = {
                scope.launch {
                    val response = getAllTeams(
                        clubName = AppGlobal.userData.organization
                    )
                    teamDataList.clear()
                    teamDataList.addAll(response.data)

                }
                isClubTeam = false},
            toastMessageCall = message
        )
    }
    Column (modifier = Modifier.height(300.px)
        .width(
            if (breakpoint > Breakpoint.LG) 30.percent
            else if (breakpoint >= Breakpoint.LG) 39.percent
            else if (breakpoint >= Breakpoint.MD) 44.percent
            else 100.percent
        )
        .padding( 20.px)
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .borderRadius(15.px)
        .margin(topBottom = if (breakpoint <= Breakpoint.MD)10.px else 0.px)){
        Row (modifier = Modifier.fillMaxWidth().margin(bottom = 10.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween) {
            SectionTitle("Teams")
            CommonButton(
                text = "Add New Team",
                icon = { FaPlus(size = IconSize.LG) },
                width = 150.px,
                height = 40.px,
                onCLick = {
                    isClubTeam = true
                })
        }
            Row(modifier = Modifier.fillMaxWidth()
                .height(50.px)
                .backgroundColor(sitePalette.coachesTitleRow.rgb),
                verticalAlignment = Alignment.CenterVertically,) {
                Row(modifier = Modifier.padding(leftRight = 5.px).fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween) {
                    FieldTitleTextNormalSimple(
                        text = "Team Name",
                        width = 120.px,
                        fontWeight = 500,
                        fontSize = 15.px,
                    )
                    FieldTitleTextNormalSimple(
                        text = "Action",
                        width = 60.px,
                        fontWeight = 500,
                        fontSize = 15.px,
                    )
                }

            }
            HorizontalDivider(
                modifier = Modifier.fillMaxWidth().margin(0.px).height((0.5).px)
                    .padding(leftRight = 5.px)
                    .backgroundColor(sitePalette.textFieldBorderColor.rgb)
            )
            Column(
                modifier = Modifier.fillMaxWidth().maxHeight(200.px)
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
            ) {
                teamData.forEach { data ->
                    Row(
                        modifier = Modifier.fillMaxWidth()
                            .padding(leftRight = 5.px)
                            .margin(topBottom = 10.px),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        FieldTitleTextNormalSimple(
                            text = data.teamName,
                            width = 130.px,
                            fontSize = 13.px
                        )
                        Row(modifier = Modifier.width(60.px).gap(8.px),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Center) {
                            FaTrash(size = IconSize.SM,
                                modifier = Modifier.color(sitePalette.primary.rgb)
                                    .cursor(Cursor.Pointer)
                                    .onClick {
                                        teamSingleData = data
                                        deleteTeam = true
                                    })
                        }
                    }
                    HorizontalDivider(
                        modifier = Modifier.fillMaxWidth().margin(0.px).height((0.5).px)
                            .padding(leftRight = 5.px)
                            .backgroundColor(sitePalette.textFieldBorderColor.rgb)
                    )
                }

            }
        }

    }