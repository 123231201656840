package org.example.zone360.models
import kotlinx.serialization.Serializable

@Serializable
data class GetClubPerformanceResponse(
    val data: GetClubPerformanceData
)
@Serializable

data class GetClubPerformanceData(
    val week1: WeekData=WeekData(),
    val week2: WeekData=WeekData(),
    val week3: WeekData=WeekData(),
    val week4: WeekData=WeekData(),
)
@Serializable
data class WeekData(
    val dateTime: String="",
    val fitness: Int = 0,
    val mental: Int = 0,
    val tactical: Int = 0,
    val technical: Int = 0,
    val goalkeeper: Int = 0,
)
