import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.BackgroundPosition
import com.varabyte.kobweb.compose.css.BackgroundSize
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.css.autoLength
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.backgroundImage
import com.varabyte.kobweb.compose.ui.modifiers.backgroundPosition
import com.varabyte.kobweb.compose.ui.modifiers.backgroundSize
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.rotate
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCalendarDay
import com.varabyte.kobweb.silk.components.icons.fa.FaFutbol
import com.varabyte.kobweb.silk.components.icons.fa.FaGlobe
import com.varabyte.kobweb.silk.components.icons.fa.FaLandmark
import com.varabyte.kobweb.silk.components.icons.fa.FaPen
import com.varabyte.kobweb.silk.components.icons.fa.FaShirt
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonDropdown
import org.example.zone360.components.CustomSpacer
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetSinglePlayerData
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.outline
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Input

@Composable
fun PlayerInfo(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    userId: String,
    imageUrl: String,
    getPlayerInfo1: GetSinglePlayerData){

    val scope = rememberCoroutineScope()
    var getPlayerInfo by remember { mutableStateOf(GetSinglePlayerData()) }
    var isEditing by remember { mutableStateOf(false) }
    var isAgeEditing by remember { mutableStateOf(false) }
    var isEthnicityEditing by remember { mutableStateOf(false) }
    var isHeightEditing by remember { mutableStateOf(false) }
    var isWeightEditing by remember { mutableStateOf(false) }
    var isNumberEditing by remember { mutableStateOf(false) }
    var isPositionEditing by remember { mutableStateOf(false) }
    var isFootEditing by remember { mutableStateOf(false) }
    var isSecondaryEditing by remember { mutableStateOf(false) }
    var selectedPosition by remember { mutableStateOf("Select Position") }
    var selectedSecondPosition by remember { mutableStateOf("Select Secondary Position") }
    var selectedFoot by remember { mutableStateOf("Select Foot") }
    var playerPosition by  mutableStateOf("N/A")
    var secondaryPosition by remember { mutableStateOf("N/A") }
    var foot by   mutableStateOf("N/A")

    var nationality by remember { mutableStateOf("") }
    var height by remember { mutableStateOf("") }
    var age by remember { mutableStateOf("") }
    var weight by remember { mutableStateOf("") }
    var number by remember { mutableStateOf("") }
    var ethnicity by remember { mutableStateOf("") }

    scope.launch {

        getPlayerInfo = getPlayerInfo1
        nationality = getPlayerInfo.nationality
        age = getPlayerInfo.age
        height = getPlayerInfo.height
        weight = getPlayerInfo.weight
        number = getPlayerInfo.number
        playerPosition = getPlayerInfo.position
        foot = getPlayerInfo.foot
        secondaryPosition = getPlayerInfo.secondaryPosition
        ethnicity = getPlayerInfo.ethnicity
    }
    Box(
        modifier = Modifier
            .width(
                if (breakpoint >= Breakpoint.LG) 68.percent
                else if (breakpoint > Breakpoint.MD) 54.percent else 100.percent
            )
//            .height(450.px)
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .borderRadius(15.px)
//            .overflow(
//                overflowY = Overflow.Auto,
//                overflowX =   Overflow.Auto
//            )
            .boxShadow(
              offsetY = 1.px,
                blurRadius = 3.px,
                color = sitePalette.boxShadowColor.rgb
            )
            .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
            .padding(
                leftRight =  10.px,
                topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
            ),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
//                .width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight(),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box (modifier = Modifier.width(98.percent),
                contentAlignment = Alignment.Center) {
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(topBottom = 10.px)
                ) {
                    Row(modifier = Modifier
                        .fillMaxWidth()
                        .flexWrap(FlexWrap.Wrap)
                        .margin(topBottom = 10.px)) {

                        if (imageUrl.isNotEmpty()) {
                            Image(
                                src = "${Res.Images.coachImageUrl}${imageUrl}",
                                modifier = Modifier.width(120.px).height(120.px)
                                    .borderRadius(100.px)
                            )
                        } else {
                            Image(
                                src = Res.Images.userIcon,
                                modifier =  Modifier.width(120.px).height(120.px)
                                    .borderRadius(100.px)
                            )
                        }
                        Column(modifier = Modifier.margin(left = 20.px, right = 8.px)
                            .weight(1.0)
                        ) {

                                FieldTitleTextNormalSimple(
                                    text = getPlayerInfo.firstName,
                                    fontSize = 20.px,
                                    fontWeight = 600,
                                    fontFamily = Constants.IBM_FONT
                                )

                            Row(modifier = Modifier.fillMaxWidth()
                                .flexWrap(FlexWrap.Wrap),
                                verticalAlignment = Alignment.CenterVertically) {
                                Row(
                                    modifier = Modifier.margin(topBottom = 10.px),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    FaFutbol(modifier = Modifier.color(Palette.InfoIconColor.rgbLight))
                                    FieldTitleTextNormalSimple(
                                        text = AppGlobal.userData.organization.replaceFirstChar
                                        { if (it.isLowerCase()) it.titlecase() else it.toString() },
                                        fontSize = 16.px,
                                        fontFamily = Constants.IBM_FONT,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        marginLeft = 8.px
                                    )
                                }
                                CustomSpacer(30.px)
                                Row(modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap),
                                    verticalAlignment = Alignment.CenterVertically){
                                    Image(src = Res.Images.teamIcon,
                                        modifier = Modifier.width(14.px)
                                            .height(14.px)
                                       )
                                    FieldTitleTextNormalSimple(
                                        text = getPlayerInfo.team.ifEmpty { "-" },
                                        fontSize = 16.px,
                                        fontFamily = Constants.IBM_FONT,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        marginLeft = 8.px
                                    )

                                }
                            }

                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .flexWrap(FlexWrap.Wrap),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Column (Modifier.gap(15.px)
                                    .margin(topBottom = 10.px)
                                    .width(if(window.screen.width > 1350)33.percent
                                    else if(window.screen.width in 750 .. 1349) 49.percent
                                    else 100.percent)){
                                    Row(modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap).fillMaxWidth(),
                                        verticalAlignment = Alignment.CenterVertically) {
                                        Image(src = Res.Images.nationalityFlag)
                                        FieldTitleTextNormalSimple(
                                            text = " Nationality: ",
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                            marginLeft = 8.px
                                        )
                                        if (isEditing) {
                                            Input(
                                                type = InputType.Text,
                                                attrs = {
                                                    value(nationality)
                                                    onInput { event ->
                                                        nationality = event.value
                                                    }
                                                    onKeyDown { event ->
                                                        if (event.key == "Enter") {
                                                            isEditing = false
                                                            scope.launch {
                                                                val jsonData = """{
                                                                "uid": "$userId",
                                                                "nationality": "$nationality"
                                                                 }"""
                                                                val  updateSuccess = updateUserData(jsonData)
                                                                if (updateSuccess.data.nationality == nationality) {

                                                                    getPlayerInfo = getPlayerInfo.copy(nationality = nationality)
                                                                } else{
                                                                    println("Failed to update nationality")
                                                                }
                                                            }


                                                        }
                                                    }
                                                    style {
                                                        fontSize(16.px)
                                                        fontFamily(Constants.IBM_FONT)
                                                        color(sitePalette.coachInfoTextColor.rgb)
                                                        border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                        outline("none")
                                                        borderRadius(8.px)
                                                        width(120.px)


                                                    }
                                                }
                                            )
                                        } else {
                                            FieldTitleTextNormalSimple(
                                                text = nationality,
                                                fontSize = 16.px,
                                                fontFamily = Constants.IBM_FONT,
                                                color = sitePalette.coachInfoTextColor.rgb,
                                            )
                                        }
                                        FaPen(
                                            size = IconSize.XXS,
                                            modifier = Modifier
                                                .color(sitePalette.coachInfoTextColor.rgb)
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    isEditing = !isEditing
                                                }
                                        )
                                    }
                                    Row(modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap),
                                        verticalAlignment = Alignment.CenterVertically) {
                                        Image(src = Res.Images.heightMeasure)
                                        FieldTitleTextNormalSimple(
                                            text = "Height: ",
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                            marginLeft = 8.px
                                        )
                                        if (isHeightEditing) {
                                            Input(
                                                type = InputType.Text,
                                                attrs = {
                                                    value(height)
                                                    placeholder("5ft 4in")
                                                    onInput { event ->
                                                        height = event.value
                                                    }
                                                    onKeyDown { event ->
                                                        if (event.key == "Enter") {
                                                            isHeightEditing = false
                                                            scope.launch {
                                                                val jsonData = """{
                                                                "uid": "$userId",
                                                                "height": "$height"
                                                                 }"""
                                                                val  updateSuccess = updateUserData(jsonData)
                                                                if (updateSuccess.data.height == height) {

                                                                    getPlayerInfo = getPlayerInfo.copy(height = height)
                                                                } else{
                                                                    println("Failed to update height")
                                                                }

                                                            }

                                                        }
                                                    }
                                                    style {
                                                        fontSize(16.px)
                                                        fontFamily(Constants.IBM_FONT)
                                                        color(sitePalette.coachInfoTextColor.rgb)
                                                        border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                        outline("none")
                                                        borderRadius(8.px)
                                                        width(120.px)
                                                    }
                                                }
                                            )
                                        } else {
                                            FieldTitleTextNormalSimple(
                                                text = height,
                                                fontSize = 16.px,
                                                fontFamily = Constants.IBM_FONT,
                                                color = sitePalette.coachInfoTextColor.rgb,
                                            )
                                        }
                                        FaPen(
                                            size = IconSize.XXS,
                                            modifier = Modifier
                                                .color(sitePalette.coachInfoTextColor.rgb)
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    isHeightEditing = !isHeightEditing
                                                }
                                        )
                                    }
                                }

                                Column (Modifier.gap(15.px)
                                    .margin(topBottom = 10.px)
                                    .width(if(window.screen.width > 1350)33.percent
                                    else if(window.screen.width in 750 .. 1349) 49.percent
                                    else 100.percent)){
                                    Row(modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap),
                                        verticalAlignment = Alignment.CenterVertically) {
                                        FaLandmark(Modifier.color(Palette.InfoIconColor.rgbLight))
                                        FieldTitleTextNormalSimple(
                                            text = " Ethnicity: ",
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                            marginLeft = 8.px
                                        )
                                        if (isEthnicityEditing) {
                                            Input(
                                                type = InputType.Text,
                                                attrs = {
                                                    value(ethnicity)
                                                    onInput { event ->
                                                        ethnicity = event.value
                                                    }
                                                    onKeyDown { event ->
                                                        if (event.key == "Enter") {
                                                            isEthnicityEditing = false
                                                            scope.launch {
                                                                val jsonData = """{
                                                                "uid": "$userId",
                                                                "ethnicity": "$ethnicity"
                                                                 }"""
                                                                val  updateSuccess = updateUserData(jsonData)
                                                                if (updateSuccess.data.ethnicity == ethnicity) {

                                                                    getPlayerInfo = getPlayerInfo.copy(ethnicity = ethnicity)
                                                                } else{
                                                                    println("Failed to update ethnicity")
                                                                }
                                                            }


                                                        }
                                                    }
                                                    style {
                                                        fontSize(16.px)
                                                        fontFamily(Constants.IBM_FONT)
                                                        color(sitePalette.coachInfoTextColor.rgb)
                                                        border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                        outline("none")
                                                        borderRadius(8.px)
                                                        width(120.px)


                                                    }
                                                }
                                            )
                                        } else {
                                            FieldTitleTextNormalSimple(
                                                text = ethnicity,
                                                fontSize = 16.px,
                                                fontFamily = Constants.IBM_FONT,
                                                color = sitePalette.coachInfoTextColor.rgb,
                                            )
                                        }
                                        FaPen(
                                            size = IconSize.XXS,
                                            modifier = Modifier
                                                .color(sitePalette.coachInfoTextColor.rgb)
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    isEthnicityEditing = !isEthnicityEditing
                                                }
                                        )
                                    }
                                    Row(modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap),
                                        verticalAlignment = Alignment.CenterVertically) {
                                        Image(src = Res.Images.weightMeasurement, modifier = Modifier.size(20.px))
                                        FieldTitleTextNormalSimple(
                                            text = "Weight: ",
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                            marginLeft = 8.px
                                        )
                                        if (isWeightEditing) {
                                            Input(
                                                type = InputType.Text,
                                                attrs = {
                                                    value(weight)
                                                    placeholder("76 lbs")
                                                    onInput { event ->
                                                        weight = event.value
                                                    }
                                                    onKeyDown { event ->
                                                        if (event.key == "Enter") {
                                                            isWeightEditing = false
                                                            scope.launch {
                                                                val jsonData = """{
                                                                "uid": "$userId",
                                                                "weight": "$weight"
                                                                 }"""
                                                                val  updateSuccess = updateUserData(jsonData)
                                                                if (updateSuccess.data.weight == weight) {

                                                                    getPlayerInfo = getPlayerInfo.copy(weight = weight)
                                                                } else{
                                                                    println("Failed to update weight")
                                                                }
                                                            }

                                                        }
                                                    }
                                                    style {
                                                        fontSize(16.px)
                                                        fontFamily(Constants.IBM_FONT)
                                                        color(sitePalette.coachInfoTextColor.rgb)
                                                        border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                        outline("none")
                                                        borderRadius(8.px)
                                                        width(70.px)


                                                    }
                                                }
                                            )
                                        } else {
                                            FieldTitleTextNormalSimple(
                                                text = weight,
                                                fontSize = 16.px,
                                                fontFamily = Constants.IBM_FONT,
                                                color = sitePalette.coachInfoTextColor.rgb,
                                            )
                                        }
                                        FaPen(
                                            size = IconSize.XXS,
                                            modifier = Modifier
                                                .color(sitePalette.coachInfoTextColor.rgb)
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    isWeightEditing = !isWeightEditing
                                                }
                                        )
                                    }
                                }

                                Column (Modifier.gap(15.px)
                                    .margin(topBottom = 10.px)
                                    .width(if(window.screen.width > 1350)33.percent
                                    else if(window.screen.width in 750 .. 1349) 49.percent
                                    else 100.percent)){
                                    Row(modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap),
                                        verticalAlignment = Alignment.CenterVertically){
                                        FaCalendarDay(modifier = Modifier.color(Colors.Gray))
                                        FieldTitleTextNormalSimple(
                                            text = " Age: ",
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                            marginLeft = 8.px
                                        )
                                        if (isAgeEditing) {
                                            Input(
                                                type = InputType.Text,
                                                attrs = {
                                                    value(age)
                                                    placeholder("18 Years")
                                                    onInput { event ->
                                                        age = event.value
                                                    }
                                                    onKeyDown { event ->
                                                        if (event.key == "Enter") {
                                                            isAgeEditing = false
                                                            scope.launch {
                                                                val jsonData = """{
                                                                "uid": "$userId",
                                                                "age": "$age"
                                                                 }"""
                                                                val  updateSuccess = updateUserData(jsonData)
                                                                if (updateSuccess.data.age == age) {

                                                                    getPlayerInfo = getPlayerInfo.copy(age = age)
                                                                } else{
                                                                    println("Failed to update age")
                                                                }
                                                            }

                                                        }
                                                    }
                                                    style {
                                                        fontSize(16.px)
                                                        fontFamily(Constants.IBM_FONT)
                                                        color(sitePalette.coachInfoTextColor.rgb)
                                                        border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                        outline("none")
                                                        borderRadius(8.px)
                                                        width(90.px)

                                                    }
                                                }
                                            )
                                        } else {
                                            FieldTitleTextNormalSimple(
                                                text = age,
                                                fontSize = 16.px,
                                                fontFamily = Constants.IBM_FONT,
                                                color = sitePalette.coachInfoTextColor.rgb,
                                            )
                                        }
                                        FaPen(
                                            size = IconSize.XXS,
                                            modifier = Modifier
                                                .color(sitePalette.coachInfoTextColor.rgb)
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    isAgeEditing = !isAgeEditing
                                                }
                                        )
                                    }
                                    Row(modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap),
                                        verticalAlignment = Alignment.CenterVertically) {
                                        FaShirt(modifier = Modifier.color(Colors.Gray))
                                        FieldTitleTextNormalSimple(
                                            text = "Number: ",
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT,
                                            color = sitePalette.coachInfoTextColor.rgb,
                                            marginLeft = 8.px
                                        )
                                        if (isNumberEditing) {
                                            Input(
                                                type = InputType.Text,
                                                attrs = {
                                                    value(number)
                                                    placeholder("56")
                                                    onInput { event ->
                                                        number = event.value
                                                    }
                                                    onKeyDown { event ->
                                                        if (event.key == "Enter") {
                                                            isNumberEditing = false
                                                            scope.launch {
                                                                val jsonData = """{
                                                                "uid": "$userId",
                                                                "number": "$number"
                                                                 }"""
                                                                val  updateSuccess = updateUserData(jsonData)
                                                                if (updateSuccess.data.number == number) {

                                                                    getPlayerInfo = getPlayerInfo.copy(number = number)
                                                                } else{
                                                                    println("Failed to update number")
                                                                }
                                                            }

                                                        }
                                                    }
                                                    style {
                                                        fontSize(16.px)
                                                        fontFamily(Constants.IBM_FONT)
                                                        color(sitePalette.coachInfoTextColor.rgb)
                                                        border(1.px, style = LineStyle.Solid, color = sitePalette.coachInfoTextColor.rgb)
                                                        outline("none")
                                                        borderRadius(8.px)
                                                        width(50.px)


                                                    }
                                                }
                                            )
                                        } else {
                                            FieldTitleTextNormalSimple(
                                                text = number,
                                                fontSize = 16.px,
                                                fontFamily = Constants.IBM_FONT,
                                                color = sitePalette.coachInfoTextColor.rgb,
                                            )
                                        }
                                        FaPen(
                                            size = IconSize.XXS,
                                            modifier = Modifier
                                                .color(sitePalette.coachInfoTextColor.rgb)
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    isNumberEditing = !isNumberEditing
                                                }
                                        )
                                    }
                                }

                            }

                        }
                    }
                    Row(
                        modifier = Modifier.margin(topBottom = 10.px)
                            .flexWrap(FlexWrap.Wrap),
                    ) {
                            Box (modifier = Modifier.width(271.px)
                                .height(182.px)
                                .zIndex(9)
                                .rotate(if (window.screen.width < 400) 90.deg else 0.deg)
                                .backgroundColor(Palette.FieldBackColor.rgbLight)
                                .overflow(overflowY = Overflow.Hidden, overflowX = Overflow.Auto)
                                .margin(topBottom = if (window.screen.width < 400) 60.px else 10.px),
                                contentAlignment = Alignment.Center){
//
                                Box(modifier = Modifier.width(254.px).height(165.px)
                                    .zIndex(99)
                                ){
                                    Row (modifier = Modifier.fillMaxSize().gap(5.px).flexWrap(FlexWrap.Wrap),
                                        verticalAlignment = Alignment.CenterVertically,){
                                        Box(
                                            modifier = Modifier
                                                .backgroundColor(if (playerPosition == "GK Goalkeeper")Palette.AbsoluteGreen.rgbLight
                                                else if(secondaryPosition == "GK Goalkeeper") Palette.AbsoluteOrange.rgbLight
                                                else Colors.Transparent)
                                                .width(31.px).height(73.px)
                                        )
                                        Column (modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap).margin(top = 2.px)){
                                            Box(
                                                modifier = Modifier
                                                    .backgroundColor(if(playerPosition == "LLD Left Lateral Defender")Palette.AbsoluteGreen.rgbLight
                                                    else if(secondaryPosition == "LLD Left Lateral Defender") Palette.AbsoluteOrange.rgbLight
                                                    else Colors.Transparent)
                                                    .width(86.px).height(28.px)
                                            )
                                            Row (Modifier.gap(4.px)){
                                            Column(Modifier.gap(4.px)) {
                                                Box(
                                                    modifier = Modifier
                                                        .backgroundColor(if (playerPosition == "LCD Left Central Defender")Palette.AbsoluteGreen.rgbLight
                                                        else if(secondaryPosition == "LCD Left Central Defender") Palette.AbsoluteOrange.rgbLight
                                                        else Colors.Transparent)
                                                        .width(42.px).height(47.px)
                                                )
                                                Box(
                                                    modifier = Modifier
                                                        .backgroundColor(if(playerPosition == "RCD Right Central Defender")Palette.AbsoluteGreen.rgbLight
                                                        else if(secondaryPosition == "RCD Right Central Defender") Palette.AbsoluteOrange.rgbLight
                                                        else Colors.Transparent)
                                                        .width(42.px).height(45.px)
                                                )
                                            }
                                                Box(
                                                    modifier = Modifier
                                                        .backgroundColor(if(playerPosition == "DM Defensive Midfielder")Palette.AbsoluteGreen.rgbLight
                                                        else if(secondaryPosition == "DM Defensive Midfielder") Palette.AbsoluteOrange.rgbLight
                                                        else Colors.Transparent)
                                                        .width(40.px).height(96.px)
                                                )
                                            }
                                            Box(
                                                modifier = Modifier
                                                    .backgroundColor(if(playerPosition == "RLD Right Lateral Defender")Palette.AbsoluteGreen.rgbLight
                                                    else if(secondaryPosition == "RLD Right Lateral Defender") Palette.AbsoluteOrange.rgbLight
                                                    else Colors.Transparent)
                                                    .width(86.px).height(28.px)
                                            )
                                        }
                                        Column (modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap).margin(left = 2.px, top = 2.px)) {
                                            Box(
                                                modifier = Modifier
                                                    .backgroundColor(if (playerPosition == "LW Left Wing")Palette.AbsoluteGreen.rgbLight
                                                    else if(secondaryPosition == "LW Left Wing") Palette.AbsoluteOrange.rgbLight
                                                    else Colors.Transparent)
                                                    .width(82.px).height(28.px)
                                            )
                                            Row (Modifier.gap(4.px)){
                                                Box(
                                                    modifier = Modifier
                                                        .backgroundColor(if(playerPosition == "AM Attacking Midfielder")Palette.AbsoluteGreen.rgbLight
                                                        else if(secondaryPosition == "AM Attacking Midfielder") Palette.AbsoluteOrange.rgbLight
                                                        else Colors.Transparent)
                                                        .width(43.px).height(96.px)
                                                )
                                                Box(
                                                    modifier = Modifier
                                                        .backgroundColor(if (playerPosition == "ST Striker")Palette.AbsoluteGreen.rgbLight
                                                        else if(secondaryPosition == "ST Striker") Palette.AbsoluteOrange.rgbLight
                                                        else Colors.Transparent)
                                                        .width(43.px).height(96.px)
                                                )

                                            }
                                            Box(
                                                modifier = Modifier
                                                    .backgroundColor(if(playerPosition == "RW Right Wing")Palette.AbsoluteGreen.rgbLight
                                                    else if(secondaryPosition == "RW Right Wing") Palette.AbsoluteOrange.rgbLight
                                                    else Colors.Transparent)
                                                    .width(82.px).height(28.px)
                                            )
                                        }

                                    }
                                }
                                Box(modifier = Modifier.width(254.px).height(165.px)
                                    .backgroundImage(url (Res.Images.field_boundary))
                                    .zIndex(999)
                                    .backgroundSize(BackgroundSize.Cover)
                                    .backgroundPosition( BackgroundPosition.of(CSSPosition.Center)))
                            }

                        Column (modifier = Modifier.margin(left = 24.px).gap(24.px)){
                            Column {
                                Row(modifier = Modifier.gap(10.px), verticalAlignment = Alignment.CenterVertically) {
                                    FieldTitleTextNormalSimple(
                                        text = "Position:",
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        fontWeight = 500,
                                        fontSize = 14.px,
                                        marginBottom = 4.px
                                    )
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isPositionEditing = !isPositionEditing
                                            }
                                    )
                                }
                                Row(modifier = Modifier.gap(5.px).flexWrap(FlexWrap.Wrap), verticalAlignment = Alignment.CenterVertically) {
                                    if (isPositionEditing) {
                                        Box {
                                            CommonDropdown(
                                                data = listOf(
                                                    "GK Goalkeeper", "RCD Right Central Defender",
                                                    "LCD Left Central Defender", "RLD Right Lateral Defender",
                                                    "LLD Left Lateral Defender", "DM Defensive Midfielder",
                                                    "AM Attacking Midfielder", "LW Left Wing",
                                                    "RW Right Wing", "ST Striker"
                                                ).filter { it != secondaryPosition },
                                                onSelect = { selectedItem ->
                                                    selectedPosition = selectedItem
                                                    playerPosition = selectedItem

                                                    scope.launch {
                                                        val jsonData = """{
                                                            "uid": "$userId",
                                                            "position": "$playerPosition"
                                                        }"""
                                                        val updateSuccess = updateUserData(jsonData)
                                                        if (updateSuccess.data.position == playerPosition) {
                                                            getPlayerInfo = getPlayerInfo.copy(position = playerPosition)
                                                        } else {
                                                            println("Failed to update position")
                                                        }
                                                    }
                                                    isPositionEditing = false
                                                },
                                                selectedItem = selectedPosition,
                                                width = if (window.screen.width > 375 )250.px else 190.px,
                                            )
                                        }
                                    } else {
                                        FieldTitleTextNormalSimple(
                                            text = playerPosition,
                                            color = Palette.AbsoluteGreen.rgbLight,
                                            fontWeight = 600,
                                            fontSize = 16.px,
                                            width = if (window.screen.width > 375 )250.px else 180.px,
                                            whiteSpace = WhiteSpace.PreWrap
                                        )
                                    }
                                }
                            }
                            Column {
                                Row(modifier = Modifier.gap(10.px), verticalAlignment = Alignment.CenterVertically) {
                                    FieldTitleTextNormalSimple(
                                        text = "Secondary:",
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        fontWeight = 500,
                                        fontSize = 14.px,
                                        marginBottom = 4.px
                                    )
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isSecondaryEditing = !isSecondaryEditing
                                            }
                                    )
                                }
                                if (isSecondaryEditing) {
                                    Box {
                                        CommonDropdown(
                                            data = listOf(
                                                "GK Goalkeeper", "RCD Right Central Defender",
                                                "LCD Left Central Defender", "RLD Right Lateral Defender",
                                                "LLD Left Lateral Defender", "DM Defensive Midfielder",
                                                "AM Attacking Midfielder", "LW Left Wing",
                                                "RW Right Wing", "ST Striker"
                                            ).filter { it != playerPosition }, // Exclude primary selection
                                            onSelect = { selectedItem ->
                                                selectedSecondPosition = selectedItem
                                                secondaryPosition = selectedItem
                                                scope.launch {
                                                    val jsonData = """{
                                                        "uid": "$userId",
                                                        "secondaryPosition": "$secondaryPosition"
                                                    }"""
                                                    val updateSuccess = updateUserData(jsonData)
                                                    if (updateSuccess.data.secondaryPosition == secondaryPosition) {
                                                        getPlayerInfo = getPlayerInfo.copy(secondaryPosition = secondaryPosition)
                                                    } else {
                                                        println("Failed to update secondary position")
                                                    }
                                                }
                                                isSecondaryEditing = false

                                            },
                                            selectedItem = selectedSecondPosition,
                                            width = if (window.screen.width > 375 )250.px else 190.px,

                                            )
                                    }
                                } else {
                                    FieldTitleTextNormalSimple(
                                        text = secondaryPosition,
                                        color = Palette.AbsoluteOrange.rgbLight,
                                        fontWeight = 600,
                                        width = if (window.screen.width > 375 )250.px else 180.px,
                                        fontSize = 16.px,
                                        whiteSpace = WhiteSpace.PreWrap
                                    )
                                }
                            }
                            Column {
                                Row(
                                    modifier = Modifier.gap(10.px),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    FieldTitleTextNormalSimple(
                                        text = "Foot:",
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        fontWeight = 500,
                                        fontSize = 14.px,
                                        marginBottom = 4.px
                                    )
                                    FaPen(
                                        size = IconSize.XXS,
                                        modifier = Modifier
                                            .color(sitePalette.coachInfoTextColor.rgb)
                                            .cursor(Cursor.Pointer)
                                            .onClick {
                                                isFootEditing = !isFootEditing
                                            }
                                    )
                                }
                                if (isFootEditing) {
                                    Box {
                                        CommonDropdown(
                                            data = listOf(
                                                "Both Feet",
                                                "Right Foot",
                                                "Left Foot"
                                            ),
                                            onSelect = { selectedItem ->
                                                selectedFoot = selectedItem
                                                foot = selectedItem
                                                scope.launch {
                                                    val jsonData = """{
                                                            "uid": "$userId",
                                                            "foot": "$foot"
                                                        }"""
                                                    val updateSuccess = updateUserData(jsonData)
                                                    if (updateSuccess.data.foot == foot) {
                                                        getPlayerInfo = getPlayerInfo.copy(foot = foot)
                                                    } else {
                                                        println("Failed to update foot preference")
                                                    }
                                                }
                                                isFootEditing = false

                                            },
                                            selectedItem = selectedFoot,
                                            width = if (window.screen.width > 375 )250.px else 190.px,

                                            )
                                    }
                                } else {
                                    FieldTitleTextNormalSimple(
                                        text = foot,
                                        color = sitePalette.coachInfoTextColor.rgb,
                                        fontWeight = 600,
                                        fontSize = 16.px,
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }}
}

