package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetPlayerResponse(
    val data: List<GetPlayerData>,
)
@Serializable

data class GetPlayerData(
    val coachName: String = "",
    val count: Long = 0,
    val email: String = "",
    val firstName: String = "",
    val imageUrl: String = "",
    val lastName: String = "",
    var team: String = "",
    @SerialName("userID")
    val userId: String = "",
)