package org.example.zone360.pages.players

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshots.SnapshotStateList
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaPause
import com.varabyte.kobweb.silk.components.icons.fa.FaPlay
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import getTagSentences
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.components.VideoProgressSliderCustom
import org.example.zone360.components.showToast
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetSentencesData
import org.example.zone360.pages.coachDetails.charts.CircularProgressIndicator
import org.example.zone360.pages.coachDetails.sessionAllDetails.SentenceAudioWaveform
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Res
import org.example.zone360.utils.UploadedType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Video
import org.w3c.dom.Audio
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLVideoElement
import org.w3c.fetch.RequestInit
import org.w3c.files.File
import org.w3c.files.get
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.json
import kotlin.math.ceil

@Composable
fun VideosSection(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    userId: String,
    message: (String) -> Unit,
    userVideosData: SnapshotStateList<String>
) {
//
    val scope = rememberCoroutineScope()
    var videoUploadedType by remember { mutableStateOf(UploadedType.You) }
    var currentVideoIndex by remember { mutableStateOf(0) }
    var uploadProgress by remember { mutableStateOf(-1.0) }
    var uploadStatus by remember { mutableStateOf("") }
    val getSentences = remember { mutableStateListOf<GetSentencesData>() }

//
    var startIndex by remember { mutableStateOf(0) }
    val maxDots = 5
    val totalDots = getSentences.size

// Track the start index for pagination
    var visibleDotStartIndex by remember { mutableStateOf(0) }

// Ensure only the required number of dots are visible
    val visibleDotsForCoach =
        (visibleDotStartIndex until minOf(visibleDotStartIndex + maxDots, totalDots))

//     for You Dots
//    var userVideos = remember { mutableStateListOf<String>() }

    var visibleDotStartIndexForYou by remember { mutableStateOf(0) }

    val totalDotsForDots = userVideosData.size


// Ensure only the necessary dots are visible
    val visibleDots = (visibleDotStartIndexForYou until minOf(
        visibleDotStartIndexForYou + maxDots,
        totalDotsForDots
    ))


//
    val uploadFile: (File) -> Unit = { file ->
        uploadProgress = 0.0
        uploadStatus = "Initializing upload..."

        scope.launch {
            // Step 1: Initiate Multipart Upload
            try {

                println("upload start")
//                    delay(4000)
//                    uploadProgress=100.0
////                    sessionData.videoFile="${getCurrentMilliseconds()}"
//                    sessionData = sessionData.copy(videoFile = getCurrentMilliseconds().toString())
//                    println("sessionData.videoFile: ${sessionData.videoFile}")


                val initiateResponse = window.fetch(
                    "https://vitru2.17nmki56rdxv.us-south.codeengine.appdomain.cloud/initiate-upload",
                    RequestInit(
                        method = "POST",
                        headers = json("Content-Type" to "application/json"),
                        body = JSON.stringify(json("fileName" to file.name))
                    )
                ).await().json().await()
                val uploadId = initiateResponse.asDynamic().uploadId as String

                val chunkSize = 5 * 1024 * 1024 // 5MB per chunk
                val totalChunks = ceil(file.size.toDouble() / chunkSize).toInt()
                val parts = mutableListOf<dynamic>()

                uploadStatus = "Uploading file in $totalChunks chunks..."

                for (chunkIndex in 0 until totalChunks) {
                    val start = chunkIndex * chunkSize
                    val end = minOf(start + chunkSize, file.size.toInt())
                    val chunk = file.slice(start, end)

                    // Step 2: Get Signed URL for this part
                    val partResponse =
                        window.fetch("https://vitru2.17nmki56rdxv.us-south.codeengine.appdomain.cloud/get-part-url?fileName=${file.name}&uploadId=$uploadId&partNumber=${chunkIndex + 1}")
                            .await().json().await()
                    val partUrl = partResponse.asDynamic().url as String

                    // Step 3: Upload Chunk
                    val xhr = XMLHttpRequest()
                    xhr.open("PUT", partUrl, true)
                    xhr.setRequestHeader("Content-Type", "application/octet-stream")

                    xhr.upload.onprogress = { event ->
                        if (event.lengthComputable) {
                            uploadProgress =
                                ((chunkIndex.toDouble() / totalChunks) * 100) + ((event.loaded.toDouble() / event.total.toDouble()) * (100 / totalChunks))
                        }
                    }

                    val partCompleted = CompletableDeferred<String>()
                    xhr.onload = {
                        if (xhr.status.toInt() == 200) {
                            val eTag = xhr.getResponseHeader("ETag") ?: ""
                            parts.add(json("ETag" to eTag, "PartNumber" to (chunkIndex + 1)))
                            partCompleted.complete("Success")
                        } else {
                            uploadStatus = "Error uploading chunk ${chunkIndex + 1}"
                            partCompleted.completeExceptionally(Exception("Upload failed!"))
                        }
                    }

                    xhr.onerror =
                        { partCompleted.completeExceptionally(Exception("Upload error!")) }
                    xhr.send(chunk)
                    partCompleted.await()  // Wait for the chunk to upload before moving to the next
                }

                // Step 4: Complete Multipart Upload
                val completeResponse = window.fetch(
                    "https://vitru2.17nmki56rdxv.us-south.codeengine.appdomain.cloud/complete-upload",
                    RequestInit(
                        method = "POST",
                        headers = json("Content-Type" to "application/json"),
                        body = JSON.stringify(
                            json(
                                "fileName" to file.name,
                                "uploadId" to uploadId,
                                "parts" to parts,
                                "sessionID" to "",
                                "userID" to userId,
                                "playerVideo" to true
                            )
                        )
                    )
                ).await().json().await()

                if (completeResponse.asDynamic().message != null) {
                    uploadStatus = "Upload complete!"
                    uploadProgress = 101.0

                    message("Video uploaded successfully!")
                    showToast("toastSuccess")
//
                    val videoUrl = completeResponse.asDynamic().videoUrl as String?

                    println("videoUrl: $videoUrl")

//                    if (videoUrl != null) {
                    val newVideoUrl = "${Res.Audio.base_Audio_URL}${file.name}"
                    userVideosData.add(newVideoUrl)
                    // Update current index to show the newly uploaded video
                    currentVideoIndex = userVideosData.size - 1
//                    }
//                    getProfileData = getProfileData.copy(userVideos = listOf(file.name))

                } else {
                    uploadStatus = "Upload failed!"
                    uploadProgress = -1.0
                    message("Video uploading error!")
                    showToast("toastError")


                }
            } catch (e: Exception) {
                message("Video uploading error!")
                showToast("toastError")
                uploadStatus = "Error: ${e.message}"
                uploadProgress = -1.0

            }
        }
    }

    LaunchedEffect(Unit) {

        val getTagPlayerSentences = getTagSentences(
            organization = AppGlobal.userData.organization,
            domainType = "soccer",
            language = "en",
            playerID = userId
        )
        getSentences.clear()
        getSentences.addAll(getTagPlayerSentences.data)
    }


    Box(
        modifier = Modifier
            .height(450.px)
            .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px)
            .width(
                if (breakpoint >= Breakpoint.LG) 31.percent
                else if (breakpoint > Breakpoint.MD) 44.percent
                else 100.percent)
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .boxShadow(
                offsetY = 1.px,
                blurRadius = 3.px,
                color = sitePalette.boxShadowColor.rgb
            )
            .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
            .borderRadius(15.px)
            .padding(20.px)
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                SectionTitle("Videos")
                Row(
                    Modifier.gap(10.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    CommonButton(
                        text = "Upload Video",
                        icon = {
                            FaUpload(
                                size = IconSize.LG,
                                modifier = Modifier.color(sitePalette.primary.rgb)
                            )
                        },
                        width = 150.px,
                        height = 40.px,
                        borderColor = sitePalette.primary.rgb,
                        backgroundColor = Colors.Transparent,
                        textSpanColor = sitePalette.primary.rgb,
                        onCLick = {
                            // Trigger file selection when the button is clicked
                            val fileInput =
                                document.createElement("input") as HTMLInputElement
                            fileInput.type = "file"
//                                        fileInput.accept = "video/*"
                            fileInput.onchange = { event ->
                                val selectedFile =
                                    (event.target as HTMLInputElement).files?.get(0)
                                if (selectedFile != null) {
                                    uploadFile(selectedFile)
                                }
                            }
                            fileInput.click()
                        }
                    )
                    if (uploadProgress in 0.0..100.0) {

                        CircularProgressIndicator(
                            percentage = uploadProgress.toFloat(),
                            size = 40,
                            strokeWidth = 5,
                            fontSize = 10.px,
                            primaryColor = sitePalette.primary.rgb,
                            backgroundColor = Colors.LightGray
                        )
                    }

                }
            }
            Row(
                modifier = Modifier.fillMaxWidth(90.percent)
                    .margin(topBottom = 10.px)
                    .borderRadius(6.px)
                    .backgroundColor(sitePalette.lightGrey.rgb)
                    .padding(leftRight = 5.px, topBottom = 4.px)
                    .border(
                        width = 1.px,
                        style = LineStyle.Solid,
                        color = Colors.LightGray
                    ),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Box(modifier = Modifier.fillMaxWidth(50.percent)
                    .borderRadius(4.px)
                    .cursor(Cursor.Pointer)
                    .padding(leftRight = 12.px, topBottom = 6.px)
                    .backgroundColor(if (videoUploadedType == UploadedType.You) Palette.White.rgbLight else Colors.Transparent)
                    .onClick { videoUploadedType = UploadedType.You }
                    .boxShadow(
                        offsetX = 0.px,
                        offsetY = if (videoUploadedType == UploadedType.You) 2.px else 0.px,
                        blurRadius = if (videoUploadedType == UploadedType.You) 16.px else 0.px,
                        spreadRadius = null,
                        color = sitePalette.boxShadowColor.rgb
                    ),
                    contentAlignment = Alignment.Center) {
                    FieldTitleTextNormalSimple(
                        text = "Uploaded by You",
                        textAlign = TextAlign.Center,
                        fontWeight = 500,
                        fontSize = 16.px,
                        isClickable = true
                    )
                }
                Box(modifier = Modifier.fillMaxWidth(50.percent)
                    .borderRadius(4.px)
                    .cursor(Cursor.Pointer)
                    .padding(leftRight = 12.px, topBottom = 6.px)
                    .backgroundColor(if (videoUploadedType != UploadedType.You) Palette.White.rgbLight else Colors.Transparent)
                    .onClick { videoUploadedType = UploadedType.SharedByCoach }
                    .boxShadow(
                        offsetX = 0.px,
                        offsetY = if (videoUploadedType == UploadedType.SharedByCoach) 2.px else 0.px,
                        blurRadius = if (videoUploadedType == UploadedType.SharedByCoach) 16.px else 0.px,
                        spreadRadius = null,
                        color = sitePalette.boxShadowColor.rgb
                    ),
                    contentAlignment = Alignment.Center) {
                    FieldTitleTextNormalSimple(
                        text = "Shared by Coach",
                        textAlign = TextAlign.Center,
                        fontWeight = 500,
                        fontSize = 16.px,
                        isClickable = true
                    )
                }

            }

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(270.px)
                    .borderRadius(8.px)
                    .margin(topBottom = 5.px)
                    .align(Alignment.CenterHorizontally)
            ) {
                Column(
                    modifier = Modifier.fillMaxSize(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    if (videoUploadedType == UploadedType.You) {
                        if (userVideosData.isEmpty()) {
                            Box(
                                modifier = Modifier.fillMaxSize(),
                                contentAlignment = Alignment.Center
                            ) {
                                FieldTitleTextNormalSimple(
                                    text = "No data available",
                                    fontSize = 16.px,
                                    fontWeight = 500
                                )
                            }
                        } else {
                            // Uploaded video
                            Video(
                                attrs = {
                                    attr("src", userVideosData[currentVideoIndex])
                                    attr("controls", "true")
                                    attr("loop", "false")
                                    attr("muted", "true")
                                    style {
                                        property("width", "100%")
                                        property("height", "100%")
                                        property("border-radius", "8px")
                                        property("object-fit", "cover")
                                    }
                                }
                            )
                            if (totalDotsForDots > 1) {
                                Row(
                                    modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                                    verticalAlignment = Alignment.CenterVertically,
                                    horizontalArrangement = Arrangement.SpaceBetween
                                ) {
                                    Row {
                                        FieldTitleTextNormalSimple(
                                            text = "${currentVideoIndex + 1}/$totalDotsForDots",
                                            fontSize = 16.px,
                                            fontWeight = 500
                                        )
                                    }
                                    Row(
                                        modifier = Modifier.fillMaxWidth(),
                                        horizontalArrangement = Arrangement.Center,
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        visibleDots.forEach { index ->
                                            Box(
                                                modifier = Modifier
                                                    .size(if (index == currentVideoIndex) 8.px else 9.px)
                                                    .backgroundColor(if (index == currentVideoIndex) Colors.Gray else Colors.LightGray)
                                                    .borderRadius(100.px)
                                                    .margin(leftRight = 5.px)
                                                    .cursor(Cursor.Pointer)
                                                    .onClick {
                                                        currentVideoIndex = index
                                                    }
                                            )
                                        }
                                    }
                                    Row(Modifier.gap(4.px)) {
                                        Image(
                                            src = Res.Images.arrow_Left,
                                            modifier = Modifier
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    if (currentVideoIndex > 0) {
                                                        currentVideoIndex--
                                                        if (currentVideoIndex < visibleDotStartIndexForYou) {
                                                            visibleDotStartIndexForYou = maxOf(
                                                                0,
                                                                visibleDotStartIndexForYou - 1
                                                            )
                                                        }
                                                    }
                                                }
                                        )
                                        Image(
                                            src = Res.Images.arrow_Right,
                                            modifier = Modifier
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    if (currentVideoIndex < totalDotsForDots - 1) {
                                                        currentVideoIndex++
                                                        if (currentVideoIndex >= visibleDotStartIndexForYou + maxDots) {
                                                            visibleDotStartIndexForYou = minOf(
                                                                totalDotsForDots - maxDots,
                                                                visibleDotStartIndexForYou + 1
                                                            )
                                                        }
                                                    }
                                                }
                                        )
                                    }
                                }
                            }
                        }
                    } else {

                        if (getSentences.isNotEmpty()) {
                            var allVideosProcessed by remember { mutableStateOf(false) }

                            getSentences.drop(startIndex)
                                .take(1)
                                .forEachIndexed { index, sentenceData ->
                                    val sessionId = sentenceData.id
                                    val getSentenceAudio =
                                        Audio("${Res.Audio.base_Audio_URL}${sentenceData.sessionId}")
                                    val fileName = sentenceData.videoFile
                                    val encodedFileName = encodeFileName(fileName)
                                    val getSentenceVideo =
                                        "${Res.Audio.base_Audio_URL}$encodedFileName"
                                    println("getSentenceVideo $getSentenceVideo")

                                    var audioProgress by remember { mutableStateOf(0f) }
                                    var isPlayingAllHighlight by remember { mutableStateOf(false) }
                                    var currentlyPlayingIndexHighlight by remember {
                                        mutableStateOf<Int?>(
                                            null
                                        )
                                    }
                                    var currentlyPlayingIdHighlight by remember {
                                        mutableStateOf<String?>(
                                            null
                                        )
                                    }
                                    var loadingSessionIdHighlight by remember {
                                        mutableStateOf<String?>(
                                            null
                                        )
                                    }
                                    val currentAudioRefHighlight =
                                        remember { mutableStateOf<Audio?>(null) }
                                    var isThisPlaying by remember { mutableStateOf(false) }

                                    LaunchedEffect(startIndex) {
                                        currentlyPlayingIdHighlight = null
                                        currentAudioRefHighlight.value?.pause()
                                        currentAudioRefHighlight.value = null
                                        audioProgress = 0f
                                        isThisPlaying = false
                                        loadingSessionIdHighlight = null
                                    }

                                    LaunchedEffect(currentlyPlayingIdHighlight) {
                                        isThisPlaying = currentlyPlayingIdHighlight == sessionId

                                        if (currentlyPlayingIdHighlight == sessionId) {
                                            val audioPlay = currentAudioRefHighlight.value
                                            audioPlay?.apply {
                                                while (currentTime < sentenceData.endTime) {
                                                    audioProgress =
                                                        ((currentTime - sentenceData.startTime) / (sentenceData.endTime - sentenceData.startTime)).toFloat()
                                                    delay(100)

                                                    if (currentTime >= sentenceData.endTime) {
                                                        pause()
                                                        currentlyPlayingIdHighlight = null
                                                        currentAudioRefHighlight.value = null
                                                        audioProgress = 0f
                                                        isThisPlaying = false
                                                        break
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    Column(
                                        modifier = Modifier.fillMaxSize(),
                                        verticalArrangement = Arrangement.Center
                                    ) {
                                        if (sentenceData.videoFile.isEmpty()) {
                                            Row(
                                                modifier = Modifier.fillMaxWidth()
                                                    .height(100.px)
                                                    .borderRadius(8.px)
                                                    .padding(8.px)
                                                    .gap(8.px)
                                                    .backgroundColor(sitePalette.searchBackground.rgb),
                                                verticalAlignment = Alignment.CenterVertically
                                            ) {
                                                Box(
                                                    modifier = Modifier.size(30.px)
                                                        .borderRadius(100.percent)
                                                        .backgroundColor(if (currentlyPlayingIdHighlight == sessionId) sitePalette.primary.rgb else Colors.Transparent)
                                                        .cursor(Cursor.Pointer)
                                                        .onClick {
                                                            isPlayingAllHighlight = false
                                                            if (currentlyPlayingIdHighlight == sessionId) {
                                                                currentAudioRefHighlight.value?.pause()
                                                                getSentenceAudio.pause()
                                                                currentlyPlayingIdHighlight = null
                                                                currentlyPlayingIndexHighlight =
                                                                    null
                                                                currentAudioRefHighlight.value =
                                                                    null
                                                                audioProgress = 0f
                                                            } else {
                                                                currentAudioRefHighlight.value?.pause()
                                                                loadingSessionIdHighlight =
                                                                    sessionId

                                                                getSentenceAudio.onloadeddata = {
                                                                    loadingSessionIdHighlight = null
                                                                    getSentenceAudio.currentTime =
                                                                        sentenceData.startTime
                                                                    getSentenceAudio.play()
                                                                    currentlyPlayingIdHighlight =
                                                                        sessionId
                                                                    currentlyPlayingIndexHighlight =
                                                                        index
                                                                    getSentenceAudio.also {
                                                                        currentAudioRefHighlight.value =
                                                                            it
                                                                    }
                                                                }
                                                                getSentenceAudio.load()
                                                            }
                                                        },
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    if (loadingSessionIdHighlight == sessionId) {
                                                        ProgressIndicator(10.px)
                                                    } else if (currentlyPlayingIdHighlight == sessionId) {
                                                        FaPause(
                                                            modifier = Modifier.color(Palette.White.rgbLight),
                                                            size = IconSize.XXS
                                                        )
                                                    } else {
                                                        Image(
                                                            src = Res.Images.play_all_button,
                                                            modifier = Modifier.size(30.px)
                                                        )
                                                    }
                                                }
                                                SentenceAudioWaveform(
                                                    modifier = Modifier.fillMaxWidth(),
                                                    progress = audioProgress,
                                                    startTime = sentenceData.startTime,
                                                    endTime = sentenceData.endTime,
                                                    isPlaying = isThisPlaying,
                                                    onProgressChange = { newProgress ->
                                                        audioProgress = newProgress
                                                        currentAudioRefHighlight.value?.let { audio ->
                                                            audio.currentTime =
                                                                sentenceData.startTime + (sentenceData.endTime - sentenceData.startTime) * newProgress
                                                        }
                                                    }
                                                )
                                            }
                                            if (getSentences.size > 1) {
                                                Row(
                                                    modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                                                    verticalAlignment = Alignment.CenterVertically,
                                                    horizontalArrangement = Arrangement.SpaceBetween
                                                ) {
                                                    FieldTitleTextNormalSimple(
                                                        text = "${startIndex + 1}/$totalDots",
                                                        fontSize = 16.px,
                                                        fontWeight = 500
                                                    )

                                                    Row(
                                                        modifier = Modifier.fillMaxWidth(),
                                                        horizontalArrangement = Arrangement.Center,
                                                        verticalAlignment = Alignment.CenterVertically
                                                    ) {
                                                        visibleDotsForCoach.forEach { index ->
                                                            Box(
                                                                modifier = Modifier
                                                                    .size(if (index == startIndex) 8.px else 9.px)
                                                                    .backgroundColor(if (index == startIndex) Colors.Gray else Colors.LightGray)
                                                                    .borderRadius(100.px)
                                                                    .margin(leftRight = 5.px)
                                                                    .cursor(Cursor.Pointer)
                                                                    .onClick {
                                                                        startIndex = index
                                                                    }
                                                            )
                                                        }
                                                    }

                                                    Row(Modifier.gap(4.px)) {
                                                        Image(
                                                            src = Res.Images.arrow_Left,
                                                            modifier = Modifier.cursor(Cursor.Pointer).onClick {
                                                                if (startIndex > 0) {
                                                                    startIndex--
                                                                    if (startIndex < visibleDotStartIndex) {
                                                                        visibleDotStartIndex = maxOf(0, visibleDotStartIndex - 1)
                                                                    }
//
                                                                }
                                                            }
                                                        )
                                                        Image(
                                                            src = Res.Images.arrow_Right,
                                                            modifier = Modifier.cursor(Cursor.Pointer).onClick {
                                                                if (startIndex < totalDots - 1) {
                                                                    startIndex++
                                                                    if (startIndex >= visibleDotStartIndex + maxDots) {
                                                                        visibleDotStartIndex = minOf(totalDots - maxDots, visibleDotStartIndex + 1)
                                                                    }
                                                                }
                                                            }
                                                        )
                                                    }
                                                }
}
//
                                        }
                                        else {
                                            // Video row logic
                                            var videoProgress by remember { mutableStateOf(0f) }
                                            var isSeeking by remember { mutableStateOf(false) }
                                            var currentlyPlayingVideoId by remember { mutableStateOf<String?>(null) }
                                            val currentVideoRef = remember { mutableStateOf<HTMLVideoElement?>(null) }
                                            var isVideoDurationPlaying by remember { mutableStateOf(false) }
                                            var isHovered by remember { mutableStateOf(false) }
                                            var isBuffering by remember { mutableStateOf(false) }

                                            LaunchedEffect(startIndex) {
                                                currentlyPlayingVideoId = null
                                                currentVideoRef.value?.pause()
                                                currentVideoRef.value = null
                                                videoProgress = 0f
                                            }

                                            LaunchedEffect(currentlyPlayingVideoId) {
                                                if (currentlyPlayingVideoId == sessionId) {
                                                    val videoElement = currentVideoRef.value
                                                    videoElement?.apply {
                                                        currentTime = sentenceData.startTime
                                                        play()

                                                        while (currentTime < sentenceData.endTime) {
                                                            videoProgress = ((currentTime - sentenceData.startTime) / (sentenceData.endTime - sentenceData.startTime)).toFloat()
                                                            delay(100)

                                                            if (currentTime >= sentenceData.endTime) {
                                                                pause()
                                                                currentlyPlayingVideoId = null
                                                                currentVideoRef.value = null
                                                                videoProgress = 0f
                                                                break
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            fun playVideoWithAudio(
                                                videoElement: HTMLVideoElement,
                                                audioElement: Audio,
                                                startTime: Double,
                                                endTime: Double
                                            ) {
                                                var timeoutId: Int? = null

                                                println("Starting playVideoWithAudio function")
                                                println("Start time: $startTime, End time: $endTime")

                                                isVideoDurationPlaying = true
                                                isSeeking = true
                                                println("State reset - isVideoDurationPlaying: $isVideoDurationPlaying, isSeeking: $isSeeking")

                                                val cleanup = {
                                                    println("Cleanup function called")
                                                    videoElement.pause()
                                                    audioElement.pause()
                                                    println("Video and audio paused")

                                                    isVideoDurationPlaying = false
                                                    videoProgress = 0f
                                                    audioProgress = 0f
                                                    currentlyPlayingVideoId = null
                                                    currentVideoRef.value = null
                                                    currentAudioRefHighlight.value = null
                                                    println("State variables reset in cleanup")

                                                    videoElement.onwaiting = null
                                                    videoElement.onplaying = null
                                                    videoElement.oncanplay = null
                                                    videoElement.ontimeupdate = null
                                                    println("Event listeners removed")

                                                    timeoutId?.let {
                                                        window.clearTimeout(it)
                                                        println("Timeout cleared: $it")
                                                    }
                                                    println("Cleanup complete")
                                                }

                                                videoElement.currentTime = startTime
                                                audioElement.currentTime = startTime
                                                println("Initial times set - Video time: ${videoElement.currentTime}, Audio time: ${audioElement.currentTime}")

                                                videoElement.onwaiting = {
                                                    println("Video buffering detected")
                                                    isBuffering = true
                                                    audioElement.pause()
                                                    println("Audio paused due to video buffering")
                                                }

                                                videoElement.onplaying = {
                                                    println("Video resumed playing")
                                                    isBuffering = false
                                                    if (audioElement.paused) audioElement.play()
                                                    println("Audio playback resumed")
                                                }

                                                videoElement.onprogress = {
                                                    if (videoElement.buffered.length > 0) {
                                                        val bufferedEnd = videoElement.buffered.end(0)
                                                        val bufferedSeconds = bufferedEnd - videoElement.currentTime
                                                        println("Buffered up to: $bufferedEnd seconds, Available buffer: $bufferedSeconds seconds")

                                                        if (bufferedSeconds > 3 && videoElement.paused) {
                                                            println("Buffer sufficient, resuming playback.")
                                                            isBuffering = false
                                                            videoElement.play()
                                                            audioElement.play()
                                                        }
                                                    }
                                                }

                                                videoElement.oncanplay = {
                                                    println("Video can play event triggered")
                                                    isBuffering = false
                                                    videoElement.play().then {
                                                        println("Video playback started successfully")
                                                        audioElement.play()
                                                        println("Audio playback started")
                                                    }.catch { error ->
                                                        println("Error playing media: $error")
                                                        console.log("Error playing media: $error")
                                                        cleanup()
                                                    }
                                                }

                                                videoElement.ontimeupdate = {
                                                    val timeDiff = kotlin.math.abs(videoElement.currentTime - audioElement.currentTime)
                                                    if (timeDiff > 0.1) {
                                                        println("Sync correction needed - Time difference: $timeDiff")
                                                        audioElement.currentTime = videoElement.currentTime
                                                        println("Audio time adjusted to: ${audioElement.currentTime}")
                                                    }
                                                }

                                                timeoutId = window.setTimeout(
                                                    {
                                                        println("Timeout triggered - Ending playback")
                                                        cleanup()
                                                    },
                                                    ((endTime - startTime) * 1000).toInt()
                                                )
                                                println("Timeout set for ${((endTime - startTime) * 1000).toInt()}ms")
                                            }

                                            Box(
                                                modifier = Modifier.fillMaxWidth()
                                                    .onMouseEnter { isHovered = true }
                                                    .onMouseLeave { isHovered = false },
                                                contentAlignment = Alignment.Center
                                            ) {
                                                if (!isVideoDurationPlaying) {
                                                    Box(
                                                        modifier = Modifier.size(54.px)
                                                            .zIndex(9999)
                                                            .borderRadius(100.percent)
                                                            .backgroundColor(sitePalette.primary.rgb)
                                                            .cursor(Cursor.Pointer)
                                                            .onClick {
                                                                val videoElement = document.getElementById("video_$sessionId") as? HTMLVideoElement

                                                                videoElement?.let { video ->
                                                                    getSentenceAudio.let { audio ->
                                                                        playVideoWithAudio(
                                                                            video,
                                                                            audio,
                                                                            sentenceData.startTime,
                                                                            sentenceData.endTime
                                                                        )
                                                                        currentlyPlayingVideoId =
                                                                            sessionId
                                                                        currentVideoRef.value =
                                                                            video
                                                                        currentAudioRefHighlight.value =
                                                                            audio
                                                                    }
                                                                }
                                                            },
                                                        contentAlignment = Alignment.Center
                                                    ) {
                                                        FaPlay(
                                                            size = IconSize.XL,
                                                            modifier = Modifier.color(Colors.White)
                                                        )
                                                    }
                                                } else {
                                                    if (isHovered) {
                                                        Box(
                                                            modifier = Modifier.size(54.px)
                                                                .zIndex(9999)
                                                                .borderRadius(100.percent)
                                                                .backgroundColor(sitePalette.primary.rgb)
                                                                .cursor(Cursor.Pointer)
                                                                .onClick {
                                                                    val videoElement = document.getElementById("video_$sessionId") as? HTMLVideoElement
                                                                    val audioElement = currentAudioRefHighlight.value

                                                                    videoElement?.let {
                                                                        isVideoDurationPlaying = false
                                                                        it.pause()
                                                                        audioElement?.pause()
                                                                        currentlyPlayingVideoId = null
                                                                        currentVideoRef.value = null
                                                                        currentAudioRefHighlight.value = null
                                                                    }
                                                                },
                                                            contentAlignment = Alignment.Center
                                                        ) {
                                                            if(isBuffering){
                                                                ProgressIndicator()
                                                            } else {
                                                                FaPause(
                                                                    size = IconSize.XL,
                                                                    modifier = Modifier.color(Colors.White)
                                                                )
                                                            }
                                                        }
                                                    }
                                                }

                                                Box(
                                                    modifier = Modifier.align(Alignment.BottomCenter)
                                                        .height(90.percent)
                                                        .fillMaxWidth()
                                                ) {
                                                    if (isHovered) {
                                                        if (isVideoDurationPlaying) {
                                                            VideoProgressSliderCustom(
                                                                modifier = Modifier.fillMaxWidth(97.percent)
                                                                    .align(Alignment.BottomCenter)
                                                                    .margin(bottom = 20.px)
                                                                    .zIndex(9999),
                                                                progress = videoProgress,
                                                                onProgressChange = { newProgress ->
                                                                    val videoElement = document.getElementById("video_$sessionId") as? HTMLVideoElement
                                                                    val audioElement = currentAudioRefHighlight.value

                                                                    videoElement?.let { video ->
                                                                        val newTime = sentenceData.startTime + (newProgress * (sentenceData.endTime - sentenceData.startTime))

                                                                        video.currentTime = newTime
                                                                        // Update audio position
                                                                        audioElement?.currentTime = newTime

                                                                        // Update progress state
                                                                        videoProgress = ((newTime - sentenceData.startTime) / (sentenceData.endTime - sentenceData.startTime)).toFloat()

                                                                        // If video was playing, resume playback from new position
                                                                        if (isVideoDurationPlaying) {
                                                                            video.play()
                                                                            audioElement?.play()
                                                                        }
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    }

                                                        LaunchedEffect(sessionId) {
                                                            val videoElement = document.getElementById("video_$sessionId") as? HTMLVideoElement
                                                            videoElement?.muted = true // Ensure it's always muted
                                                        }
                                                        Video(
                                                            attrs = {
                                                                attr("id", "video_$sessionId")
                                                                attr("src", getSentenceVideo)
                                                                attr("muted", "true")
                                                                style {
                                                                    property("width", "100%")
                                                                    property("height", "100%")
                                                                    property("border-radius", "8px")
                                                                    property("object-fit", "cover")
                                                                    property(
                                                                        "align",
                                                                        "bottom center"
                                                                    )
                                                                }
                                                            }
                                                        )

                                                    }
                                                }
                                            if (getSentences.size > 1) {
                                                Row(
                                                    modifier = Modifier.fillMaxWidth()
                                                        .margin(topBottom = 10.px),
                                                    verticalAlignment = Alignment.CenterVertically,
                                                    horizontalArrangement = Arrangement.SpaceBetween
                                                ) {
                                                    FieldTitleTextNormalSimple(
                                                        text = "${startIndex + 1}/$totalDots",
                                                        fontSize = 16.px,
                                                        fontWeight = 500
                                                    )
                                                    Row(
                                                        modifier = Modifier.fillMaxWidth(),
                                                        horizontalArrangement = Arrangement.Center,
                                                        verticalAlignment = Alignment.CenterVertically
                                                    ) {
                                                        visibleDotsForCoach.forEach { index ->
                                                            Box(
                                                                modifier = Modifier
                                                                    .size(if (index == startIndex) 8.px else 9.px)
                                                                    .backgroundColor(if (index == startIndex) Colors.Gray else Colors.LightGray)
                                                                    .borderRadius(100.px)
                                                                    .margin(leftRight = 5.px)
                                                                    .cursor(Cursor.Pointer)
                                                                    .onClick {
                                                                        startIndex = index
                                                                    }
                                                            )
                                                        }
                                                    }

                                                    Row(Modifier.gap(4.px)) {
                                                        Image(
                                                            src = Res.Images.arrow_Left,
                                                            modifier = Modifier.cursor(Cursor.Pointer)
                                                                .onClick {
                                                                    if (startIndex > 0) {
                                                                        startIndex--
                                                                        if (startIndex < visibleDotStartIndex) {
                                                                            visibleDotStartIndex =
                                                                                maxOf(
                                                                                    0,
                                                                                    visibleDotStartIndex - 1
                                                                                )
                                                                        }
                                                                    }
                                                                }
                                                        )
                                                        Image(
                                                            src = Res.Images.arrow_Right,
                                                            modifier = Modifier.cursor(Cursor.Pointer)
                                                                .onClick {
                                                                    if (startIndex < totalDots - 1) {
                                                                        startIndex++
                                                                        if (startIndex >= visibleDotStartIndex + maxDots) {
                                                                            visibleDotStartIndex =
                                                                                minOf(
                                                                                    totalDots - maxDots,
                                                                                    visibleDotStartIndex + 1
                                                                                )
                                                                        }
                                                                    }
                                                                }
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                        } else {
                            Box(
                                modifier = Modifier.fillMaxSize(),
                                contentAlignment = Alignment.Center
                            ) {
                                FieldTitleTextNormalSimple(
                                    text = "No data available",
                                    fontSize = 16.px,
                                    fontWeight = 500
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

fun encodeFileName(fileName: String): String {
    return fileName.replace(" ", "%20")
        .replace(":", "%3A")
        .replace("-", "%2D")
        .replace(".", "%2E")
    // Add other characters as needed
}
