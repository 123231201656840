package org.example.zone360.pages.coachDetails.charts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.dom.svg.Circle
import com.varabyte.kobweb.compose.dom.svg.Defs
import com.varabyte.kobweb.compose.dom.svg.LinearGradient
import com.varabyte.kobweb.compose.dom.svg.Stop
import com.varabyte.kobweb.compose.dom.svg.Svg
import com.varabyte.kobweb.compose.dom.svg.SvgId
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.style.KobwebComposeStyleSheet.attr
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.utils.Constants
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import kotlin.math.PI


//@Composable
//fun CircularProgressIndicator(
//    percentage: Float,
//    size: Int = 102,
//    strokeWidth: Int = 10,
//    fontSize: CSSLengthNumericValue? = null,
//    primaryColor: CSSColorValue,
//    backgroundColor: CSSColorValue,
//) {
//    val normalizedPercentage = (percentage / 100f).coerceIn(0f, 1f)
//    val radius = (size - strokeWidth) / 2
//    val circumference = (2 * PI * radius).toFloat()
//    val progressValue = circumference * (1f - normalizedPercentage)
//
//    Box(
//        modifier = Modifier.size(size.px),
//        contentAlignment = Alignment.Center
//    ) {
//        Box(
//            modifier = Modifier
//                .size(size.px)
//                .position(Position.Relative)
//
//        ) {
//            // Using SVG element directly instead of embedding in style
//            Svg(
//                attrs = {
//                    attr("viewBox", "0 0 $size $size")
//                    style {
//                        property("transform", "rotate(-90deg)")
//                        property("position", "absolute")
//                    }
//                }
//            ) {
//                // Background circle
//                Circle(
//                    attrs = {
//                        attr("cx", "${size / 2}")
//                        attr("cy", "${size / 2}")
//                        attr("r", "$radius")
//                        attr("fill", "none")
//                        attr("stroke", backgroundColor.toString())
//                        attr("stroke-width", "$strokeWidth")
//                    }
//                )
//
//                // Progress circle
//                Circle(
//                    attrs = {
//                        attr("cx", "${size / 2}")
//                        attr("cy", "${size / 2}")
//                        attr("r", "$radius")
//                        attr("fill", "none")
//                        attr("stroke", primaryColor.toString())
//                        attr("stroke-width", "$strokeWidth")
//                        attr("stroke-dasharray", "$circumference")
//                        attr("stroke-dashoffset", "$progressValue")
//                        style {
//                            property("transition", "stroke-dashoffset 0.3s ease-in-out")
//                        }
//                    }
//                )
//            }
//        }
//
//        // Percentage text
//        FieldTitleTextNormalSimple(
//            text = "${percentage.toInt()}%",
//            fontWeight = 700,
//            fontSize = fontSize ?: 19.px,
//            fontFamily = Constants.IBM_FONT
//        )
//    }
//}

@Composable
fun CircularProgressIndicator(
    percentage: Float,
    size: Int = 102,
    strokeWidth: Int = 10,
    fontSize: CSSLengthNumericValue? = null,
    primaryColor: CSSColorValue,
    secondaryColor: CSSColorValue? = null, // Optional for gradient
    backgroundColor: CSSColorValue,
) {
    val normalizedPercentage = (percentage / 100f).coerceIn(0f, 1f)
    val radius = (size - strokeWidth) / 2
    val circumference = (2 * PI * radius).toFloat()
    val progressValue = circumference * (1f - normalizedPercentage)

    Box(
        modifier = Modifier.size(size.px),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier
                .size(size.px)
                .position(Position.Relative)
        ) {
            // Using SVG element directly instead of embedding in style
            Svg(
                attrs = {
                    attr("viewBox", "0 0 $size $size")
                    style {
                        property("transform", "rotate(-90deg)")
                        property("position", "absolute")
                    }
                }
            ) {
                // Define Gradient only if secondaryColor is provided
                if (secondaryColor != null) {
                    Defs {
                        LinearGradient(SvgId("gradientStroke")) {
                            attr("x1", "0%")  // Using attr instead of named parameters
                            attr("y1", "0%")
                            attr("x2", "100%")
                            attr("y2", "0%")

                            Stop {
                                attr("offset", "0%")
                                attr("stop-color", primaryColor.toString())
                            }
                            Stop {
                                attr("offset", "100%")
                                attr("stop-color", secondaryColor.toString())
                            }
                        }
                    }
                }

                // Background circle
                Circle(
                    attrs = {
                        attr("cx", "${size / 2}")
                        attr("cy", "${size / 2}")
                        attr("r", "$radius")
                        attr("fill", "none")
                        attr("stroke", backgroundColor.toString())
                        attr("stroke-width", "$strokeWidth")
                    }
                )

                // Progress circle
                Circle(
                    attrs = {
                        attr("cx", "${size / 2}")
                        attr("cy", "${size / 2}")
                        attr("r", "$radius")
                        attr("fill", "none")
                        attr(
                            "stroke",
                            if (secondaryColor != null) "url(#gradientStroke)" else primaryColor.toString()
                        )
                        attr("stroke-width", "$strokeWidth")
                        attr("stroke-dasharray", "$circumference")
                        attr("stroke-dashoffset", "$progressValue")
                        attr("stroke-linecap", "round")
                        style {
                            property("transition", "stroke-dashoffset 0.3s ease-in-out")
                        }
                    }
                )
            }
        }

        // Percentage text
        FieldTitleTextNormalSimple(
            text = "${percentage.toInt()}%",
            fontWeight = 700,
            fontSize = fontSize ?: 19.px,
            fontFamily = Constants.IBM_FONT
        )
    }
}



