package org.example.zone360.utils

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import kotlinx.browser.localStorage
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.example.zone360.models.GetAllTeamsData
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.models.GetClubKeywordData
import org.example.zone360.models.GetPlayerData
import org.example.zone360.models.SessionData
import org.example.zone360.models.UserData

object Constants {
    const val PAGE_WIDTH = 2560
    const val HEADER_HEIGHT = 80
    const val DASHBOARD_HEADER_HEIGHT = 70
    const val FONT_FAMILY = "Inter";
    const val IBM_FONT = "IBM Plex Sans";
    const val EMAIL_REGEX = "^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+.[A-Za-z0-9.-]"

    ///
    const val COLOR_MODE_KEY = "COLOR_MODE_KEY"

    ///
    const val USER_KEY = "USER_KEY"
}
object Res {
    // Images
    object Images {
        const val logosvg = "logo.svg"
        const val logopng = "logo.png"
        const val faviconico = "favicon.ico"
        const val whistle = "whistle.svg"
        const val footballicon = "football.svg"
        const val matteo = "matteo.svg"
        const val alex = "alex.svg"
        const val brianPic = "briananderson.svg"
        const val michealPic = "michealbrown.svg"
        const val johnPic = "johnsmith.svg"
        const val kevinPic = "kevin.svg"
        const val playbutton = "playbutton.svg"
        const val pausebutton = "pausebutton.svg"
        const val groupGraph = "graphgroup.svg"
        const val tactical = "tactical.svg"
        const val technical = "technical.svg"
        const val fitness = "fitness.svg"
        const val mental = "mental.svg"
        const val goalkeeper = "goalkeeper.svg"
        const val blueCircle = "bluecircle.svg"
        const val club_Performance_Chart = "club_performance_chart.svg"
        const val updated_graph_var = " updated_graph_var.png"
        const val line = "line.svg"
        const val technicalChart = "technicalChart.svg"
        const val tacticalChart = "tactical_Chart.svg"
        const val mentalChart = "mentalChart.svg"
        const val fitnessChart = "fitnessChart.svg"
        const val goalkeeperChart = "goalkeeperChart.svg"
        const val userIcon = "userIcon.png"
        const val timeCircle = "Time_circle.svg"
        const val calender = "Calendar.svg"
        const val coachImageUrl = "https://zone-360-images.s3.us-south.cloud-object-storage.appdomain.cloud/"
        const val cornerResize = "cornerResize.png"
        const val coachPic = "coachPic.svg"
        const val keywordIcon = "keywordIcon.svg"
        const val nationalityFlag = "nationality.svg"
        const val teamIcon = "teamIcon.svg"
        const val downloadPrimary = "downloadPrimary.svg"
        const val checkmark = "check_solid.svg"
        const val recording_Voice = "record_voice_over.svg"
        const val percentageChart = "percentagechart-graphic.svg"
        const val spiderChart = "spider_chart.svg"
        const val toneChart = "tone_chart.svg"
        const val recordingPlay = "recording_playing.svg"
        const val sentimental_Ratio = "sentimentalRatio.svg"
        const val play_all_button = "play_all_button.svg"
        const val excitedEmoji = "excitedEmoji.svg"
        const val positiveEmoji = "positiveEmoji.svg"
        const val groundSvg = "ground.svg"
        const val heightMeasure = "heightMeasurement.svg"
        const val weightMeasurement = "weightMeasurement.svg"
        const val arrow_Left = "arrow-left.svg"
        const val arrow_Right = "arrow_right.svg"
        const val cross_Icon = "crossIcon.svg"
        const val vector_Next = "vector_Next.svg"
        const val assistAiBibi = "AssistAi.svg"
        const val assistAi = "AssistAiBaba.svg"
        const val connect_Device = "connect_device.svg"
        const val am_Attacking = "am_attacking.svg"
        const val dm_svg = "dm.svg"
        const val gk_svg = "gk_goalkeeper.svg"
        const val grondFrame = "grondFrame.svg"
        const val lcd_svg = "lcd.svg"
        const val lld_svg = "lld.svg"
        const val lw_svg = "lw.svg"
        const val rcd_svg = "rcd.svg"
        const val rld_svg = "rld.svg"
        const val rw_svg = "rw.svg"
        const val st_svg = "st.svg"
        const val send_Button = "sendButton.svg"
        const val cross_Red = "remove.png"
        const val field_boundary = "field_boundary.svg"

    }


    object Id {

        // Input IDs
        const val usernameInput = "usernameInput"
        const val emailInput = "emailInput"
        const val passwordInput = "passwordInput"
        const val userSearchInput = "userSearchInput"
        const val coachName = "coachName"
        const val teamName = "teamName"
        const val firstName = "firstNameInput"
        const val lastName = "lastNameInput"
        const val orginization = "organizationInput"
        const val fileInput = "fileInput"
        const val dashboradPage = "dashboardPage"
        const val coachesPage = "coachesPage"
        const val playersPage = "playersPage"
        const val nationality = "nationality"
        const val coachId = "coachId"
        const val messageAiInput = "messageAiInput"
        const val scrollContainerId = "chatScrollContainer"


    }

    object Audio{
        const val base_Audio_URL = "https://cloud-object-storage-cos-standard-audio-storage.s3.us-south.cloud-object-storage.appdomain.cloud/"
        const val testAudio = "test_audio.aac"
    }

    object Video{
        const val traing_Video_YT = "training_video.mp4"
        const val video_For_Demo = "https://zone-360-images.s3.us-south.cloud-object-storage.appdomain.cloud/Juventus%202014%20DNA%20Prep%20Recording%20-%20Tactical%20(1).mp4"
    }
}

object URL {
    const val platformUrl = "https://varcity.io/platform"
    const val pricingUrl = "https://varcity.io/pricing"
    const val companyUrl = "https://varcity.io/company"
    const val careerUrl = "https://varcity.io/careers"
    const val supportUrl = "https://sites.google.com/varcity.io/varcitysupport/home"
    const val contactUrl = "https://varcity.io/contactUs"
    const val partnersUrl = "https://varcity.io/partners"

}

enum class TimePeriod{
    OneMonth, ThreeMonth, SixMonth, OneYear, AllTime
}
enum class SelectedLanguage{
    English, Italian
}
enum class SelectedData{
    Training, Matches
}

enum class UploadedType{
    You, SharedByCoach
}

enum class CoachesOrPlayer{
    Coaches, Players
}

enum class RecentHighlights{
    RecentAssessment, RecentSession
}

class AppGlobal {
    companion object {
        var userData: UserData
            get() {
                val data = localStorage.getItem("userData")
                return if (data != null) {
                    Json.decodeFromString(data)
                } else {
                    UserData()
                }
            }
            set(value) {
                localStorage.setItem("userData", Json.encodeToString(value))
            }

        //
        val clubCoachesList =  mutableStateListOf<GetClubCoachesData>()
        val clubSessionList =  mutableStateListOf<SessionData>()
        val keywordResponse = mutableStateListOf<GetClubKeywordData>()
        val clubPlayersList =  mutableStateListOf<GetPlayerData>()
        val teamDataList = mutableStateListOf<GetAllTeamsData>()





    }
}