package org.example.zone360.pages.dashboard.coaches

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaPlus
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CustomDivider
import org.example.zone360.components.SearchBar
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubCoachesData
import org.example.zone360.models.GetPlayerData
import org.example.zone360.models.GetSessionsData
import org.example.zone360.pages.dashboard.playerAssessment.PlayersData
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.CoachesOrPlayer
import org.example.zone360.utils.Divider
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CoachesLayout(
    coachesData: List<GetClubCoachesData>,
    playerData: List<GetPlayerData>,
    breakpoint: Breakpoint,
    onCoachesRecommendationClick: (GetClubCoachesData) -> Unit,
    onPlayerRecommendationClick: (GetPlayerData) -> Unit,
    addNewCoach: () -> Unit,
    addNewPlayer: () -> Unit,
    deletePlayer: () -> Unit
) {
    val sitePalette = ColorMode.current.toSitePalette()
    var selectedCoach by remember { mutableStateOf<GetClubCoachesData?>(null) }
    val sortedData by remember { mutableStateOf(coachesData) }
    var isSortedDescending by remember { mutableStateOf(true) }
    var isHovered by remember { mutableStateOf(false) }
    var isSelected by remember { mutableStateOf(CoachesOrPlayer.Coaches) }
    var searchText by remember { mutableStateOf("") }

    val scope = rememberCoroutineScope()

    for (player in playerData) {
        println("player0: $player")
    }

//
//    val filteredData = remember { mutableStateListOf<GetPlayerData>() }
//    scope.launch{
//        println("LaunchedEffect")
//        if (playerData.isNotEmpty()) {
//
//            filteredData.clear()
//            filteredData.addAll(playerData)
//
//        }
//        for (player in filteredData) {
//            println("player1: $player")
//        }
//
//    }
//    LaunchedEffect(searchText) {
//        filteredData.clear()
//        filteredData.addAll(
//            if (searchText.isEmpty()) playerData
//            else playerData.filter { player->
//                val playerName = "${ player.firstName} ${ player.lastName}"
//            playerName.contains(searchText, ignoreCase = true) }
//        )
//    }
    val filteredData = remember(searchText, playerData) {
        if (searchText.isEmpty()) playerData
        else playerData.filter { player ->
            val playerName = "${ player.firstName} ${ player.lastName}"
            playerName.contains(searchText, ignoreCase = true)
        }
    }
    println("Filtered data: ${filteredData.joinToString("\n") { it.toString() }}")

    val filteredCoach = remember(searchText, coachesData) {
        if (searchText.isEmpty()) coachesData
        else coachesData.filter { coach ->
            val coachName = "${ coach.firstName} ${ coach.lastName}"
            coachName.contains(searchText, ignoreCase = true)
        }
    }

    Box(
        modifier = Modifier
            .then(
                if ( window.screen.width > 765) Modifier.height(915.px)
                else Modifier.maxHeight(915.px)
            )
            .width(if (breakpoint > Breakpoint.LG) 68.percent else if(breakpoint == Breakpoint.LG) 59.percent
            else if(breakpoint >= Breakpoint.MD)54.percent else 1360.px)
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .borderRadius(15.px)
            .overflow(
                overflowY = Overflow.Hidden,
                overflowX =   Overflow.Auto
            )
            .padding(
                leftRight = if (breakpoint >= Breakpoint.LG) 30.px else 20.px,
                topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
            )
    ) {
        Column(
            modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight()
        ) {
            Row (modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween){
                Row (modifier = Modifier.gap(20.px)){
                    Column(horizontalAlignment = Alignment.CenterHorizontally){
                        FieldTitleTextNormalSimple(
                            text = "Coaches",
                            fontSize = 18.px,
                            fontWeight = 600,
                            isClickable = true,
                            color = if (isSelected == CoachesOrPlayer.Coaches) sitePalette.primary.rgb else sitePalette.titleColor.rgb,
                            onClick = {
                                isSelected = CoachesOrPlayer.Coaches
                            }
                        )
                        if (isSelected == CoachesOrPlayer.Coaches) {
                            CustomDivider(color = sitePalette.primary.rgb)
                        }

                    }

                    Column(horizontalAlignment = Alignment.CenterHorizontally){
                        FieldTitleTextNormalSimple(
                            text = "Players",
                            fontSize = 18.px,
                            fontWeight = 600,
                            isClickable = true,
                            color = if (isSelected == CoachesOrPlayer.Players) sitePalette.primary.rgb else sitePalette.titleColor.rgb,
                            onClick = {
                                isSelected = CoachesOrPlayer.Players
                            }
                        )

                        if (isSelected == CoachesOrPlayer.Players) {
                            CustomDivider(color = sitePalette.primary.rgb)
                        }

                    }

                    if (isSelected == CoachesOrPlayer.Players){
                        SearchBar(
                            placeholder = "Search player...",
                            id = "searchbar",
                            breakpoint = Breakpoint.LG,
                            modifier = Modifier.fillMaxWidth().margin(left = 20.px),
                            onEnterClick = {},
                            onChange = { newSearchText ->
                                searchText = newSearchText
                            },
                            onClick = {},
                            width = 250.px,
                            showClearButton = false
                        )
                    } else{
                        SearchBar(
                            placeholder = "Search coach...",
                            id = "searchbarCoach",
                            breakpoint = Breakpoint.LG,
                            modifier = Modifier.fillMaxWidth().margin(left = 20.px),
                            onEnterClick = {},
                            onChange = { newSearchText ->
                                searchText = newSearchText
                            },
                            onClick = {},
                            width = 250.px,
                            showClearButton = false
                        )
                    }
                }
                if (isSelected == CoachesOrPlayer.Coaches) {
                    if(AppGlobal.userData.role == "admin") {
                        CommonButton(
                            text = "Add New Coach",
                            icon = { FaPlus(size = IconSize.LG) },
                            width = 150.px,
                            height = 40.px,
                            onCLick = {
                                addNewCoach()
                            })
                    }
                } else{

                    CommonButton(
                        text = "Add New Player",
                        icon = { FaPlus(size = IconSize.LG) },
                        width = 150.px,
                        height = 40.px,
                        onCLick = {
                            addNewPlayer()
                        })
                }
            }
    if (isSelected == CoachesOrPlayer.Coaches) {
        Row(
            modifier = Modifier.fillMaxWidth().height(50.px)
                .backgroundColor(sitePalette.coachesTitleRow.rgb),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Row(
                modifier = Modifier.fillMaxSize(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                FieldTitleTextNormalSimple(
                    text = "Name",
                    width = 130.px,
                    fontWeight = 500,
                    fontSize = 15.px
                )
                FieldTitleTextNormalSimple(
                    text = "Sessions",
                    width = 50.px,
                    fontWeight = 500,
                    fontSize = 13.px,
                    isClickable = true,
                )

                FieldTitleTextNormalSimple(
                    text = "Minutes",
                    width = 50.px,
                    fontWeight = 500,
                    fontSize = 13.px
                )
                FieldTitleTextNormalSimple(
                    text = "Speaking",
                    width = 50.px,
                    fontWeight = 500,
                    fontSize = 13.px
                )
                Row(
                    modifier = Modifier.width(60.px)
                        .onMouseEnter { isHovered = true }
                        .onMouseLeave { isHovered = false }
                        .onClick {
//                                println("Sort Icon Clicked!")
//                                isSortedDescending = !isSortedDescending
//                                sortedData = if (isSortedDescending) {
//                                    data.sortedByDescending { it.count }
//                                } else {
//                                    data.sortedBy { it.count }
//                                }
                        },
                    verticalAlignment = Alignment.CenterVertically
                ) {
//                        if (isHovered) {
//                            if (isSortedDescending) {
//                                FaSortDown(
//                                    modifier = Modifier
//                                        .color(sitePalette.primary.rgb)
//                                        .margin(right = 5.px)
//                                        .cursor(Cursor.Pointer)
//                                )
//                            } else {
//                                FaSortUp(
//                                    modifier = Modifier
//                                        .color(sitePalette.primary.rgb)
//                                        .margin(right = 5.px)
//                                        .cursor(Cursor.Pointer)
//                                )
//                            }
//                        } else {
//                            CustomSpacer(16.px)
//                        }
                    FieldTitleTextNormalSimple(
                        text = "Keywords",
                        width = 50.px,
                        fontWeight = 500,
                        fontSize = 13.px,
                        isClickable = false,
                    )
                }
//                    FieldTitleTextNormalSimple(
//                        text = "Efficiency",
//                        width = 50.px,
//                        fontWeight = 500,
//                        fontSize = 13.px
//                    )

                FieldTitleTextNormalSimple(
                    text = "Emphasis",
                    width = 50.px,
                    fontWeight = 500,
                    fontSize = 13.px
                )
                FieldTitleTextNormalSimple(
                    text = "Recommendations",
                    width = 120.px,
                    fontWeight = 500,
                    fontSize = 13.px
                )

            }
        }
        HorizontalDivider(
            modifier = Modifier.fillMaxWidth().margin(0.px).height((0.5).px)
                .backgroundColor(sitePalette.textFieldBorderColor.rgb)
        )
        Column(
            modifier = Modifier.fillMaxWidth().maxHeight(750.px)
                .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
        ) {
            if (filteredCoach.isNotEmpty()) {
                CoachesData(
                    data = filteredCoach,
                    onRecommendationClick = { coach ->
                        selectedCoach = coach
                        onCoachesRecommendationClick(coach)
                    }
                )
            }
        }
    } else{
        Row(
            modifier = Modifier.fillMaxWidth().height(50.px)
                .backgroundColor(sitePalette.coachesTitleRow.rgb),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(99.percent),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                FieldTitleTextNormalSimple(
                    text = "Name",
                    width = 130.px,
                    fontWeight = 500,
                    fontSize = 15.px
                )
                FieldTitleTextNormalSimple(
                    text = "Assessments",
                    width = 50.px,
                    fontWeight = 500,
                    isClickable = true,
                )

                FieldTitleTextNormalSimple(
                    text = "Coach",
                    width = 120.px,
                    fontWeight = 500,
                    textAlign = TextAlign.Center,

                    )
                FieldTitleTextNormalSimple(
                    text = "Team",
                    width = 90.px,
                    fontWeight = 500,
                    textAlign = TextAlign.Center,

                    )

                FieldTitleTextNormalSimple(
                    text = "Email",
                    width = 310.px,
                    fontWeight = 500,
                    textAlign = TextAlign.Center,
                )
                FieldTitleTextNormalSimple(
                    text = "Recommendations",
                    width = 100.px,
                    fontWeight = 500,
                    isClickable = false,
                    textAlign = TextAlign.Center
                )
//                        FieldTitleTextNormalSimple(
//                            text = "Action",
//                            width = 50.px,
//                            fontWeight = 500,
//                            isClickable = true,
//                        )

            }
        }
        HorizontalDivider(
            modifier = Modifier.fillMaxWidth().margin(0.px).height((0.5).px)
                .backgroundColor(sitePalette.textFieldBorderColor.rgb)
        )
        Column(
            modifier = Modifier.fillMaxWidth().maxHeight(750.px).height(750.px)
                .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
        ) {
            if (filteredData.isNotEmpty()) {
                PlayersData(
                    data = filteredData,
                    onRecommendationClick = onPlayerRecommendationClick,
                    deletePlayer = deletePlayer
                )
            }
        }
    }
        }
    }
}

@Composable
fun CoachesData(
    data: List<GetClubCoachesData>,
    onRecommendationClick: (GetClubCoachesData) -> Unit = {}
) {
    val ctx = rememberPageContext()
    val sitePalette = ColorMode.current.toSitePalette()
    val sortedCoaches = data.sortedWith(
        compareByDescending<GetClubCoachesData> { it.efficiency }
            .thenByDescending { it.count }
    )

    //
    sortedCoaches.forEach { coach ->
        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(topBottom = 10.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween

        ) {
            Row(
                modifier = Modifier.width(130.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        if (AppGlobal.userData.role == "coach") {
                            if (coach.userId == AppGlobal.userData.uid) {
                                ctx.router.navigateTo("/coach-details?userId=${coach.userId}")
                                println("Navigating to own profile: ${coach.firstName}")
                            } else {
                                println("Access denied: Coaches can only view their own profile.")
                            }
                        } else {
                            ctx.router.navigateTo("/coach-details?userId=${coach.userId}")
                            println("Admin navigating to coach: ${coach.firstName}")
                        }
                    },
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (coach.imageUrl.isNotEmpty()) {
                    Image(
                        src = "${Res.Images.coachImageUrl}${coach.imageUrl}",
                        modifier = Modifier.size(30.px).margin(right = 5.px)
                            .borderRadius(100.px).textAlign(TextAlign.Start)
                    )
                } else {
                    Image(
                        src = Res.Images.userIcon,
                        modifier = Modifier.size(30.px).margin(right = 7.px)
                            .borderRadius(100.px)
                    )
                }
                FieldTitleTextNormalSimple(
                    text = "${coach.firstName} ${coach.lastName}",
                    fontSize = 13.px,
                    isClickable = true
                )
            }
            FieldTitleTextNormalSimple(
                text = if(coach.count>0) coach.count.toString() else "-",
                width = 50.px,
                fontSize = 13.px,
                textAlign = TextAlign.Center
            )
            FieldTitleTextNormalSimple(
                text = if(coach.count>0)(coach.recordTime/1000).formatSessionMinutes() else "-",
                width = 50.px,
                fontSize = 13.px,
                textAlign = TextAlign.Center
            )
            FieldTitleTextNormalSimple(
                text = if(coach.count>0) "${
                    if (coach.recordTime == 0) 0 else (((coach.speakingTime*1000) / coach.recordTime)*100).toInt()
                } %" else "-",
                width = 60.px,
                fontSize = 13.px,
                textAlign = TextAlign.Center
            )
            FieldTitleTextNormalSimple(
                text = if(coach.count>0) coach.totalKeywords.toString() else "-", width = 60.px,
                fontSize = 13.px,
                textAlign = TextAlign.Center
            )
//            FieldTitleTextNormalSimple(
//                text = if (coach.count > 0) (coach.efficiency.asDynamic().toFixed(1) as String) else "-",
//                width = 50.px,
//                fontSize = 13.px,
//                textAlign = TextAlign.Center
//            )
            FieldTitleTextNormalSimple(
                text = coach.emphais.ifEmpty { "-" }.replaceFirstChar {
               if (it.isLowerCase()) it.titlecase() else it.toString()
                },
                fontSize = 13.px,
                width = 50.px,
                textAlign = TextAlign.Center
            )
            Box(modifier = Modifier.width(120.px),
                contentAlignment = Alignment.Center) {
                CommonButton(
                    text = "View",
                    textSpanColor = if (coach.count > 0) sitePalette.viewText.rgb else sitePalette.commonButtonSupportiveTextColor.rgb,
                    backgroundColor = if (coach.count > 0) sitePalette.viewButtonBackground.rgb else sitePalette.textFieldBorderColorDisabled.rgb,
                    width = 64.px,
                    height = 28.px,
                    pointerCursor = coach.count > 0,
                    onCLick = {
                        if (coach.count > 0) {
                            if (AppGlobal.userData.role == "coach") {
                                if (coach.userId == AppGlobal.userData.uid) {
                                    onRecommendationClick(coach)
                                    println("Coach button clicked: ${coach.firstName}")
                                } else {
                                    println("Access denied: Coaches can only view their own data.")
                                }
                            } else {
                                onRecommendationClick(coach)
                                println("Admin clicked on: ${coach.firstName}")
                            }
                        }
                    }
                )}

        }
        Divider()
    }

}