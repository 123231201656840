package org.example.zone360.pages.coachDetails.coachInfo

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaBriefcase
import com.varabyte.kobweb.silk.components.icons.fa.FaClock
import com.varabyte.kobweb.silk.components.icons.fa.FaEnvelope
import com.varabyte.kobweb.silk.components.icons.fa.FaFutbol
import com.varabyte.kobweb.silk.components.icons.fa.FaInfo
import com.varabyte.kobweb.silk.components.icons.fa.FaLayerGroup
import com.varabyte.kobweb.silk.components.icons.fa.FaPen
import com.varabyte.kobweb.silk.components.icons.fa.FaSuitcase
import com.varabyte.kobweb.silk.components.icons.fa.FaUserTie
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.icons.fa.IconStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import getAllTeams
import getSingleCoach
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonDropdown
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.components.showToast
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetSingleCoachData
import org.example.zone360.pages.dashboard.coaches.TeamTokenSearchForInfo
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.outline
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import updateUserData

@Composable
fun CoachInfo(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    userId : String,
    selectedSession: SelectedData,
    message: (String) -> Unit,
){
    val scope = rememberCoroutineScope()
    var isEditing by remember { mutableStateOf(false) }
    var isJobEditing by remember { mutableStateOf(false) }
    var isLevelEditing by remember { mutableStateOf(false) }
    var getSingleCoachData by remember { mutableStateOf(GetSingleCoachData()) }
    var nationality by remember { mutableStateOf("") }
    var job by remember { mutableStateOf("") }
    var level by remember { mutableStateOf("") }
    var selectedLevel by remember { mutableStateOf("Select Level") }

    var teamManagement by remember { mutableStateOf("") }
    var teamManagementEditing by remember { mutableStateOf(false) }
    val selectedTeams = remember { mutableStateListOf<String>() }


    val apiSessionType = when (selectedSession) {
        SelectedData.Matches -> "match"
        SelectedData.Training -> "training"
    }
    scope.launch {
        val res = getSingleCoach(
            organization = AppGlobal.userData.organization,
            domainType = "soccer",
            language = "en",
            sessionType = apiSessionType,
            userID =  userId)
        getSingleCoachData = res.data
        nationality = res.data.nationality
        job = res.data.job
        level = res.data.level
        teamManagement = res.data.teams!!.joinToString(", ")
        selectedTeams.clear()
        selectedTeams.addAll(res.data.teams)
    }
    LaunchedEffect(Unit) {
        scope.launch {
            try {
                val response = getAllTeams(
                    clubName = AppGlobal.userData.organization
                )
                AppGlobal.teamDataList.clear()
                AppGlobal.teamDataList.addAll(response.data)
            } catch (e: Exception) {
                console.error("Error fetching teams:", e)
            }
        }
    }
    Box (modifier = Modifier.fillMaxWidth()
//        .height(300.px)
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
//        .overflow(overflowX = Overflow.Hidden, overflowY = Overflow.Hidden)
        .borderRadius(11.34.px)
        .padding(leftRight = 10.px)
        .boxShadow(
          offsetX = 0.px,
            offsetY = 2.px,
            blurRadius = 16.px,
            spreadRadius = null,
            color = sitePalette.boxShadowColor.rgb
        )){
        Column(
            modifier = Modifier
                .fillMaxWidth()
//                .width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                .fillMaxHeight()
        ) {
     Box (modifier = Modifier.width(98.8.percent)) {
             Column(
                 modifier = Modifier.fillMaxWidth()
                     .padding(topBottom = 20.px)
             ) {
                 Row(modifier = Modifier.padding(leftRight = 20.px)
                     .flexWrap(FlexWrap.Wrap)) {

                     if (getSingleCoachData.imageUrl.isNotEmpty()) {
                         Image(
                             src = "${Res.Images.coachImageUrl}${getSingleCoachData.imageUrl}",
                             modifier = Modifier.width(120.px).height(120.px)
                                 .borderRadius(100.px)
                         )
                     } else {
                         Image(
                             src = Res.Images.userIcon,
                             modifier =  Modifier.width(120.px).height(120.px)
                                 .borderRadius(100.px)
                         )
                     }
                     Column(modifier = Modifier.margin(leftRight = 20.px, topBottom = 10.px)) {
                         println("${getSingleCoachData.firstName} ${getSingleCoachData.lastName}")

                             FieldTitleTextNormalSimple(
                                 text = "${getSingleCoachData.firstName} ${getSingleCoachData.lastName}",
                                 fontSize = 20.px,
                                 fontWeight = 600,
                                 fontFamily = Constants.IBM_FONT
                             )


                     Row(
                         modifier = Modifier
                             .flexWrap(FlexWrap.Wrap),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Row(
                             modifier = Modifier
                                 .margin(topBottom = 10.px)
                                 .flexWrap(FlexWrap.Wrap),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             FaFutbol(modifier = Modifier.color(Palette.InfoIconColor.rgbLight))
                             FieldTitleTextNormalSimple(
                                 text = "Head Coach -",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 marginLeft = 8.px
                             )
                             FieldTitleTextNormalSimple(
                                 text = " ${AppGlobal.userData.organization}",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 whiteSpace = WhiteSpace.PreWrap
                             )
                         }
                         CustomSpacer(50.px)
                         Row(
                             modifier = Modifier
                                 .margin(topBottom = 10.px)
                                 .flexWrap(FlexWrap.Wrap),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             FaEnvelope(modifier = Modifier.color(Palette.InfoIconColor.rgbLight), style = IconStyle.FILLED)
                             FieldTitleTextNormalSimple(
                                 text = "Email: ",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 marginLeft = 8.px
                             )
                             FieldTitleTextNormalSimple(
                                 text = getSingleCoachData.email,
                                 fontSize = 16.px,
                                 color = sitePalette.coachInfoTextColor.rgb,
                                 fontFamily = Constants.IBM_FONT
                             )
                         }
                     }
                         Row(
                             modifier = Modifier
                                 .flexWrap(FlexWrap.Wrap),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             Row(
                                 modifier = Modifier.gap(6.px)
                                     .margin(topBottom = 10.px)
                                     .flexWrap(FlexWrap.Wrap),
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 Image(src = Res.Images.nationalityFlag)
                                 FieldTitleTextNormalSimple(
                                     text = " Nationality: ",
                                     fontSize = 16.px,
                                     fontFamily = Constants.IBM_FONT,
                                     color = sitePalette.coachInfoTextColor.rgb,
                                 )
                                 if (isEditing) {
                                     Input(
                                         type = InputType.Text,
                                         attrs = {
                                             value(nationality)
                                             onInput { event ->
                                                 nationality = event.value
                                             }
                                             onKeyDown { event ->
                                                 if (event.key == "Enter") {
                                                     isEditing = false
                                                     scope.launch {
                                                         val jsonData = """{
                                                                "uid": "$userId",
                                                                "nationality": "$nationality"
                                                            }"""
                                                         val updateSuccess =
                                                             updateUserData(jsonData)
                                                         if (updateSuccess.data.nationality == nationality) {
                                                             getSingleCoachData =
                                                                 getSingleCoachData.copy(nationality = nationality)
                                                         } else {
                                                             println("Failed to update nationality")
                                                         }
                                                     }

                                                 }
                                             }
                                             style {
                                                 fontSize(16.px)
                                                 fontFamily(Constants.IBM_FONT)
                                                 color(sitePalette.coachInfoTextColor.rgb)
                                                 border(
                                                     1.px,
                                                     style = LineStyle.Solid,
                                                     color = sitePalette.coachInfoTextColor.rgb
                                                 )
                                                 outline("none")
                                                 borderRadius(8.px)

                                             }
                                         }
                                     )
                                 } else {
                                     FieldTitleTextNormalSimple(
                                         text = nationality,
                                         fontSize = 16.px,
                                         fontFamily = Constants.IBM_FONT,
                                         color = sitePalette.coachInfoTextColor.rgb,
                                         id = "nationality"
                                     )
                                 }
                                 FaPen(
                                     size = IconSize.XS,
                                     modifier = Modifier
                                         .color(sitePalette.coachInfoTextColor.rgb)
                                         .cursor(Cursor.Pointer)
                                         .onClick {
                                             isEditing = !isEditing
                                         }
                                 )
                             }
                             CustomSpacer(if(window.screen.width > 365 )30.px else 0.px)

                             Row(
                                 modifier = Modifier.gap(6.px)
                                     .margin(topBottom = 10.px)
                                     .flexWrap(FlexWrap.Wrap),
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 FaBriefcase(modifier = Modifier.color(Palette.InfoIconColor.rgbLight))
                                 FieldTitleTextNormalSimple(
                                     text = " Job: ",
                                     fontSize = 16.px,
                                     fontFamily = Constants.IBM_FONT,
                                     color = sitePalette.coachInfoTextColor.rgb,
                                 )
                                 if (isJobEditing) {
                                     Input(
                                         type = InputType.Text,
                                         attrs = {
                                             value(job)
                                             onInput { event ->
                                                 job = event.value
                                             }
                                             onKeyDown { event ->
                                                 if (event.key == "Enter") {
                                                     isJobEditing = false
                                                     scope.launch {
                                                         val jsonData = """{
                                                                "uid": "$userId",
                                                                "job": "$job"
                                                            }"""
                                                         val updateSuccess =
                                                             updateUserData(jsonData)
                                                         if (updateSuccess.data.job == job) {
                                                             getSingleCoachData =
                                                                 getSingleCoachData.copy(job = job)
                                                         } else {
                                                             println("Failed to update job")
                                                         }
                                                     }

                                                 }
                                             }
                                             style {
                                                 fontSize(16.px)
                                                 fontFamily(Constants.IBM_FONT)
                                                 color(sitePalette.coachInfoTextColor.rgb)
                                                 border(
                                                     1.px,
                                                     style = LineStyle.Solid,
                                                     color = sitePalette.coachInfoTextColor.rgb
                                                 )
                                                 outline("none")
                                                 borderRadius(8.px)

                                             }
                                         }
                                     )
                                 } else {
                                     FieldTitleTextNormalSimple(
                                         text = job,
                                         fontSize = 16.px,
                                         fontFamily = Constants.IBM_FONT,
                                         color = sitePalette.coachInfoTextColor.rgb,
                                         id = "job"
                                     )
                                 }
                                 FaPen(
                                     size = IconSize.XS,
                                     modifier = Modifier
                                         .color(sitePalette.coachInfoTextColor.rgb)
                                         .cursor(Cursor.Pointer)
                                         .onClick {
                                             isJobEditing = !isJobEditing
                                         }
                                 )
                             }
                             CustomSpacer(30.px)
                             Row(
                                 modifier = Modifier.gap(6.px)
                                     .margin(topBottom = 10.px)
                                     .flexWrap(FlexWrap.Wrap),
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 FaLayerGroup(modifier = Modifier.color(Palette.InfoIconColor.rgbLight))

                                 FieldTitleTextNormalSimple(
                                     text = " Level: ",
                                     fontSize = 16.px,
                                     fontFamily = Constants.IBM_FONT,
                                     color = sitePalette.coachInfoTextColor.rgb,
                                 )
                                 if (isLevelEditing) {
                                     Box {
                                         CommonDropdown(
                                             data = listOf(
                                                 "Youth Recreational",
                                                 "Youth Competitive",
                                                 "Adult Recreational",
                                                 "Adult Competitive",
                                                 "Select Level"
                                             ),
                                             onSelect = { selectedItem ->
                                                 selectedLevel = selectedItem
                                                 level = selectedItem

                                                 scope.launch {
                                                     val jsonData = """{
                                                            "uid": "$userId",
                                                            "level": "$level"
                                                        }"""
                                                     val updateSuccess = updateUserData(jsonData)
                                                     if (updateSuccess.data.level == level) {
                                                         getSingleCoachData =
                                                             getSingleCoachData.copy(level = level)
                                                     } else {
                                                         println("Failed to update level")
                                                     }
                                                 }
                                                 isLevelEditing = false
                                             },
                                             selectedItem = selectedLevel,
                                             width = if (window.screen.width > 375) 250.px else 190.px,
                                         )
                                     }
                                 } else {
                                     FieldTitleTextNormalSimple(
                                         text = level,
                                         fontSize = 16.px,
                                         fontFamily = Constants.IBM_FONT,
                                         color = sitePalette.coachInfoTextColor.rgb,
                                         id = "level"
                                     )
                                 }
                                 FaPen(
                                     size = IconSize.XS,
                                     modifier = Modifier
                                         .color(sitePalette.coachInfoTextColor.rgb)
                                         .cursor(Cursor.Pointer)
                                         .onClick {
                                             isLevelEditing = !isLevelEditing
                                         }
                                 )
                             }
                         }
                         Row(
                             modifier = Modifier.margin(topBottom = 10.px).gap(6.px)
                                 .flexWrap(FlexWrap.Wrap),
                             verticalAlignment = Alignment.CenterVertically
                         ) {
                             Image(src = Res.Images.teamIcon)
                             FieldTitleTextNormalSimple(
                                 text = "Teams Managing: ",
                                 fontSize = 16.px,
                                 fontFamily = Constants.IBM_FONT,
                                 color = sitePalette.coachInfoTextColor.rgb,
                             )

                             if (teamManagementEditing) {
                                 val teamDataList = AppGlobal.teamDataList.map { it.teamName }
                                 TeamTokenSearchForInfo(
                                     teams = teamDataList,
                                     selectedTeams = selectedTeams,
                                     onTeamSelected = { team ->
                                         if (!selectedTeams.contains(team)) {
                                             selectedTeams.add(team)
                                             message("Team added successfully!")
                                             showToast("toastSuccess")
                                         }
                                     },
                                     onTeamRemoved = { team ->
                                         selectedTeams.remove(team)
                                         message("Team removed successfully!")
                                         showToast("toastSuccess")
                                     },
                                     width = 150.px,
                                     userID = userId,
                                     onDismiss = {
                                         teamManagementEditing = false
                                         teamManagement = selectedTeams.joinToString(", ")

                                     }
                                 )
                                 CustomSpacer(20.px)
                             } else {
                                 FieldTitleTextNormalSimple(
                                     text = teamManagement,
                                     fontSize = 16.px,
                                     fontFamily = Constants.IBM_FONT,
                                     color = sitePalette.coachInfoTextColor.rgb,
                                     marginLeft = 8.px
                                 )
                             }

                                 FaPen(
                                     size = IconSize.XS,
                                     modifier = Modifier
                                         .color(sitePalette.coachInfoTextColor.rgb)
                                         .cursor(Cursor.Pointer)
                                         .onClick {
                                             teamManagementEditing = !teamManagementEditing
                                             teamManagement = selectedTeams.joinToString(", ")
                                         }
                                 )
                         }

                     }
                 }
                 Row(
                     modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px, leftRight = 20.px)
                         .flexWrap(FlexWrap.Wrap),
                     verticalAlignment = Alignment.CenterVertically,
                     horizontalArrangement = Arrangement.SpaceBetween
                 ) {
                     Row(
                         modifier = Modifier
                             .width(if(window.screen.width > 800 )33.percent else if(window.screen.width in 600..799)49.percent else 100.percent)
                             .margin(topBottom = 10.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             Image(src = Res.Images.footballicon)
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "Total Sessions",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         getSingleCoachData.count.toString(),
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }
                     Row(
                         modifier = Modifier
                             .width(if(window.screen.width > 800 )33.percent else if(window.screen.width in 600..799)49.percent else 100.percent)
                             .margin(topBottom = 10.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             FaClock(
                                 modifier = Modifier.color(sitePalette.primary.rgb),
                                 size = IconSize.XL,
                                 style = IconStyle.FILLED
                             )
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "Minutes Recorded",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         (getSingleCoachData.recordTime/1000).formatSessionMinutes(),
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }
                     Row(
                         modifier = Modifier
                             .width(if(window.screen.width > 800 )33.percent else if(window.screen.width in 600..799)49.percent else 100.percent)
                             .margin(topBottom = 10.px),
                         verticalAlignment = Alignment.CenterVertically
                     ) {
                         Box(
                             modifier = Modifier
                                 .width(60.px)
                                 .height(60.px)
                                 .backgroundColor(sitePalette.textFieldBorderColorDisabled.rgb)
                                 .borderRadius(100.px)
                                 .margin(right = 10.px),
                             contentAlignment = Alignment.Center
                         ) {
                             Image(src = Res.Images.keywordIcon)
                         }
                         Column {
                             Row(
                                 verticalAlignment = Alignment.CenterVertically
                             ) {
                                 SpanText(
                                     "No. of Keywords",
                                     modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                         .fontSize(15.px)
                                 )
                                 Box(
                                     modifier = Modifier
                                         .width(14.px)
                                         .height(14.px)
                                         .backgroundColor(Colors.LightGray)
                                         .borderRadius(100.px)
                                         .margin(leftRight = 10.px),
                                     contentAlignment = Alignment.Center
                                 ) {
                                     FaInfo(
                                         modifier = Modifier.color(Colors.White)
                                             .cursor(Cursor.Pointer), size = IconSize.XXS
                                     )
                                 }
                             } to
                                     SpanText(
                                         getSingleCoachData.totalKeywords.toString(),
                                         modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                                             .fontWeight(
                                                 FontWeight.Bold
                                             ).margin(top = 6.px)
                                     )
                         }
                     }

                 }

             }
         }
     }
    }
}
