package org.example.zone360.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaSortDown
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.example.zone360.components.MenuDropdown
import org.example.zone360.models.MenuModel
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants.DASHBOARD_HEADER_HEIGHT
import org.example.zone360.utils.Constants.FONT_FAMILY
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun DashboardHeaderSection() {
    val sitePalette = ColorMode.current.toSitePalette()
    val headerHeight by remember { mutableStateOf(DASHBOARD_HEADER_HEIGHT) }
    Box(
        modifier = Modifier.fillMaxSize().backgroundColor(sitePalette.headerBackgroundColor.rgb)
            .boxShadow(color = sitePalette.modalBackgroundColor.rgb, offsetX = 2.px, offsetY = 2.px)
            .maxHeight(headerHeight.px),
        contentAlignment = Alignment.Center
    ) {
        Box(
            modifier = Modifier.fillMaxSize().backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .fillMaxWidth()
                .padding(leftRight = if (window.screen.width > 400 )60.px else 15.px, topBottom = 3.px),
            contentAlignment = Alignment.Center
        ) {
           DashboardHeader()

        }
    }
}

@Composable
fun DashboardHeader() {
    val ctx = rememberPageContext()
    val sitePalette = ColorMode.current.toSitePalette()
    var isDropdownOpen by remember { mutableStateOf(false) }

    Row(
        modifier = Modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(src = Res.Images.logosvg, modifier = Modifier
            .cursor(Cursor.Pointer)
            .color(sitePalette.primary.rgb)
            .size(140.px)
            .onClick {
                if (AppGlobal.userData.role != "player") {
                    ctx.router.navigateTo("/home")
                }
            })
        Spacer()
        if (AppGlobal.userData.imageUrl.isNotEmpty()) {
            Image(
                src = "${Res.Images.coachImageUrl}${AppGlobal.userData.imageUrl}",
                modifier = Modifier.size(30.px).margin(right = 8.px)
                    .borderRadius(100.px).textAlign(TextAlign.Start)
                    .border (width = 2.px, color = sitePalette.primary.rgb, style = LineStyle.Solid)
            )
        } else {
            Image(
                src = Res.Images.userIcon,
                modifier = Modifier.size(25.px).margin(right = 7.px)
                    .borderRadius(100.px)
            )
        }
        SpanText("${AppGlobal.userData.firstName} ${AppGlobal.userData.lastName}",
            modifier = Modifier.color(sitePalette.textFieldTextColor.rgb).fontFamily(FONT_FAMILY).margin(right = 8.px))
        Box(Modifier.margin(right = 15.px)) {
            MenuDropdown(
                icon = { FaSortDown(modifier = Modifier.color(sitePalette.textFieldTextColor.rgb)) },
                data = listOfNotNull(
                    MenuModel(text = "Logout", route = ""),
                    if (AppGlobal.userData.role == "coach") {
                        MenuModel(text = "My Profile", route = "/coach-details")
                    } else {
                        null
                    }
                ),
                onSelect = {
                    println("selected: $it")
                    when (it) {
                        "Logout" -> {
                            localStorage.clear()
                        ctx.router.navigateTo("/login")
                        }
                        "My Profile" -> {
                            ctx.router.navigateTo("/coach-details?userId=${AppGlobal.userData.uid}")
                        }
                    }
                }

            )
        }
        }

        }