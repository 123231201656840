package org.example.zone360.pages.coachDetails.sessionAllDetails

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onFocusIn
import com.varabyte.kobweb.compose.ui.modifiers.onKeyUp
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubPlayerData
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.example.zone360.utils.noBorder
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.Event
import org.w3c.dom.events.EventListener
import tagPlayers

@Composable
fun PlayerTokenSearch(
    sitePalette: SitePalette,
    breakpoint: Breakpoint,
    players: List<GetClubPlayerData>,
    onPlayerSelected: (sentenceId: String, GetClubPlayerData) -> Unit,
    onPlayerRemoved: (sentenceId: String, GetClubPlayerData) -> Unit,
    selectedPlayersMap: Map<String, List<GetClubPlayerData>>? = null, // Optional map-based selection
    selectedPlayersList: MutableList<GetClubPlayerData>? = null,
    sentenceId: String,
    searchInputId: String = "player_search_$sentenceId"
) {
    var showDropdown by remember { mutableStateOf(false) }
    var filteredPlayers by remember { mutableStateOf<List<GetClubPlayerData>>(emptyList()) }
    var isHover by remember { mutableStateOf(false) }
    val scope = rememberCoroutineScope()
    val currentSelectedPlayers = selectedPlayersMap?.get(sentenceId).orEmpty() +
        selectedPlayersList.orEmpty()

    DisposableEffect(showDropdown) {
        val listener = EventListener { event ->
            if (showDropdown) {
                val target = event.target as? Element
                if (target != null && target.closest(".services-dropdown, .dropdown-trigger") == null) {
                    showDropdown = false
                }
            }
        }
        window.addEventListener("click", listener)
        onDispose {
            window.removeEventListener("click", listener)
        }
    }
    Box(modifier = Modifier.fillMaxWidth()
        .position(Position.Relative)) {
        Row(
            modifier = Modifier
                .minWidth(if (breakpoint > Breakpoint.MD) 390.px else 100.percent)
                .minHeight(48.px)
                .backgroundColor(sitePalette.searchBackground.rgb)
                .borderRadius(6.px)
                .border(1.px, style = LineStyle.Solid, color = sitePalette.textFieldBorderColor.rgb)
                .padding(leftRight = 8.px)
                .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                .flexWrap(FlexWrap.Wrap)
                .gap(7.px)
                .alignItems(AlignItems.Center),
            verticalAlignment = Alignment.CenterVertically
        ) {
            currentSelectedPlayers.forEach { player ->
                    Row(
                        modifier = Modifier.gap(4.px)
                            .height(28.px)
                            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                            .borderRadius(4.px)
                            .padding(6.px),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        FieldTitleTextNormalSimple(
                            "${player.firstName} ${player.lastName}",
                            fontSize = 12.px,
                        )
                        Image(
                            src = Res.Images.cross_Icon,
                            modifier = Modifier.onClick {
                                scope.launch {
                                    onPlayerRemoved(sentenceId, player)
                                    tagPlayers(playerID = player.uid, sentenceID = sentenceId, isRemove = true)
                                }
                            }
                                .cursor(Cursor.Pointer)
                        )
                }
            }
            Input(
                type = InputType.Text,
                attrs = Modifier
                    .width(100.px)
                    .height(32.px)
                    .color(sitePalette.textFieldTextColor.rgb)
                    .backgroundColor(Colors.Transparent)
                    .noBorder()
                    .fontFamily(Constants.IBM_FONT)
                    .fontSize(13.px)
                    .classNames("dropdown-trigger")
                    .onFocusIn { showDropdown = true
                    filteredPlayers = players
                    it.stopPropagation()}
                    .onKeyUp {
                        val searchText = (document.getElementById(searchInputId) as HTMLInputElement).value
                        filteredPlayers = if (searchText.isNotEmpty()) {
                            players.filter { player ->
                                val fullName = "${player.firstName} ${player.lastName}".lowercase()
                                val email = player.email.lowercase()
                                val search = searchText.lowercase()
                                (fullName.contains(search) || email.contains(search)) &&
                                        currentSelectedPlayers.none { it.uid == player.uid }
                            }
                        } else {
                            emptyList()
                        }
                        showDropdown = searchText.isNotEmpty()
                    }
                    .toAttrs {
                        id(searchInputId)
                        attr("placeholder", if (currentSelectedPlayers.isEmpty()) "Search players..." else "")
                    }
            )
        }

        if (showDropdown && filteredPlayers.isNotEmpty()) {
            Box(
                modifier = Modifier
                    .width(390.px)
                    .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                    .borderRadius(8.px)
                    .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                    .position(Position.Absolute)
                    .top(50.px)
                    .zIndex(10)
                    .maxHeight(200.px)
                    .overflow(Overflow.Auto)
            ) {
                Column {
                    filteredPlayers.forEach { player ->
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(8.px)
                                .cursor(Cursor.Pointer)
                                .onMouseEnter { isHover = true }
                                .onClick {
                                    scope.launch {
                                        onPlayerSelected(sentenceId, player)
//                                        if (currentSelectedPlayers.none { it.uid == player.uid }){
                                        tagPlayers(playerID = player.uid, sentenceID = sentenceId, isRemove = false)}
//                                    }
                                    (document.getElementById(searchInputId) as HTMLInputElement).value = ""
                                    showDropdown = false
                                }
                        ) {
                            Column {
                                FieldTitleTextNormalSimple(
                                    "${player.firstName} ${player.lastName}",
                                    fontFamily = Constants.IBM_FONT,
                                    fontSize = 14.px,
                                    fontWeight = 500
                                )
                                FieldTitleTextNormalSimple(
                                    player.email,
                                    fontFamily = Constants.IBM_FONT,
                                    fontSize = 12.px,
                                    color = Colors.Gray
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}



