package org.example.zone360.pages.dashboard.emphasisBreakdown

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.models.GetClubKeywordData
import org.example.zone360.models.GetClubKeywordResponse
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.pages.dashboard.chart.ChartPage
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun EmphasisBreakdown(
    keywordResponse: List<GetClubKeywordData>,
    selectedLanguage: SelectedLanguage,
    breakpoint: Breakpoint,
    isLoading: Boolean) {

    val sitePalette = ColorMode.current.toSitePalette()
    val scope = rememberCoroutineScope()
    var categoryData by remember { mutableStateOf(emptyMap<String, Int>()) }
    var totalCount by remember { mutableStateOf(0) }

    scope.launch {
        if (keywordResponse.isNotEmpty()) {
            val groupedData = keywordResponse
                .filter { it.value?.keywordCategory != null }
                .groupBy { it.value!!.keywordCategory!!.lowercase() }
                .mapValues { (_, values) ->
                    values.sumOf { it.value?.count ?: 0 }
                }

            totalCount = groupedData.values.sum()
            categoryData = groupedData
        }
    }

    val categories = listOf(
        (if (selectedLanguage == SelectedLanguage.English) "Tactical" else "Tattica") to Res.Images.tactical,
        (if (selectedLanguage == SelectedLanguage.English) "Fitness" else "Atletico") to Res.Images.fitness,
        (if (selectedLanguage == SelectedLanguage.English) "Mental" else "Mentale") to Res.Images.technical,
        (if (selectedLanguage == SelectedLanguage.English) "Technical" else "Tecnica") to Res.Images.technical,
        (if (selectedLanguage == SelectedLanguage.English) "GoalKeeper" else "Portiere") to Res.Images.goalkeeper
    )
    Box(
        modifier = Modifier
            .height(if (window.screen.width < 765) 260.px else 425.px)
            .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px)
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .width(
                if (breakpoint > Breakpoint.LG) 30.percent
                else if (breakpoint >= Breakpoint.LG) 39.percent
                else if (breakpoint >= Breakpoint.MD) 44.percent
                else 100.percent
            )
            .borderRadius(15.px)
            .flexWrap(FlexWrap.Wrap)
            .padding(if (breakpoint >= Breakpoint.LG) 40.px else 20.px)
    ) {
        Column(modifier = Modifier.fillMaxSize()) {
            SectionTitle(
                "Emphasis Breakdown",
                bottom = if (window.screen.width > 765) 50.px else 3.px
            )
            if (isLoading) {
                Box(
                    modifier = Modifier.fillMaxSize(),
                    contentAlignment = Alignment.Center
                ) {
                    ProgressIndicator(50.px)
                }
            } else {
                Row(
                    modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                        .flexWrap(if (window.screen.width in 765..1555)FlexWrap.Wrap else FlexWrap.Nowrap)
                    ,
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    // Chart
                    ChartPage(breakpoint, keywordResponse, selectedLanguage)

                    CustomSpacer(20.px)
                    if (window.screen.width !in 765..1555
                    ) {
                        // Percentages List
                        Column(modifier = Modifier.flexWrap(FlexWrap.Wrap).margin(top = 15.px)) {
                            categories.forEach { (category, image) ->
                                val categoryLower = category.lowercase()
                                val count = categoryData[categoryLower] ?: 0
                                val percentage = if (totalCount > 0) (count * 100) / totalCount else 0

                                Row {
                                    Image(src = image, modifier = Modifier.size(20.px))
                                    SpanText(
                                        "$category ($percentage%)",
                                        modifier = Modifier
                                            .margin(right = 5.px)
                                            .fontFamily(Constants.FONT_FAMILY)
                                            .margin(bottom = 15.px)
                                            .fontSize(13.px)
                                    )
                                    println("Category: $category, Count: $count, Percentage: $percentage")
                                }
                            }
                        }
                    } else {
                        Row(modifier = Modifier.flexWrap(FlexWrap.Wrap).margin(top = 15.px)) {
                            categories.forEach { (category, image) ->
                                val count = categoryData[category.lowercase()] ?: 0
                                val percentage =
                                    if (totalCount > 0) (count * 100) / totalCount else 0

                                Row(modifier = Modifier.margin(right = 10.px)) {
                                    Image(src = image, modifier = Modifier.size(20.px))
                                    SpanText(
                                        "$category ($percentage%)",
                                        modifier = Modifier
                                            .margin(right = 3.px)
                                            .fontFamily(Constants.FONT_FAMILY)
                                            .margin(bottom = 15.px)
                                            .fontSize(13.px)
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

