package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
@Serializable
data class GetSinglePlayerResponse(
    val response: GetSinglePlayerData,
)
@Serializable
data class GetSinglePlayerData(
    @SerialName("_id")
    val id: String = " ",
    @SerialName("_rev")
    val rev: String = " ",
    val coachName: String = " ",
    val email: String = " ",
    val firstName: String = " ",
    val latestAssessment: String = " ",
    val organization: String = " ",
    val nationality: String = "",
    val height: String = "",
    val ethnicity: String = "",
    val team: String = "",
    val age: String = "",
    val weight: String = "",
    val number: String = "",
    val position: String = "",
    val userVideos: List<String> = emptyList(),
    val secondaryPosition: String = "",
    val foot: String = "",
    val role: String = " ",
    val type: String = " ",
    val uid: String = " ",
)