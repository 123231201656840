package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetSingleCoachResponse(
    val data: GetSingleCoachData,
)
@Serializable
data class GetSingleCoachData(
    @SerialName("_id")
    val id: String = "",
    @SerialName("_rev")
    val rev: String  = "",
    val count: Int = 0,
    val dob: String = "",
    val email: String = "",
    val fcmToken: String = "",
    val firstName: String = "",
    val imageUrl: String = "",
    val isVerified: String = "",
    val job: String = "",
    val teams: List<String>? = emptyList(),
    val lastName: String = "",
    val level: String = "",
    val nationality: String = "",
    val organization: String = "",
    val recordTime: Int = 0,
    val role: String = "",
    val speakingTime: Double = 0.0 ,
    val totalKeywords: Int = 0 ,
    val type: String  = "",
    val uid: String  = "",
    val verificationCode: String  = "",
)