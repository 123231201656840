package org.example.zone360.pages.dashboard.teamSection

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import deleteClubTeam
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.showToast
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetAllTeamsData
import org.example.zone360.theme.SitePalette
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun DeleteTeam(
    onDialogDismiss: (Long) -> Unit,
    breakpoint: Breakpoint,
    modalNumber: Long,
    sitePalette: SitePalette,
    toastMessageCall: (String) -> Unit,
    teamData: GetAllTeamsData
){
    val scope = rememberCoroutineScope()
    var isLoading by remember { mutableStateOf(false) }
    var serverError by remember { mutableStateOf("") }
    var isError by remember { mutableStateOf(false) }
    Box(
        modifier = Modifier
            .width(if (breakpoint < Breakpoint.MD) 350.px else 450.px)
            .height(200.px)
            .id("delete-Club-Team$modalNumber")
            .position(Position.Fixed)
            .zIndex(9)
            .top(50.percent)
            .left(50.percent)
            .transform { translate((-50).percent, (-50).percent) },
        contentAlignment = Alignment.Center,
    ) {
        Box(
            modifier = Modifier
                .width(90.percent)
                .height(90.percent)
                .onClick { }
        )
        Box(
            modifier = Modifier
                .padding(all = 24.px)
                .width(90.percent)
                .margin(top = 10.px)
                .height(90.percent)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .border(
                    width = 1.px,
                    color = sitePalette.textFieldBorderColor.rgb,
                    style = LineStyle.Solid
                )
                .borderRadius(4.px)
                .overflow(Overflow.Auto),
            contentAlignment = Alignment.Center,
        ) {
            Column(modifier = Modifier.fillMaxSize()) {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 20.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Column {
                        FieldTitleTextNormalSimple(
                            "Delete Team",
                            fontSize = 20.px,
                            fontWeight = 600,
                            onClick = {}
                        )
                    }

                    FaXmark(
                        modifier = Modifier
                            .color(sitePalette.commonButtonSupportiveTextColor.rgb)
                            .cursor(Cursor.Pointer)
                            .onClick { onDialogDismiss(modalNumber) },
                        size = IconSize.LG
                    )

                }
                FieldTitleTextNormalSimple(
                    "Are you sure you want to delete this team?",
                    whiteSpace = WhiteSpace.PreWrap,
                    fontSize = 16.px,
                    marginBottom = 10.px
                )
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 20.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Box {
                        CommonButton(
                            text = "Confirm",
                            isLoading = isLoading,
                            onCLick = {
                                scope.launch {
                                    isError = false
                                    when {

                                        else -> {
                                            isLoading = true
                                            isError = false

                                            try {
                                                val response = deleteClubTeam(
                                                    team_id = teamData.id
                                                )
                                                println("response: $response")
                                                if (response.message != "") {
                                                    toastMessageCall("Team deleted successfully")
                                                    showToast("toastSuccess")
                                                    onDialogDismiss(modalNumber)
                                                } else {
                                                    isError = true
                                                    toastMessageCall("Team deletion Failed")
                                                    showToast("toastError")
                                                }
                                            } catch (e: Exception) {
                                                console.error("Error deleting team:", e)
                                                isError = true
                                                serverError =
                                                    "Failed to Delete team. Please try again."
                                            } finally {
                                                isLoading = false
                                            }
                                        }
                                    }
                                }
                            },
                            width = if (breakpoint < Breakpoint.MD) 115.px else 170.px,
                            height = 45.px
                        )
                    }
                    Box {
                        CommonButton(
                            text = "Cancel",
                            onCLick = {
                                onDialogDismiss(modalNumber)
                            },
                            width = if (breakpoint < Breakpoint.MD) 115.px else 170.px,
                            height = 45.px,
                            borderColor = sitePalette.primary.rgb,
                            backgroundColor = Colors.Transparent,
                            textColor = sitePalette.primary.rgb
                        )
                    }
                }

            }
        }
    }

}