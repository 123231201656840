package org.example.zone360.pages.dashboard.playerAssessment

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaPen
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CommonDropdown
import org.example.zone360.components.CustomSpacer
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetPlayerData
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal.Companion.teamDataList
import org.example.zone360.utils.Divider
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.css.px
import updateUserWithTeam

@Composable
fun PlayersData(
    data: List<GetPlayerData>,
    onRecommendationClick: (GetPlayerData) -> Unit = {},
    deletePlayer: () -> Unit
) {
    println("PlayersData recomposed with ${data.size} players")
    val scope = rememberCoroutineScope()
    val isTeamEditMap = remember { mutableStateMapOf<String, Boolean>() }
//    var selectedTeam by remember { mutableStateOf("Select") }
    val selectedTeamMap = remember { mutableStateMapOf<String, String>() }
    val ctx = rememberPageContext()
//    val sortedPlayer = remember { mutableStateListOf<GetPlayerData>() }

//    LaunchedEffect(data) {
//        sortedPlayer.clear()
//        sortedPlayer.addAll(data)
//        sortedPlayer.sortedWith(
//            compareByDescending { it.count })
//    }
//
    val sortedPlayer by remember(data) {
        mutableStateOf(data.sortedByDescending { it.count })
    }
println("sorted Player = ${sortedPlayer.size}")
    val sitePalette = ColorMode.current.toSitePalette()

    sortedPlayer.forEachIndexed { _, player ->

        val isTeamEdit = isTeamEditMap[player.userId] ?: false
        val selectedTeam = selectedTeamMap[player.userId] ?: player.team
        Row(
            modifier = Modifier.fillMaxWidth()
                .margin(topBottom = 10.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Row(
                modifier = Modifier.width(130.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        ctx.router.navigateTo("/player-details?imageUrl=${player.imageUrl}&userId=${player.userId}")
                    },
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (player.imageUrl.isNotEmpty()) {
                    Image(
                        src = "${Res.Images.coachImageUrl}${player.imageUrl}",
                        modifier = Modifier.size(30.px).margin(right = 5.px)
                            .borderRadius(100.px).textAlign(TextAlign.Start)
                    )
                } else {
                    Image(
                        src = Res.Images.userIcon,
                        modifier = Modifier.size(30.px).margin(right = 7.px)
                            .borderRadius(100.px)
                    )
                }
                FieldTitleTextNormalSimple(
                    text = "${player.firstName} ${player.lastName}".ifEmpty { "-" }
                        .replaceFirstChar {
                            if (it.isLowerCase()) it.titlecase() else it.toString()
                        },
                    isClickable = true
                )
            }
            FieldTitleTextNormalSimple(
                text = player.count.toString(),
                width = 50.px,
                textAlign = TextAlign.Center
            )
            FieldTitleTextNormalSimple(
                text = player.coachName.ifEmpty { "-" }.replaceFirstChar {
                    if (it.isLowerCase()) it.titlecase() else it.toString()
                },
                width = 120.px,
                textAlign = TextAlign.Center
            )
            Row(
                modifier = Modifier.width(120.px).gap(2.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                println("isTeamEdit: $isTeamEdit")
                if (isTeamEdit) {
                    Box(Modifier.fillMaxHeight()) {
                        CommonDropdown(
                            data = teamDataList.map { it.teamName },
                            onSelect = { newTeam ->
                                selectedTeamMap[player.userId] = newTeam
                                scope.launch {
                                    val response = updateUserWithTeam(
                                        teamName = newTeam,
                                        userID = player.userId,
                                        isRemove = false
                                    )
                                    if (response.data == "Team added successfully!") {
                                        println("team added successfully")
                                        val playerIndex = sortedPlayer.indexOf(player)
                                        sortedPlayer[playerIndex].team = newTeam
                                        isTeamEditMap[player.userId] =
                                            !(isTeamEditMap[player.userId] ?: false)

                                    }

                                }

                            },
                            selectedItem = selectedTeam,
                            width = 90.px,
                        )

                    }
                        CustomSpacer(10.px)
                } else {
                    FieldTitleTextNormalSimple(
                        text = player.team.ifEmpty { "-" }.replaceFirstChar {
                            if (it.isLowerCase()) it.titlecase() else it.toString()
                        },
                        width = 90.px,
                        textAlign = TextAlign.Center
                    )
                }
                FaPen(size = IconSize.XXS,
                    modifier = Modifier.onClick {
                        isTeamEditMap[player.userId] = !(isTeamEditMap[player.userId] ?: false)
                    }
                        .cursor(Cursor.Pointer))
            }
            Box(
                modifier = Modifier.width(310.px),
                contentAlignment = Alignment.Center
            ) {
                Box(
                    modifier = Modifier.maxWidth(305.px),
                    contentAlignment = Alignment.Center
                ) {
                    Box(
                        modifier = Modifier
//                            .padding(topBottom = 5.px)
                            .borderRadius(4.px)
                            .padding(10.px)
                            .backgroundColor(sitePalette.viewButtonBackground.rgb)
                            .onClick {
                            },
                        contentAlignment = Alignment.Center
                    ) {
                        FieldTitleTextNormalSimple(
                            text = player.email,
                            textAlign = TextAlign.Center,
                            color = sitePalette.viewText.rgb
                        )
                    }
                }
            }
            Box(
                modifier = Modifier.width(100.px),
                contentAlignment = Alignment.Center
            ) {
                CommonButton(
                    text = "View",
                    textSpanColor = if (player.count > 0) sitePalette.viewText.rgb else sitePalette.commonButtonSupportiveTextColor.rgb,
                    backgroundColor = if (player.count > 0) sitePalette.viewButtonBackground.rgb else sitePalette.textFieldBorderColorDisabled.rgb,
                    pointerCursor = player.count > 0,
                    width = 64.px,
                    height = 28.px,
                    onCLick = {
                        if (player.count > 0) {
                            onRecommendationClick(player)
                        }
                    }
                )
            }
//        Box (modifier = Modifier.width(50.px),
//            contentAlignment = Alignment.Center){
//            FaTrash(modifier = Modifier.onClick {
//                deletePlayer()
//            }.cursor(Cursor.Pointer))
//        }
        }
        Divider()
    }
}