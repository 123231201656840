@file:Suppress("DEPRECATION")

package org.example.zone360.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.CSSLengthNumericValue
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaAngleDown
import com.varabyte.kobweb.silk.components.icons.fa.FaAngleUp
import com.varabyte.kobweb.silk.components.icons.fa.FaDownload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.style.DropdownStyleDark
import org.example.zone360.style.DropdownStyleLight
import org.example.zone360.theme.Palette
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants.FONT_FAMILY
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Ul


@Composable
fun CommonDropdown(
    isDownloadButton: Boolean = false,
    data: List<String>,
    onSelect: (String) -> Unit,
    selectedItem: String,
    width: CSSLengthOrPercentageNumericValue = 100.px,
    height: CSSLengthNumericValue = 45.px,
    isPagination: Boolean = false,
    disabled: Boolean = false,
    id: String = "",
) {
    val sitePalette = ColorMode.current.toSitePalette()
    val modifier = if (isPagination) Modifier.width(70.px) else Modifier.fillMaxWidth()
    var isHovered by remember { mutableStateOf(false) }

    Box(
        modifier = modifier
            .margin(topBottom = 0.px)
            .classNames(if (disabled) "disabled" else "dropdown")
            .id("menuDropDown")
            .width(width)
            .height(height)
            .id(id)
            .borderRadius(5.px)
            .color(if (disabled) sitePalette.textFieldSupportiveTextColor.rgb else sitePalette.textFieldTextColor.rgb)
            .border(
                width = if (disabled) 0.px else 1.px,
                style = LineStyle.Solid,
                color = if (isDownloadButton) sitePalette.primary.rgb else sitePalette.textFieldBorderColor.rgb
            )
            .backgroundColor(if (disabled) sitePalette.textFieldBorderColorDisabled.rgb else sitePalette.modalBackgroundColor.rgb)
            .cursor(Cursor.Pointer)
            .attrsModifier {
                attr("data-bs-toggle", "dropdown")
                attr("id", "dropdown1")
            }
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize()
                .padding(leftRight = 20.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            SpanText(
                modifier = Modifier
                    .fillMaxWidth()
                    .fontSize(13.px)
                    .fontFamily(FONT_FAMILY)
                    .color(if (isDownloadButton) sitePalette.primary.rgb else if (disabled) sitePalette.textFieldSupportiveTextColor.rgb else sitePalette.textFieldTextColor.rgb),
                text = selectedItem
            )
            if (isDownloadButton) {
                FaDownload(Modifier.color(sitePalette.primary.rgb))
            } else {
                Box(modifier = Modifier.classNames("dropdown-toggle"))
                println("box opened by clicking")
            }
        }
        Ul(
            attrs = Modifier
                .fillMaxWidth()
                .classNames("dropdown-menu").backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .toAttrs()
        ) {
            val colorMode = ColorMode.current
            val modifier1 =
                if (colorMode == ColorMode.LIGHT) DropdownStyleLight else DropdownStyleDark

            data.forEach { item ->
                println(item)
                Li {
                    A(
                        attrs = modifier1.toModifier()
                            .classNames("dropdown-item")
                            .fontFamily(FONT_FAMILY)
                            .fontSize(16.px)
                            .onClick { onSelect(item) }
                            .toAttrs()
                    ) {
                        SpanText(
                            text = item,
                            modifier = Modifier
                                .fillMaxWidth()
                                .color(sitePalette.textFieldTextColor.rgb)
                                .fontSize(13.px)
                                .fontFamily(FONT_FAMILY)
                                .whiteSpace(WhiteSpace.PreWrap)
                        )
                    }
                }
            }
        }
    }
}
@Composable
fun CommonDropdownWB(
    data: List<String>,
    onSelect: (String) -> Unit,
    selectedItem: String,
    id: String = "",
    disabled: Boolean = false,
    width: CSSLengthOrPercentageNumericValue = 100.px,
    height: CSSLengthNumericValue = 45.px,
) {
    val sitePalette = ColorMode.current.toSitePalette()
    val modifier = Modifier.width(width)
    var isDropdownVisible by remember { mutableStateOf(false) }

    Box(
        modifier = modifier.backgroundColor(sitePalette.primary.rgb)
            .margin(topBottom = 0.px)
            .classNames(if (disabled) "disabled" else "dropdown")
            .width(width)
            .height(height)
            .id(id)
            .borderRadius(5.px)
            .position(Position.Relative)
            .border(
                width = if (disabled) 0.px else 1.px,
                style = LineStyle.Solid,
                color = sitePalette.textFieldBorderColor.rgb
            )
            .color(if (disabled) sitePalette.textFieldSupportiveTextColor.rgb else sitePalette.textFieldTextColor.rgb)
            .backgroundColor(if (disabled) sitePalette.textFieldBorderColorDisabled.rgb else sitePalette.modalBackgroundColor.rgb)
            .cursor(Cursor.Pointer)
    ) {
        Row(
            modifier = modifier
                .fillMaxHeight()
                .onClick { isDropdownVisible = !isDropdownVisible }
                .padding(leftRight = 20.px),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            SpanText(
                modifier = Modifier
                    .fontSize(13.px)
                    .fontFamily(FONT_FAMILY)
                    .color(sitePalette.textFieldTextColor.rgb),
                text = selectedItem
            )
            if (isDropdownVisible){
                FaAngleUp(
                    size = IconSize.SM,
                    modifier = Modifier
                        .color(sitePalette.textFieldTextColor.rgb)
                        .margin(left = 5.px)
                        .cursor(Cursor.Pointer)
                        .onClick { isDropdownVisible = false }
                )
            }else {
                FaAngleDown(
                    size = IconSize.SM,
                    modifier = Modifier
                        .color(sitePalette.textFieldTextColor.rgb)
                        .margin(left = 5.px)
                        .cursor(Cursor.Pointer)
                        .onClick { isDropdownVisible = true }
                )
            }
        }

        if (isDropdownVisible) {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .position(Position.Absolute)
                    .top(height)
                    .margin(top = 5.px)
                    .border(
                        width = 1.px,
                        style = LineStyle.Solid,
                        color = sitePalette.textFieldBorderColor.rgb
                    )
                    .zIndex(1000)
                    .classNames("dropdown-menu")
                    .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                    .maxHeight(200.px)
            ) {
                data.forEach { item ->
                    Box(
                        modifier = Modifier
                            .padding(topBottom = 3.px, leftRight = 5.px)
                            .cursor(Cursor.Pointer)
                            .onClick {
                                onSelect(item)
                                isDropdownVisible = false
                            }
                    ) {
                        SpanText(
                            text = item,
                            modifier = Modifier
                                .color(sitePalette.textFieldTextColor.rgb)
                                .fontSize(13.px)
                                .fontFamily(FONT_FAMILY)
                        )
                    }
                }
            }
        }

    }
    }

