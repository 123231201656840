package org.example.zone360.models

import kotlinx.serialization.Serializable

@Serializable
data class CommonDataResponse(
    val response: String = "",
    val error: String = "",
)
@Serializable
data class ChatBotMessage(
    val message: String = "",
    val isUserMessage: Boolean = false,
)