package org.example.zone360.pages.dashboard.coaches

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.alignItems
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onFocusIn
import com.varabyte.kobweb.compose.ui.modifiers.onKeyDown
import com.varabyte.kobweb.compose.ui.modifiers.onKeyUp
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCircle
import com.varabyte.kobweb.silk.components.icons.fa.FaCircleXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconStyle
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Res
import org.example.zone360.utils.noBorder
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.CSSLengthOrPercentageValue
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.Element
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.events.EventListener
import updateUserWithTeam

@Composable
fun TeamTokenSearch(
    teams: List<String>,
    width: CSSLengthOrPercentageValue  = 350.px,
    selectedTeams: MutableList<String>,
    onTeamSelected: (String) -> Unit,
    onTeamRemoved: (String) -> Unit,
    searchInputId: String = "team_search"
) {
    var showDropdown by remember { mutableStateOf(false) }
    var filteredTeams by remember { mutableStateOf(teams) }
    var isHover by remember { mutableStateOf(false) }
    val sitePalette = ColorMode.current.toSitePalette()

    DisposableEffect(showDropdown) {
        val listener = EventListener { event ->
            if (showDropdown) {
                val target = event.target as? Element
                if (target != null && target.closest(".services-dropdown, .dropdown-trigger") == null) {
                    showDropdown = false
                }
            }
        }
        window.addEventListener("click", listener)
        onDispose { window.removeEventListener("click", listener) }
    }

    Box(modifier = Modifier.fillMaxWidth(width).position(Position.Relative)) {
        Row(
            modifier = Modifier
                .fillMaxWidth(width)
                .minHeight(40.px)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .borderRadius(5.px)
                .border(1.px, style = LineStyle.Solid, color = sitePalette.textFieldBorderColor.rgb)
                .padding(8.px)
                .overflow(overflow = Overflow.Auto)
                .flexWrap(FlexWrap.Wrap)
                .gap(7.px)
                .alignItems(AlignItems.Center),
            verticalAlignment = Alignment.CenterVertically
        ) {
            selectedTeams.forEach { team ->
                Row(
                    modifier = Modifier
                        .gap(4.px)
                        .height(28.px)
                        .backgroundColor(sitePalette.textFieldBorderColor.rgb)
                        .borderRadius(4.px)
                        .padding(6.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FieldTitleTextNormalSimple(team, fontSize = 12.px)
                    Image(
                        src = Res.Images.cross_Icon,
                        modifier = Modifier.onClick { onTeamRemoved(team) }
                            .cursor(Cursor.Pointer)
                    )
                }
            }
            Input(
                type = InputType.Text,
                attrs = Modifier
                    .width(100.px)
                    .height(32.px)
                    .backgroundColor(Colors.Transparent)
                    .noBorder()
                    .classNames("dropdown-trigger")
                    .onFocusIn { 
                        showDropdown = true
                        filteredTeams = teams
                    }
                    .onKeyUp {
                        val searchText = (document.getElementById(searchInputId) as HTMLInputElement).value
                        filteredTeams = if (searchText.isNotEmpty()) {
                            teams.filter { it.contains(searchText, ignoreCase = true) }
                                .filterNot { selectedTeams.contains(it) }
                        } else {
                            emptyList()
                        }
                        showDropdown = searchText.isNotEmpty()
                    }
                    .toAttrs {
                        id(searchInputId)
                        attr("autocomplete", "off")
//                        attr("placeholder", if (selectedTeams.isEmpty()) "Search teams..." else "")
                    }
            )
        }

        if (showDropdown && filteredTeams.isNotEmpty()) {
            Box(
                modifier = Modifier
                    .width(width)
                    .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                    .borderRadius(8.px)
                    .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                    .position(Position.Absolute)
                    .top(50.px)
                    .zIndex(999)
                    .maxHeight(200.px)
                    .overflow(Overflow.Auto)
            ) {
                Column {
                    filteredTeams.forEach { team ->
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(8.px)
                                .cursor(Cursor.Pointer)
                                .onMouseEnter { isHover = true }
                                .onClick {
                                    onTeamSelected(team)
                                    (document.getElementById(searchInputId) as HTMLInputElement).value = ""
                                    showDropdown = false
                                }
                        ) {
                            FieldTitleTextNormalSimple(team,
                                fontSize = 14.px,
                                isClickable = true)
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun TeamTokenSearchForInfo(
    teams: List<String>,
    width: CSSLengthOrPercentageValue = 150.px,
    selectedTeams: MutableList<String>,
    onTeamSelected: (String) -> Unit,
    onTeamRemoved: (String) -> Unit,
    searchInputId: String = "team_search",
    onDismiss: () -> Unit,
    userID: String
) {
    var showDropdown by remember { mutableStateOf(false) }
    var filteredTeams by remember { mutableStateOf(teams) }
    val coroutineScope = rememberCoroutineScope()
    val sitePalette = ColorMode.current.toSitePalette()

    DisposableEffect(showDropdown) {
        val listener = EventListener { event ->
            if (showDropdown) {
                val target = event.target as? Element
                if (target != null && target.closest(".services-dropdown, .dropdown-trigger") == null) {
                    showDropdown = false
                }
            }
        }
        window.addEventListener("click", listener)
        onDispose {
            window.removeEventListener("click", listener)
        }
    }

    Box(modifier = Modifier.position(Position.Relative)) {
        Row(
            modifier = Modifier
                .minWidth(width)
                .maxHeight(100.px) // Reduced height
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .borderRadius(5.px)
                .border(1.px, style = LineStyle.Solid, color = sitePalette.textFieldBorderColor.rgb)
                .padding(8.px)
                .overflow(Overflow.Auto)
                .flexWrap(FlexWrap.Wrap)
                .gap(7.px)
                .alignItems(AlignItems.Center),
            verticalAlignment = Alignment.CenterVertically
        ) {
            selectedTeams.forEach { team ->
                Row(
                    modifier = Modifier
                        .gap(4.px)
                        .height(28.px)
                        .backgroundColor(sitePalette.textFieldBorderColor.rgb)
                        .borderRadius(4.px)
                        .padding(6.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FieldTitleTextNormalSimple(team, fontSize = 12.px)
                    FaCircleXmark(
                        style = IconStyle.FILLED,
                        modifier = Modifier
                            .cursor(Cursor.Pointer)
                            .color(sitePalette.primary.rgb)
                            .onClick {
                                coroutineScope.launch {
                                    val response = updateUserWithTeam(team, userID, isRemove = true)
                                    if (response.data == "Team removed successfully!") {
                                        selectedTeams.remove(team) // Fix: Remove directly
                                        println("Team removed successfully!")

                                        onTeamRemoved(team)
                                    } else {
                                        console.log("Failed to remove team: ${response.data}")
                                    }
                                }
                            }
                    )
                }
            }
            Input(
                type = InputType.Text,
                attrs = Modifier
                    .width(100.px)
                    .height(28.px) // Reduced height
                    .backgroundColor(Colors.Transparent)
                    .classNames("dropdown-trigger")
                    .noBorder()
                    .onKeyDown {
                        if (it.key == "Enter") {
                            onDismiss()
                        }
                    }
                    .onKeyDown {
                        if (it.key == "Enter") {
                            showDropdown = true
                            filteredTeams = teams
                        }
                    }
                    .onFocusIn {
                            showDropdown = true
                            filteredTeams = teams
                           it.stopPropagation() }
                    .onKeyUp {
                        val searchText = (document.getElementById(searchInputId) as HTMLInputElement).value
                        filteredTeams = if (searchText.isNotEmpty()) {
                            teams.filter { it.contains(searchText, ignoreCase = true) }
                                .filterNot { selectedTeams.contains(it) }
                        } else {
                            emptyList()
                        }
                        showDropdown = searchText.isNotEmpty()
                    }
                    .toAttrs {
                        id(searchInputId)
                        attr("autocomplete", "off")
                        attr("placeholder", if (selectedTeams.isEmpty()) "Search teams..." else "")
                    }
            )
        }

        if (showDropdown && filteredTeams.isNotEmpty()) {
            Box(
                modifier = Modifier
                    .width(width)
                    .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                    .borderRadius(8.px)
                    .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
                    .position(Position.Absolute)
                    .top(50.px)
                    .zIndex(999)
                    .maxHeight(350.px)
                    .overflow(Overflow.Auto)
            ) {
                Column {
                    filteredTeams.forEach { team ->
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(8.px)
                                .cursor(Cursor.Pointer)
                                .onClick {
                                    coroutineScope.launch {
                                        val response = updateUserWithTeam(
                                            teamName = team,
                                            userID = userID, isRemove = false)
                                        if (response.data == "Team added successfully!") {
                                            onTeamSelected(team)
                                            (document.getElementById(searchInputId) as HTMLInputElement).value = ""
                                            showDropdown = false
                                        } else {
                                            console.log("Failed to add team: ${response.data}")
                                        }
                                    }
                                }
                        ) {
                            FieldTitleTextNormalSimple(team, fontSize = 14.px, isClickable = true)
                        }
                    }
                }
            }
        }
    }
}


