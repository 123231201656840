package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetAllTeamsResponse(
    val data: List<GetAllTeamsData>,
)
@Serializable
data class GetAllTeamsData(
    @SerialName("_id")
    val id: String = "",
    @SerialName("_rev")
    val rev: String = "",
    val clubName: String = "",
    val teamName: String = "",
    val type: String = "",
)