package org.example.zone360.pages.dashboard.keywordUsage

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.models.GetClubKeywordData
import org.example.zone360.models.GetClubKeywordResponse
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Divider
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun KeywordUsage(keywordResponse: List<GetClubKeywordData>,
selectedLanguage: SelectedLanguage){
    val breakpoint = rememberBreakpoint()
    val sitePalette = ColorMode.current.toSitePalette()
    Column (modifier = Modifier
        .width(if (breakpoint > Breakpoint.LG) 68.percent else if(breakpoint == Breakpoint.LG) 59.percent
        else if(breakpoint >= Breakpoint.MD)54.percent else 100.percent)
        .height(300.px)
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .borderRadius(15.px)
        .padding(topBottom = 10.px, leftRight = 30.px)
    ){
        SectionTitle("Keywords Usage", left = 20.px, top = 10.px)
        Row(modifier = Modifier.padding(topBottom = 10.px, leftRight = 8.px)
            .flexWrap(FlexWrap.Wrap)
            .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)) {
                Keywords( keywordResponse, selectedLanguage)
            }

        }
    }
@Composable
fun Keywords(
    response: List<GetClubKeywordData>,
    selectedLanguage: SelectedLanguage
) {
    val sitePalette = ColorMode.current.toSitePalette()
    val sortedData = response.sortedByDescending { it.value?.count ?: 0 }
        sortedData  .forEach { keywordData ->
            val keywordText = keywordData.value?.text ?: "Unknown"
            val count = keywordData.value?.count ?: 0
            val keywordCategory = keywordData.value?.keywordCategory ?: "Unknown"
//            println("count: $count")
            Box {
                Box(
                    modifier = Modifier.height(32.px)
                        .backgroundColor(
                            when (keywordCategory) {
                              if (selectedLanguage == SelectedLanguage.English)  "fitness" else "atletico" -> sitePalette.fitnessCategoryBackground.rgb
                                if (selectedLanguage == SelectedLanguage.English)  "mental" else "mentale" -> sitePalette.mentalCategoryBackground.rgb
                                if (selectedLanguage == SelectedLanguage.English)  "technical" else "tecnica" -> sitePalette.technicalCategoryBackground.rgb
                                if (selectedLanguage == SelectedLanguage.English)  "tactical" else "tattica"-> sitePalette.tacticalCategoryBackground.rgb
                                if (selectedLanguage == SelectedLanguage.English) "goalkeeper" else "portiere" -> sitePalette.goalKeeperCategoryBackground.rgb
                                else -> sitePalette.textErrorColor.rgb
                            }
                        )
                        .borderRadius(7.px)
                        .padding(leftRight = 8.px, topBottom = 4.px)
                        .border(1.px, color =
                        when (keywordCategory) {
                            if (selectedLanguage == SelectedLanguage.English)  "fitness" else "atletico" -> sitePalette.fitnessCategoryBorder.rgb
                            if (selectedLanguage == SelectedLanguage.English)  "mental" else "mentale" -> sitePalette.mentalCategoryBorder.rgb
                            if (selectedLanguage == SelectedLanguage.English)  "technical" else "tecnica" -> sitePalette.technicalCategoryBorder.rgb
                            if (selectedLanguage == SelectedLanguage.English)  "tactical" else "tattica" -> sitePalette.tacticalCategoryBorder.rgb
                            if (selectedLanguage == SelectedLanguage.English) "goalkeeper" else "portiere" -> sitePalette.goalKeeperCategoryBorder.rgb
                            else -> sitePalette.textErrorColor.rgb
                        }
                            , style = LineStyle.Solid)
                        .margin(7.px),
                    contentAlignment = Alignment.Center
                ) {
                    SpanText(
                        keywordText,
                        modifier = Modifier.fontFamily(Constants.FONT_FAMILY).fontSize(13.px)
                    )
                }
                Box(
                    modifier = Modifier.size(18.px)
                        .margin(right = (-5).px)
                        .align(Alignment.TopEnd)
                        .backgroundColor(
                            when (keywordCategory) {
                                if (selectedLanguage == SelectedLanguage.English)  "fitness" else "atletico" -> sitePalette.fitnessCategoryBorder.rgb
                                if (selectedLanguage == SelectedLanguage.English)  "mental" else "mentale" -> sitePalette.mentalCategoryBorder.rgb
                                if (selectedLanguage == SelectedLanguage.English)  "technical" else "tecnica" -> sitePalette.technicalCategoryBorder.rgb
                                if (selectedLanguage == SelectedLanguage.English)  "tactical" else "tattica" -> sitePalette.tacticalCategoryBorder.rgb
                                if (selectedLanguage == SelectedLanguage.English) "goalkeeper" else "portiere" -> sitePalette.goalKeeperCategoryBorder.rgb
                                else -> sitePalette.textErrorColor.rgb
                            }
                        )
                        .borderRadius(100.px),
                    contentAlignment = Alignment.Center
                ) {
                    SpanText(
                        "$count",
                        modifier = Modifier.fontFamily(Constants.FONT_FAMILY)
                            .color(Colors.White)
                            .fontSize(10.px)
                    )
                }
            }
        }
    }
