package org.example.zone360.pages.players_Details.playerRecentAssessment

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.icons.fa.FaFileLines
import com.varabyte.kobweb.silk.components.icons.fa.FaUserPlus
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import getClubPlayerAssessmentAggregate
import getPlayerAssessment
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CustomDivider
import org.example.zone360.components.formatSessionDate
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubPlayerAssessmentData
import org.example.zone360.models.GetSessionsData
import org.example.zone360.models.GetSinglePlayerData
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.SitePalette
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun PlayersRecentAssessment(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    userId: String,
    message: (String) -> Unit,
    getPlayerInfo: GetSinglePlayerData
){

//    val playerAssessment = remember { mutableStateListOf<GetClubPlayerAssessmentData>() }
    val scope = rememberCoroutineScope()
    var inviteCoach by remember { mutableStateOf(false) }
    var requestAssessment by remember { mutableStateOf(false) }
    val getSessions = remember { mutableStateListOf<GetSessionsData>() }
    var sessionSingleData by remember { mutableStateOf(GetSessionsData()) }
    println("Session Data JSON: ${sessionSingleData.sessionData}")

    var playerRecommendation by remember { mutableStateOf(false) }
    var allRecommendations by remember { mutableStateOf(false) }
    if (allRecommendations){
        AllAssessmentRecommendation(
            onDialogDismiss = { allRecommendations = false },
            modalNumber = 4,
            breakpoint = breakpoint,
            data = sessionSingleData,
            userId = userId
        )
    }
    if (playerRecommendation){
        PlayersRecommendation(
            onDialogDismiss = { playerRecommendation = false },
            modalNumber = 3,
            breakpoint = breakpoint,
            data = sessionSingleData,
            userId = userId
        )
    }

    if (requestAssessment){
        RequestAssessment(
            onDialogDismiss = { requestAssessment = false },
            modalNumber = 2,
            breakpoint = breakpoint,
            toastMessageCall = message,
            getPlayerInfo =  getPlayerInfo
        )
    }
  if (inviteCoach){
      InviteCoach(
          onDialogDismiss = { inviteCoach = false },
          modalNumber = 1,
          breakpoint = breakpoint,
          toastMessageCall = message,
          getPlayerInfo =  getPlayerInfo

      )
  }

//    LaunchedEffect(Unit) {
//        val response = getClubPlayerAssessmentAggregate(
//        domainType = "soccer",
//            language = "en",
//            playerID = userId
//        )
//        println("response: $response")
//        playerAssessment.clear()
//        playerAssessment.addAll(response.data)
//
//    }

    LaunchedEffect(Unit) {
        val getSessionsResponse = getPlayerAssessment(
            playerID = userId,
            domainType = "soccer",
            language = "en"
        )

        if (getSessionsResponse.data.isNotEmpty()) {
            getSessions.clear()
            getSessions.addAll(getSessionsResponse.data)
            sessionSingleData = getSessionsResponse.data.first() // Set the first session
        } else {
            println("No session data received from API.")
        }
    }

    Box(
        modifier = Modifier
            .height(400.px)
            .fillMaxWidth()
            .backgroundColor(sitePalette.modalBackgroundColor.rgb)
            .borderRadius(15.px)
            .overflow(
                overflowY = Overflow.Hidden,
                overflowX =   Overflow.Auto
            )
            .boxShadow(
                offsetY = 1.px,
                blurRadius = 3.px,
                color = sitePalette.boxShadowColor.rgb
            )
            .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
    ) {
        Column(
            modifier = Modifier
//                .fillMaxWidth()
                .width(if (breakpoint < Breakpoint.LG) 900.px else 100.percent)
                .fillMaxHeight()
        ) {
            Box (modifier = Modifier.width(98.8.percent)) {
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(16.px)
                ) {
                    Row(
                        modifier = Modifier.margin(topBottom = 10.px).fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        SectionTitle("Recent Assessments")

                        Row(modifier = Modifier.gap(12.px)) {
                            CommonButton(
                                text = "Invite Coach",
                                textSpanColor = sitePalette.coachInfoTextColor.rgb,
                                backgroundColor = sitePalette.lightGrey.rgb,
                                textSpanFontWeight = 500,
                                icon = {
                                    FaUserPlus(
                                        modifier = Modifier.color(sitePalette.coachInfoTextColor.rgb),
                                        size = IconSize.LG
                                    )
                                },
                                onCLick = {
                                    inviteCoach = true
                                },
                                width = 138.px,
                                height = 36.px
                            )
                            Box(modifier = Modifier.position(Position.Relative)) {
                                CommonButton(
                                    text = "Request Assessment",
                                    textSpanColor = sitePalette.coachInfoTextColor.rgb,
                                    backgroundColor = sitePalette.lightGrey.rgb,
                                    textSpanFontWeight = 500,
                                    icon = {
                                        FaFileLines(
                                            modifier = Modifier.color(sitePalette.coachInfoTextColor.rgb),
                                            size = IconSize.LG
                                        )
                                    },
                                    onCLick = { requestAssessment = true },
                                    width = 195.px,
                                    height = 36.px
                                )
                            }
                            if (getSessions.isNotEmpty()) {
                                Box(modifier = Modifier.position(Position.Relative)) {
                                    CommonButton(
                                        text = "All Recommendations",
                                        textSpanColor = sitePalette.coachInfoTextColor.rgb,
                                        backgroundColor = sitePalette.lightGrey.rgb,
                                        textSpanFontWeight = 500,
                                        onCLick = {
                                            allRecommendations = true
                                        },
                                        width = 195.px,
                                        height = 36.px
                                    )
                                }
                            }
                        }

                    }
                    Row(
                        modifier = Modifier.fillMaxWidth()
                            .backgroundColor(sitePalette.lightGrey.rgb)
                            .height(50.px),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Row(
                            modifier = Modifier.fillMaxWidth(98.percent)
                                .padding(leftRight = 8.px),
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.SpaceBetween
                        ) {
                            FieldTitleTextNormalSimple(
                                text = "Date",
                                width = 140.px,
                                fontWeight = 500,
                                fontSize = 15.px
                            )
                            FieldTitleTextNormalSimple(
                                text = "Coach Name",
                                width = 100.px,
                                fontWeight = 500,
                                fontSize = 15.px
                            )
//                        FieldTitleTextNormalSimple(
//                            text = "No. of Assessments",
//                            width = 145.px,
//                            fontWeight = 500,
//                            fontSize = 15.px
//                        )
                            FieldTitleTextNormalSimple(
                                text = "Key Insight",
                                width = 200.px,
                                fontWeight = 500,
                                fontSize = 15.px,
                                textAlign = TextAlign.Center
                            )
                            FieldTitleTextNormalSimple(
                                text = "Recommendations",
                                width = 120.px,
                                fontWeight = 500,
                                fontSize = 15.px
                            )
                        }
                    }
                    Column(
                        modifier = Modifier.fillMaxWidth()
                            .maxHeight(230.px)
                            .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                    ) {
                            getSessions.forEach { data ->

                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .padding(leftRight = 8.px),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                FieldTitleTextNormalSimple(
                                    text = data.sessionDateTime.formatSessionDate(),
                                    width = 140.px,
                                    fontWeight = 400,
                                    fontSize = 14.px
                                )
                                Row(
                                    modifier = Modifier.gap(8.px),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    FieldTitleTextNormalSimple(
                                        text = data.coachName,
                                        width = 100.px,
                                        fontWeight = 400,
                                        fontSize = 14.px
                                    )
                                }
//                            FieldTitleTextNormalSimple(
//                                text = data.count.toString(),
//                                width = 145.px,
//                                fontWeight = 400,
//                                fontSize = 14.px,
//                                textAlign = TextAlign.Center
//                            )
                                FieldTitleTextNormalSimple(
                                    text = data.keyInsights.replace("Three words:", "").ifEmpty { "-" },
                                    width = 200.px,
                                    fontWeight = 400,
                                    fontSize = 14.px,
                                )
                                Box(
                                    modifier = Modifier.width(120.px),
                                    contentAlignment = Alignment.Center
                                ) {
                                    CommonButton(
                                        text = "View",
                                        textSpanColor = if (data.wordCount > 0) sitePalette.viewText.rgb else sitePalette.commonButtonSupportiveTextColor.rgb,
                                        backgroundColor = if (data.wordCount > 0) sitePalette.viewButtonBackground.rgb else sitePalette.textFieldBorderColorDisabled.rgb,
                                        width = 64.px,
                                        height = 28.px,
                                        pointerCursor = data.wordCount > 0,
                                        onCLick = {
                                            playerRecommendation = true
                                        },
                                    )
                                }

                            }
                            CustomDivider()
                        }
                }
            }
            }
        }
    }
}
