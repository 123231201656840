package org.example.zone360.components

import kotlinx.datetime.TimeZone
import kotlinx.datetime.internal.JSJoda.DateTimeFormatter
import kotlinx.datetime.internal.JSJoda.Instant
import kotlinx.datetime.internal.JSJoda.ZoneId

fun String.formatSessionHours(): String {
    return try {
        val timestamp = this.toLongOrNull() ?: return "N/A"
        val dateTime = Instant.ofEpochMilli(timestamp.toDouble()).atZone(ZoneId.systemDefault())
        val formatter = DateTimeFormatter.ofPattern("HH:mm")
        dateTime.format(formatter)
    } catch (e: Exception) {
        "N/A"
    }
}

fun String.formatSessionDate(): String {
    return try {
        val timestamp = this.toLongOrNull() ?: return "N/A"
        val dateTime = Instant.ofEpochMilli(timestamp.toDouble()).atZone(ZoneId.systemDefault())
        val formatter = DateTimeFormatter.ofPattern("dd/MM/yyyy")
        dateTime.format(formatter)
    } catch (e: Exception) {
        "N/A"
    }
}
//Int format of minutes
fun Number.formatSessionMinutes(): String {
    return try {
        val totalSeconds = this.toDouble().toInt()
        val minutes = totalSeconds / 60
        val seconds = totalSeconds % 60
        "$minutes:${seconds.toString().padStart(2, '0')}"
    } catch (e: Exception) {
        "N/A"
    }
}
//String format of minutes

fun Number.formatToPercentage(total: Number): String {
    return try {
        val value = this.toDouble()
        val totalValue = total.toDouble()

        // Add more detailed logging
        println("Value: $value")
        println("Total Value: $totalValue")

        if (totalValue > 0) {
            val percentage = ((value / totalValue) * 100.0).toInt()
            "$percentage%"
        } else {
            "0%"
        }
    } catch (e: Exception) {
        "N/A"
    }
}
