package org.example.zone360.pages.coachDetails.sessionAllDetails

import JsonConfig
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronDown
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronUp
import com.varabyte.kobweb.silk.components.icons.fa.FaHourglassEnd
import com.varabyte.kobweb.silk.components.icons.fa.FaPause
import com.varabyte.kobweb.silk.components.icons.fa.FaPlay
import com.varabyte.kobweb.silk.components.icons.fa.FaTriangleExclamation
import com.varabyte.kobweb.silk.components.icons.fa.FaUpload
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import getClubPlayer
import getSentences
import getSessions
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.await
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.example.zone360.components.CommonButton
import org.example.zone360.components.CustomDivider
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.components.VideoProgressSliderCustom
import org.example.zone360.components.formatSessionDate
import org.example.zone360.components.formatSessionHours
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.components.showToast
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetClubPlayerData
import org.example.zone360.models.GetSentencesData
import org.example.zone360.models.GetSessionsData
import org.example.zone360.models.Sentimentprob
import org.example.zone360.models.SessionDataDetailsResponse
import org.example.zone360.pages.coachDetails.charts.CircularProgressIndicator
import org.example.zone360.pages.coachDetails.charts.RadarChartPage
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.pages.players.encodeFileName
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Video
import org.w3c.dom.Audio
import org.w3c.dom.HTMLAudioElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLVideoElement
import org.w3c.fetch.RequestInit
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get
import org.w3c.xhr.XMLHttpRequest
import kotlin.collections.component1
import kotlin.collections.component2
import kotlin.collections.set
import kotlin.coroutines.cancellation.CancellationException
import kotlin.js.json
import kotlin.math.ceil

// Global object to store upload states
object UploadStateManager {
    private val uploadStates = mutableMapOf<String, Pair<Double, String>>()
    private val sessionUpdates = mutableMapOf<String, (String) -> Unit>() // Store update callbacks per session

    fun getUploadProgress(sessionId: String): Double = uploadStates[sessionId]?.first ?: -1.0
    fun getUploadStatus(sessionId: String): String = uploadStates[sessionId]?.second ?: ""
    fun setUploadState(sessionId: String, progress: Double, status: String) {
        uploadStates[sessionId] = progress to status
    }

    fun setSessionUpdateCallback(sessionId: String, callback: (String) -> Unit) {
        sessionUpdates[sessionId] = callback
    }

}
@Composable
fun SessionAllDetails(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    sessionData1: GetSessionsData,
    videoUrlFile: (GetSessionsData) -> Unit,
    message: (String) -> Unit,
) {
    val scope = rememberCoroutineScope()
    var sessionData by remember { mutableStateOf(GetSessionsData()) }

//
    var videoProgress1 by remember { mutableStateOf(0f) }
    var isSeeking1 by remember { mutableStateOf(false) }
    var fullSessionVideo by remember { mutableStateOf(false) }
    var currentlyPlayingVideoId1 by remember { mutableStateOf<String?>(null) }
    val currentVideoRef1 = remember { mutableStateOf<HTMLVideoElement?>(null) }
    var isVideoDurationPlaying1 by remember { mutableStateOf(false) }
    var isHovered1 by remember { mutableStateOf(false) }
    val currentAudioRefHighlight1 = remember { mutableStateOf<Audio?>(null) }
    val sessionId1 = sessionData.sessionId
    var audioProgress1 by remember { mutableStateOf(0f) }
    var isBuffering1 by remember { mutableStateOf(false) }

//

    var globalCurrentlyPlayingId by remember { mutableStateOf<String?>(null) }
    var globalCurrentVideoRef by remember { mutableStateOf<HTMLVideoElement?>(null) }
    var globalCurrentAudioRef by remember { mutableStateOf<Audio?>(null) }
    var globalTimeoutId by remember { mutableStateOf<Int?>(null) }
    var isBuffering by remember { mutableStateOf(false) }

//

    println("fullSessionNoEffect $fullSessionVideo")

    LaunchedEffect(currentlyPlayingVideoId1, fullSessionVideo) {
        println("currentlyPlayingVideoId1: $currentlyPlayingVideoId1")
        println("fullSessionVideo: $fullSessionVideo")

        if (fullSessionVideo.not()) {
            val videoElement = currentVideoRef1.value
            val audioElement = currentAudioRefHighlight1.value

            videoElement?.pause()
            audioElement?.pause()

            isVideoDurationPlaying1 = false
            currentlyPlayingVideoId1 = null
            currentVideoRef1.value = null
            currentAudioRefHighlight1.value = null
            videoProgress1 = 0f
            audioProgress1 = 0f
            return@LaunchedEffect  // Exit early
        }

        if (currentlyPlayingVideoId1 == sessionId1 && fullSessionVideo) {
            val videoElement = currentVideoRef1.value
            println("videoElement: $videoElement")

            videoElement?.apply {
                play()

                while (!ended && fullSessionVideo) {  // Stop if fullSessionVideo becomes false
                    videoProgress1 = (currentTime / duration).toFloat()
                    delay(100)

                    if (ended || fullSessionVideo.not()) {
                        pause()
                        currentlyPlayingVideoId1 = null
                        currentVideoRef1.value = null
                        videoProgress1 = 0f
                        break
                    }
                }
            }
        }
    }

    fun playVideoWithAudio(
        videoElement: HTMLVideoElement,
        audioElement: Audio
    ) {
        val timeoutId: Int? = null

        println("Starting playVideoWithAudio function")

        if (fullSessionVideo.not()) return  // Stop function if fullSessionVideo is false

        // Reset state
        isVideoDurationPlaying1 = true
        isSeeking1 = true
        isBuffering1 = true
        println("State reset - isVideoDurationPlaying: $isVideoDurationPlaying1, isSeeking: $isSeeking1")

        val cleanup = {
            println("Cleanup function called")
            videoElement.pause()
            audioElement.pause()

            isVideoDurationPlaying1 = false
            videoProgress1 = 0f
            audioProgress1 = 0f
            currentlyPlayingVideoId1 = null
            currentVideoRef1.value = null
            currentAudioRefHighlight1.value = null

            videoElement.onwaiting = null
            videoElement.onplaying = null
            videoElement.oncanplay = null
            videoElement.ontimeupdate = null
            videoElement.onprogress = null

            timeoutId?.let {
                window.clearTimeout(it)
            }
        }

        videoElement.currentTime = 0.0
        audioElement.currentTime = 0.0

        videoElement.onwaiting = {
            isBuffering1 = true
            println("Video is buffering... Current time: ${videoElement.currentTime}")
            audioElement.pause()
        }

        videoElement.onplaying = {
            isBuffering1 = false
            println("Video started playing at: ${videoElement.currentTime}")
            if (audioElement.paused) audioElement.play()
        }

        videoElement.onprogress = {
            if (videoElement.buffered.length > 0) {
                val bufferedEnd = videoElement.buffered.end(0)
                val bufferedSeconds = bufferedEnd - videoElement.currentTime
                println("Buffered up to: $bufferedEnd seconds, Available buffer: $bufferedSeconds seconds")

                if (bufferedSeconds > 10 && videoElement.paused) {
                    println("Buffer sufficient, resuming playback.")
                    isBuffering1 = false
                    videoElement.play()
                    audioElement.play()
                }
            }
        }

        videoElement.oncanplay = {
            println("Video can play now at time: ${videoElement.currentTime}")
            isBuffering1 = false
            videoElement.play().then {
                println("Video started playing successfully.")
                audioElement.play()
                println("Audio started playing successfully.")
            }.catch { error ->
                println("Error playing media: $error")
                cleanup()
            }
        }

        videoElement.ontimeupdate = {
            if (!fullSessionVideo) {
                cleanup()  // Stop everything if fullSessionVideo is false
            }

            val timeDiff = kotlin.math.abs(videoElement.currentTime - audioElement.currentTime)
            if (timeDiff > 0.1) {
                println("Syncing full audio to video time")
                audioElement.currentTime = videoElement.currentTime
            }
        }
    }


//
    var uploadingSessionData by remember { mutableStateOf<GetSessionsData?>(null) }

    var uploadProgress by remember { mutableStateOf(UploadStateManager.getUploadProgress(sessionData1.sessionId)) }
    var uploadStatus by remember { mutableStateOf(UploadStateManager.getUploadStatus(sessionData1.sessionId)) }

    // Set up callback for this session
    LaunchedEffect(sessionData1) {
        println("assigned")
        sessionData = sessionData1
        uploadProgress = UploadStateManager.getUploadProgress(sessionData1.sessionId)
        uploadStatus = UploadStateManager.getUploadStatus(sessionData1.sessionId)

        // Register callback for this session
        UploadStateManager.setSessionUpdateCallback(sessionData1.sessionId) { fileName ->
            sessionData = sessionData.copy(videoFile = fileName)
            videoUrlFile(sessionData)
        }
    }

    val parts = sessionData.sessionId.removeSuffix(".aac").split("_")
    val sessionName = parts.first()
    if (sessionData.sessionData.isNotEmpty()) {



//

        val getSentences = remember { mutableStateListOf<GetSentencesData>() }
        var selectedPlayers by remember {
            mutableStateOf<Map<String, List<GetClubPlayerData>>>(
                emptyMap()
            )
        }
        val selectedPlayersFullSession = remember { mutableStateListOf<GetClubPlayerData>() }
//    val getSessions = remember { mutableStateListOf<GetSessionsData>() }

        val obj =
            JsonConfig.default.decodeFromString<SessionDataDetailsResponse>(sessionData.sessionData)
        val keywordExtraction = obj.response.keywordExtraction
        val sentiments = obj.response.sentimentAnalysis
        val toneAnalysis = obj.response.toneAnalysis
        val summaryData = obj.response.summary

        // Aggregate sentiment probabilities from sentimentMentions
        val sentimentMentionCounts = sentiments.documentSentiment.sentimentMentions.size
        val aggregatedSentiments = sentiments.documentSentiment.sentimentMentions.fold(
            Sentimentprob(
                0.0,
                0.0,
                0.0
            )
        ) { acc, mention ->
            Sentimentprob(
                positive = acc.positive + mention.sentimentprob.positive,
                negative = acc.negative + mention.sentimentprob.negative,
                neutral = acc.neutral + mention.sentimentprob.neutral
            )
        }

        // Average the aggregated sentiments
        val averageSentiments = Sentimentprob(
            positive = aggregatedSentiments.positive / sentimentMentionCounts,
            negative = aggregatedSentiments.negative / sentimentMentionCounts,
            neutral = aggregatedSentiments.neutral / sentimentMentionCounts
        )

        // Convert to percentages
        val positivePercentage = (averageSentiments.positive * 100).toInt()
        val negativePercentage = (averageSentiments.negative * 100).toInt()
        val neutralPercentage = (averageSentiments.neutral * 100).toInt()

        var uploadedVideoUrl by remember { mutableStateOf<String?>(null) }
        var expandedIndex by remember { mutableStateOf(-1) }

        var players by remember { mutableStateOf<List<GetClubPlayerData>>(emptyList()) }
        var isLoading by remember { mutableStateOf(true) }
        var isEmotionLoading by remember { mutableStateOf(true) }
        var isSentenceLoading by remember { mutableStateOf(true) }
        var error by remember { mutableStateOf<String?>(null) }
//
        var isPlayingAllHighlight by remember { mutableStateOf(false) }
        var currentlyPlayingIndexHighlight by remember { mutableStateOf<Int?>(null) }
        var currentlyPlayingIdHighlight by remember { mutableStateOf<String?>(null) }
        var loadingSessionIdHighlight by remember { mutableStateOf<String?>(null) }
        val currentAudioRefHighlight = remember { mutableStateOf<Audio?>(null) }


        // Add individual playing state for each audio
        var isThisPlaying by remember { mutableStateOf(false) }

//        Video
        val fileName = sessionData.videoFile
        val encodedFileName = encodeFileName(fileName)
        val sessionVideo = "${Res.Audio.base_Audio_URL}$encodedFileName"


//    audio
        var currentlyPlayingIdFullSession by remember { mutableStateOf<String?>(null) }
        var loadingSessionIdFullSession by remember { mutableStateOf<String?>(null) }
        val currentAudioRefFullSession = remember { mutableStateOf<Audio?>(null) }
        val fullPlaySessionId = sessionData.sessionId
        val fullAudio =  Audio("${Res.Audio.base_Audio_URL}${sessionData.sessionId}")
        val getSentenceAudio = Audio("${Res.Audio.base_Audio_URL}${sessionData.sessionId}")
        var fullPlayAudioProgress by remember { mutableStateOf(0f) }

        println("sessionId11: ${sessionData.sessionId}")


        val playAllAudio = Audio("${Res.Audio.base_Audio_URL}${sessionData.sessionId}")

//
        var isPlayingAll by remember { mutableStateOf(false) }
        var playJob: Job? = null
//
        val uploadFile: (File) -> Unit = { file ->
            val uploadingSessionId = sessionData.sessionId // Capture session ID at upload start
            uploadingSessionData = sessionData // Store the sessionData of the session where upload started

            // Initialize upload state for this session
            UploadStateManager.setUploadState(uploadingSessionId, 0.0, "Initializing upload...")
            uploadProgress = 0.0
            uploadStatus = "Initializing upload..."

            scope.launch {
                try {
                    val initiateResponse = window.fetch(
                        "https://vitru2.17nmki56rdxv.us-south.codeengine.appdomain.cloud/initiate-upload",
                        RequestInit(
                            method = "POST",
                            headers = json("Content-Type" to "application/json"),
                            body = JSON.stringify(json("fileName" to file.name))
                        )
                    ).await().json().await()
                    val uploadId = initiateResponse.asDynamic().uploadId as String

                    val chunkSize = 5 * 1024 * 1024 // 5MB per chunk
                    val totalChunks = ceil(file.size.toDouble() / chunkSize).toInt()
                    val chunkParts = mutableListOf<dynamic>()

                    UploadStateManager.setUploadState(uploadingSessionId, 0.0, "Uploading file in $totalChunks chunks...")
                    if (sessionData.sessionId == uploadingSessionId) {
                        uploadStatus = "Uploading file in $totalChunks chunks..."
                    }

                    for (chunkIndex in 0 until totalChunks) {
                        val start = chunkIndex * chunkSize
                        val end = minOf(start + chunkSize, file.size.toInt())
                        val chunk = file.slice(start, end)

                        val partResponse = window.fetch(
                            "https://vitru2.17nmki56rdxv.us-south.codeengine.appdomain.cloud/get-part-url?fileName=${file.name}&uploadId=$uploadId&partNumber=${chunkIndex + 1}"
                        ).await().json().await()
                        val partUrl = partResponse.asDynamic().url as String

                        val xhr = XMLHttpRequest()
                        xhr.open("PUT", partUrl, true)
                        xhr.setRequestHeader("Content-Type", "application/octet-stream")

                        xhr.upload.onprogress = { event ->
                            if (event.lengthComputable) {
                                val progress = ((chunkIndex.toDouble() / totalChunks) * 100) +
                                        ((event.loaded.toDouble() / event.total.toDouble()) * (100 / totalChunks))
                                UploadStateManager.setUploadState(uploadingSessionId, progress, uploadStatus)
                                if (sessionData.sessionId == uploadingSessionId) {
                                    uploadProgress = progress
                                }
                            }
                        }

                        val partCompleted = CompletableDeferred<String>()
                        xhr.onload = {
                            if (xhr.status.toInt() == 200) {
                                val eTag = xhr.getResponseHeader("ETag") ?: ""
                                chunkParts.add(json("ETag" to eTag, "PartNumber" to (chunkIndex + 1)))
                                partCompleted.complete("Success")
                            } else {
                                val errorStatus = "Error uploading chunk ${chunkIndex + 1}"
                                UploadStateManager.setUploadState(uploadingSessionId, -1.0, errorStatus)
                                if (sessionData.sessionId == uploadingSessionId) {
                                    uploadStatus = errorStatus
                                    uploadProgress = -1.0
                                }
                                partCompleted.completeExceptionally(Exception("Upload failed!"))
                            }
                        }

                        xhr.onerror = { partCompleted.completeExceptionally(Exception("Upload error!")) }
                        xhr.send(chunk)
                        partCompleted.await()
                    }

                    val completeResponse = window.fetch(
                        "https://vitru2.17nmki56rdxv.us-south.codeengine.appdomain.cloud/complete-upload",
                        RequestInit(
                            method = "POST",
                            headers = json("Content-Type" to "application/json"),
                            body = JSON.stringify(
                                json(
                                    "fileName" to file.name,
                                    "uploadId" to uploadId,
                                    "parts" to chunkParts,
                                    "sessionID" to uploadingSessionId // Ensure correct session is updated
                                )
                            )
                        )
                    ).await().json().await()

                    if (completeResponse.asDynamic().message != null) {
                        UploadStateManager.setUploadState(uploadingSessionId, 101.0, "Upload complete!")
                        if (sessionData.sessionId == uploadingSessionId) {
                            uploadStatus = "Upload complete!"
                            uploadProgress = 101.0
                        }
                        message("Video uploaded successfully!")
                        showToast("toastSuccess")

                        val videoUrl = completeResponse.asDynamic().videoUrl as String?
                        println("videoUrl: $videoUrl")

                        // Ensure the correct session gets updated
                        uploadingSessionData?.let { originalSession ->
                            if (originalSession.sessionId == uploadingSessionId) {
                                val updatedSession = originalSession.copy(videoFile = file.name)
                                videoUrlFile(updatedSession)

                                if (sessionData.sessionId == originalSession.sessionId) {
                                    sessionData = updatedSession
                                }
                            }
                        }

                        // Clear the uploading session reference after completion
                        uploadingSessionData = null
                    } else {
                        UploadStateManager.setUploadState(uploadingSessionId, -1.0, "Upload failed!")
                        if (sessionData.sessionId == uploadingSessionId) {
                            uploadStatus = "Upload failed!"
                            uploadProgress = -1.0
                        }
                        message("Video uploading error!")
                        showToast("toastError")
                    }
                } catch (e: Exception) {
                    UploadStateManager.setUploadState(uploadingSessionId, -1.0, "Error: ${e.message}")
                    if (sessionData.sessionId == uploadingSessionId) {
                        uploadStatus = "Error: ${e.message}"
                        uploadProgress = -1.0
                    }
                    message("Video uploading error!")
                    showToast("toastError")
                }
            }
        }




        DisposableEffect(Unit) {
            onDispose {
                if (currentlyPlayingIdFullSession == sessionData.sessionId) {
                    fullAudio.pause()
                    currentlyPlayingIdFullSession = null
                    fullPlayAudioProgress = 0f
                }
            }
        }

        LaunchedEffect(currentlyPlayingIdFullSession) {
            if (currentlyPlayingIdFullSession != null) {
                val audioPlay = currentAudioRefFullSession.value
                audioPlay?.apply {
                    while (currentTime < duration) {
                        fullPlayAudioProgress = (currentTime / duration).toFloat()
                        delay(100)
                    }
                    // Reset when audio finishes
                    if (currentTime >= duration) {
                        currentlyPlayingIdFullSession = null
                        fullPlayAudioProgress = 0f
                    }
                }
            }
        }

        LaunchedEffect(sessionData.sessionId) {
            if (currentlyPlayingIdFullSession != sessionData.sessionId) {
                println("Stopping previous audio because sessionId changed.")

                // Pause and reset any playing audio
                currentAudioRefFullSession.value?.pause()
                fullAudio.pause()
                getSentenceAudio.pause()
                playAllAudio.pause()

                // Reset all states related to playing audio
                currentlyPlayingIdFullSession = null
                currentlyPlayingIdHighlight = null
                isPlayingAll = false
                fullPlayAudioProgress = 0f
            }
        }

        // Ensure this is only triggered once during composition
        LaunchedEffect(Unit) {
            try {
                isLoading = true
                error = null
                val response = getClubPlayer(AppGlobal.userData.organization)
                players = response.data

                //
                val taggedPlayers = sessionData.tagPlayers

                if (taggedPlayers?.isNotEmpty() == true) {
                    taggedPlayers.forEach { player ->
                        val filteredPlayers = players.filter { it.uid == player }
                        if (filteredPlayers.isNotEmpty()) {
//                    selectedPlayersFullSession.clear()
                            selectedPlayersFullSession.add(filteredPlayers.first())
                        }
                    }
                }

//            println("selectedPlayersSessionS: $selectedPlayersFullSession")
            } catch (e: Exception) {
                error = "Failed to load players: ${e.message}"
                println("Error loading players: ${e.message}")
            } finally {
                isLoading = false
            }
        }


// Only re-fetch sentences when `sessionData.sessionId` changes
        LaunchedEffect(sessionData.sessionId) {
            try {
                isEmotionLoading = true
                isSentenceLoading = true
                val getSentencesResponse = getSentences(
                    sessionID = sessionData.sessionId,
                    type = "sentence"
                )
                getSentences.clear()
                getSentences.addAll(getSentencesResponse.data)
//                getSentences.addAll(listOf(getSentencesResponse.data.first()))




            } catch (e: Exception) {
                println("Error loading sentences: ${e.message}")
            } finally {
                isSentenceLoading = false
                isEmotionLoading = false
            }
        }


        Box(
            modifier = Modifier
                .width(
                    if (breakpoint > Breakpoint.LG) 65.percent else if (breakpoint == Breakpoint.LG) 59.percent
                    else if (breakpoint >= Breakpoint.MD) 49.percent else 1360.px
                )
                .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .borderRadius(15.px)
                .overflow(
                    overflowY = Overflow.Hidden,
                    overflowX = Overflow.Auto
                )
                .boxShadow(
                    offsetY = 1.px,
                    blurRadius = 3.px,
                    color = sitePalette.boxShadowColor.rgb
                )
                .padding(
                    leftRight = if (breakpoint >= Breakpoint.LG) 30.px else 20.px,
                    topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
                )
        ) {
            Column(modifier = Modifier.fillMaxWidth().fillMaxSize()) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    SectionTitle(sessionName.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() })
                    println("video file name : ${sessionData.videoFile}")

                    if (sessionData.videoFile.isEmpty() && uploadProgress < 0 && sessionData.sessionData.isNotEmpty()) {
                        Div {
                            Row(
                                modifier = Modifier.gap(10.px),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                CommonButton(
                                    text = "Upload Video",
                                    icon = {
                                        FaUpload(
                                            size = IconSize.LG,
                                            modifier = Modifier.color(sitePalette.primary.rgb)
                                        )
                                    },
                                    width = 150.px,
                                    height = 40.px,
                                    borderColor = sitePalette.primary.rgb,
                                    backgroundColor = Colors.Transparent,
                                    textSpanColor = sitePalette.primary.rgb,
                                    onCLick = {
                                        val fileInput = document.createElement("input") as HTMLInputElement
                                        fileInput.type = "file"
                                        fileInput.onchange = { event ->
                                            val selectedFile = (event.target as HTMLInputElement).files?.get(0)
                                            if (selectedFile != null) {
                                                uploadFile(selectedFile)
                                            }
                                        }
                                        fileInput.click()
                                    }
                                )
                            }
                        }
                    } else {
                        if (uploadProgress in 0.0..100.0) {
                            Row (verticalAlignment = Alignment.CenterVertically){
                                FaTriangleExclamation(
                                    modifier = Modifier.color(sitePalette.primary.rgb)
                                )
                                CustomSpacer(5.px)
                            FieldTitleTextNormalSimple(
                                "Closing coaches details page will cancel uploading",
                                fontSize = 15.px,
                                fontWeight = 500,
                                color = sitePalette.primary.rgb)
                                CustomSpacer(10.px)
                            CircularProgressIndicator(
                                percentage = uploadProgress.toFloat(),
                                size = 40,
                                strokeWidth = 5,
                                fontSize = 10.px,
                                primaryColor = sitePalette.primary.rgb,
                                backgroundColor = Colors.LightGray
                            )
                        }
                            }
                    }
                }
                Row(
                    modifier = Modifier.fillMaxWidth().gap(36.px)
                        .flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
//                    Image(
//                        src = Res.Images.coachPic,
//                        modifier = Modifier.size(30.px).margin(right = 8.px)
//                            .borderRadius(100.px).textAlign(TextAlign.Start)
//                            .border(
//                                width = 2.px,
//                                color = sitePalette.primary.rgb,
//                                style = LineStyle.Solid
//                            )
//                    )
                        FieldTitleTextNormalSimple(
                            text = "Coach:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = sessionData.coachName,
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(src = Res.Images.timeCircle, modifier = Modifier.size(17.px))
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Duration:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = sessionData.recordTime.toLongOrNull()?.let {
                                (it / 1000).formatSessionMinutes()
                            } ?: "Invalid Time",
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(src = Res.Images.calender, modifier = Modifier.size(17.px))
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Recorded:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = "${sessionData.sessionDateTime.formatSessionDate()} ${sessionData.sessionDateTime.formatSessionHours()}",
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }
                }

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .flexWrap(FlexWrap.Wrap),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
// Speaking Box
                    Box(
                        modifier = Modifier.width(
                            if (window.screen.width > 1399) 32.percent
                            else if (breakpoint <= Breakpoint.MD) 100.percent else 49.percent
                        )
                            .height(370.px)
                            .margin(top = 15.px)
                            .borderRadius(12.px)
                            .border(
                                1.px,
                                style = LineStyle.Solid,
                                color = sitePalette.coachDetailsBoxBorder.rgb
                            )
                            .padding(16.px)
                            .boxShadow(
                                offsetY = 1.px,
                                blurRadius = 0.px,
                                color = sitePalette.fadedBlack.rgb

                            )
                    ) {
                        SectionTitle("Speaking")
                        Column(
                            modifier = Modifier.fillMaxSize().margin(right = 30.px),
                            verticalArrangement = Arrangement.Center,
                        ) {
                            Row(
                                modifier = Modifier.fillMaxWidth(),
                                verticalAlignment = Alignment.CenterVertically,
                                horizontalArrangement = Arrangement.SpaceBetween
                            ) {
                                Column(
                                    modifier = Modifier.fillMaxWidth()
                                        .gap(10.px)
                                ) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth()
                                            .flexWrap(FlexWrap.Wrap),
                                        horizontalArrangement = Arrangement.SpaceBetween,
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            FieldTitleTextNormalSimple(
                                                text = "Total Words: ",
                                                fontFamily = Constants.IBM_FONT,
                                                fontSize = 14.px
                                            )
                                            FieldTitleTextNormalSimple(
                                                text = sessionData.text.split(" ").size.toString(),
                                                fontWeight = 600,
                                                fontFamily = Constants.IBM_FONT,
                                                fontSize = 14.px
                                            )

                                        }
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            FieldTitleTextNormalSimple(
                                                text = "Total Keywords: ",
                                                fontFamily = Constants.IBM_FONT,
                                                fontSize = 14.px
                                            )
                                            FieldTitleTextNormalSimple(
                                                text = sessionData.totalKeywords.toString(),
                                                fontWeight = 600,
                                                fontFamily = Constants.IBM_FONT,
                                                fontSize = 14.px
                                            )

                                        }

                                    }
                                    Row(
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        FaHourglassEnd(
                                            modifier = Modifier.color(sitePalette.primary.rgb)
                                                .margin(right = 4.px, bottom = (-7).px),
                                            size = IconSize.LG
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = "Total Duration:  ",
                                            fontFamily = Constants.IBM_FONT,
                                            fontSize = 16.px
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = sessionData.recordTime.toLongOrNull()?.let {
                                                (it / 1000).formatSessionMinutes()
                                            } ?: "Invalid Time",
                                            fontWeight = 600,
                                            fontFamily = Constants.IBM_FONT,
                                            fontSize = 16.px
                                        )
                                    }
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        Image(
                                            src = Res.Images.recording_Voice,
                                            modifier = Modifier.size(17.px).margin(right = 4.px)
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = "Speaking: ",
                                            fontFamily = Constants.IBM_FONT,
                                            fontSize = 16.px
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = sessionData.speakingTime.formatSessionMinutes(),
                                            fontWeight = 600,
                                            fontFamily = Constants.IBM_FONT,
                                            fontSize = 16.px
                                        )
                                    }

                                }


                            }
                            Box(
                                modifier = Modifier.fillMaxWidth().margin(top = 50.px),
                                contentAlignment = Alignment.Center
                            ) {
                                val recordTimeInSeconds = sessionData.recordTime.toLongOrNull()
                                    ?.let { it / 1000 }  // Convert to seconds
                                val percentage =
                                    (sessionData.speakingTime / recordTimeInSeconds!!.toDouble()) * 100

                                CircularProgressIndicator(
                                    percentage = percentage.toFloat(),
                                    size = 102,       // Pass Int directly
                                    strokeWidth = 10,  // Pass Int directly
                                    primaryColor = rgba(255, 128, 8, 1),    // Orange
                                    secondaryColor = rgba(255, 200, 55, 1), // Yellow
                                    backgroundColor = sitePalette.lightGrey.rgb
                                )
                            }

                        }
                    }
// Emotion Box
                    Box(
                        modifier = Modifier.width(
                            if (window.screen.width > 1399) 32.percent
                            else if (breakpoint <= Breakpoint.MD) 100.percent else 49.percent
                        )
                            .height(370.px)
                            .margin(top = 15.px)
                            .borderRadius(12.px)
                            .border(
                                1.px,
                                style = LineStyle.Solid,
                                color = sitePalette.coachDetailsBoxBorder.rgb
                            )
                            .padding(16.px)
                            .boxShadow(
                                offsetY = 1.px,
                                blurRadius = 0.px,
                                color = sitePalette.fadedBlack.rgb

                            )
                    ) {
                        Column(modifier = Modifier.fillMaxSize()) {
                            SectionTitle("Emotion")
                            if (isEmotionLoading) {
                                Box(
                                    modifier = Modifier.fillMaxSize(),
                                    contentAlignment = Alignment.Center
                                ) {
                                    ProgressIndicator(30.px)
                                }
                            } else {
                                Column(
                                    modifier = Modifier.fillMaxWidth(),
                                    horizontalAlignment = Alignment.CenterHorizontally
                                ) {
                                    RadarChartPage(sessionData)
//
                                }
                            }
                        }
                    }

//  Sentiment Box
                    Box(
                        modifier = Modifier.width(if (window.screen.width > 1399) 32.percent else 100.percent)
                            .height(370.px)
                            .margin(top = 15.px)
                            .borderRadius(12.px)
                            .border(
                                1.px,
                                style = LineStyle.Solid,
                                color = sitePalette.coachDetailsBoxBorder.rgb
                            )
                            .padding(16.px)
                            .boxShadow(
                                offsetY = 1.px,
                                blurRadius = 0.px,
                                color = sitePalette.fadedBlack.rgb

                            )
                    ) {
                        Column(modifier = Modifier.fillMaxWidth()) {
                            SectionTitle("Sentiments")
                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .borderRadius(8.px),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Row(
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .height(8.px)
                                        .borderRadius(8.px)

                                ) {

                                    Box(
                                        modifier = Modifier
                                            .fillMaxHeight()
                                            .borderRadius(topLeft = 8.px, bottomLeft = 8.px)
                                            .width(positivePercentage.percent)
                                            .backgroundColor(Palette.PositiveColor.rgbLight)
                                    )
                                    Box(
                                        modifier = Modifier
                                            .fillMaxHeight()
                                            .width(negativePercentage.percent)
                                            .backgroundColor(Palette.NegativeColor.rgbLight)
                                    )
                                    Box(
                                        modifier = Modifier
                                            .fillMaxHeight()
                                            .borderRadius(topRight = 8.px, bottomRight = 8.px)
                                            .width(neutralPercentage.percent)
                                            .backgroundColor(Palette.NeutralColor.rgbLight)
                                    )
                                }

                            }
                            Row(
                                modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px)
                                    .gap(8.px)
                                    .flexWrap(FlexWrap.Wrap),
                                verticalAlignment = Alignment.CenterVertically,
                            ) {
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Box(
                                        modifier = Modifier.size(10.px)
                                            .borderRadius(1.67.px)
                                            .backgroundColor(Palette.PositiveColor.rgbLight)
                                    )
                                    FieldTitleTextNormalSimple(
                                        text = "Positive: $positivePercentage%",
                                        marginLeft = 10.px,
                                        fontSize = 12.px
                                    )
                                }
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Box(
                                        modifier = Modifier.size(10.px)
                                            .borderRadius(1.67.px)
                                            .backgroundColor(Palette.NegativeColor.rgbLight)
                                    )
                                    FieldTitleTextNormalSimple(
                                        text = "Negative: $negativePercentage%",
                                        marginLeft = 10.px,
                                        fontSize = 12.px
                                    )
                                }
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    Box(
                                        modifier = Modifier.size(10.px)
                                            .borderRadius(1.67.px)
                                            .backgroundColor(Palette.NeutralColor.rgbLight)
                                    )
                                    FieldTitleTextNormalSimple(
                                        text = "Neutral: $neutralPercentage%",
                                        marginLeft = 10.px,
                                        fontSize = 12.px
                                    )
                                }
                            }

                            FieldTitleTextNormalSimple(
                                text = "Tone",
                                fontSize = 20.px,
                                fontFamily = Constants.IBM_FONT,
                                fontWeight = 600,
                            )
                            Column(
                                modifier = Modifier.gap(8.px).fillMaxWidth().margin(bottom = 10.px)
                                    .overflow(
                                        overflowY = Overflow.Auto,
                                        overflowX = Overflow.Hidden
                                    )
                            ) {
                                // Dynamically generate rows based on toneAnalysis data
                                toneAnalysis.classes.forEach { tone ->
                                    Row(verticalAlignment = Alignment.CenterVertically) {
                                        FieldTitleTextNormalSimple(
                                            text = "${getEmoji(tone.className)} ${tone.className}", // Name of the tone
                                            width = 100.px,
                                            fontSize = 14.px,
                                            fontFamily = Constants.IBM_FONT
                                        )
                                        CustomSpacer(10.px)
                                        Box(
                                            modifier = Modifier
                                                .width((tone.confidence * 238).px) // Adjust width based on confidence (scaled)
                                                .height(22.px)
                                                .borderRadius(bottomRight = 2.92.px)
                                                .backgroundColor(Palette.NeutralColor.rgbLight)
                                        )
                                        FieldTitleTextNormalSimple(
                                            text = "${(tone.confidence * 100).toInt()}%", // Confidence percentage
                                            fontSize = 12.px,
                                            fontFamily = Constants.IBM_FONT,
                                            marginLeft = 5.px
                                        )
                                    }
                                }
                            }
                        }
                    }
                }

                //            Summary Box
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .height(400.px)
                        .margin(top = 15.px)
                        .borderRadius(12.px)
                        .border(
                            1.px,
                            style = LineStyle.Solid,
                            color = sitePalette.coachDetailsBoxBorder.rgb
                        )
                        .padding(16.px)
                        .boxShadow(
                            offsetY = 1.px,
                            blurRadius = 0.px,
                            color = sitePalette.fadedBlack.rgb
                        )
                ) {
                    SectionTitle("Summary")
                    Column(
                        modifier = Modifier.fillMaxWidth()
                            .maxHeight(360.px)
                            .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                    ) {
                        FieldTitleTextNormalSimple(
                            width = 100.percent,
                            whiteSpace = WhiteSpace.PreWrap,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px,
                            text = summaryData.split("\n").joinToString("\n") { it.trim() }
                        )
                    }

                }

//  Highlights Box
                Column(
                    modifier = Modifier.width(100.percent)
                        .height(if(sessionData.videoFile.isNotEmpty())1575.px else 975.px)
                        .margin(top = 15.px)
                        .borderRadius(12.px)
                        .border(
                            1.px,
                            style = LineStyle.Solid,
                            color = sitePalette.coachDetailsBoxBorder.rgb
                        )
                        .padding(16.px)
                        .boxShadow(
                            offsetY = 1.px,
                            blurRadius = 0.px,
                            color = sitePalette.fadedBlack.rgb
                        )
                ) {
                    Column(
                        modifier = Modifier.fillMaxSize()
                    ) {
                        if (isLoading) Box(
                            modifier = Modifier.fillMaxSize(),
                            contentAlignment = Alignment.Center
                        ) {
                            ProgressIndicator(50.px)
                        } else {
                            Column(modifier = Modifier.fillMaxSize()) {
                                SectionTitle("Full Session")
                                if(sessionData.videoFile.isNotEmpty()) {
                                    val modifier: Modifier = if (breakpoint>=Breakpoint.XL) Modifier.height(400.px) else Modifier
                                    Box(
                                        modifier = modifier.fillMaxWidth()
                                            .margin(topBottom = 10.px)
                                            .onMouseEnter { isHovered1 = true }
                                            .onMouseLeave { isHovered1 = false },
                                        contentAlignment = Alignment.Center
                                    ) {
                                        if (!isVideoDurationPlaying1) {
                                            Box(
                                                modifier = Modifier.size(54.px)
                                                    .zIndex(9999)
                                                    .borderRadius(100.percent)
                                                    .backgroundColor(sitePalette.primary.rgb)
                                                    .cursor(Cursor.Pointer)
                                                    .onClick {
                                                        fullSessionVideo = true
                                                        val videoElement = document.getElementById("video_$sessionId1") as? HTMLVideoElement
                                                        videoElement?.let { video ->
                                                            getSentenceAudio.let { audio ->
                                                                playVideoWithAudio(video, audio)
                                                                currentlyPlayingVideoId1 = sessionId1
                                                                currentVideoRef1.value = video
                                                                currentAudioRefHighlight.value = audio
                                                            }
                                                        }
                                                    },
                                                contentAlignment = Alignment.Center
                                            ) {
                                                FaPlay(
                                                    size = IconSize.XL,
                                                    modifier = Modifier.color(Colors.White)
                                                )
                                            }
                                        } else {
                                            if (isHovered1 || isBuffering1) {
                                                Box(
                                                    modifier = Modifier.size(54.px)
                                                        .zIndex(9999)
                                                        .borderRadius(100.percent)
                                                        .backgroundColor(sitePalette.primary.rgb)
                                                        .cursor(Cursor.Pointer)
                                                        .onClick {
                                                            fullSessionVideo = false
                                                            val videoElement = document.getElementById("video_$sessionId1") as? HTMLVideoElement
                                                            val audioElement = currentAudioRefHighlight.value

                                                            videoElement?.let {
                                                                isVideoDurationPlaying1 = false
                                                                it.pause()
                                                                audioElement?.pause()
                                                                currentlyPlayingVideoId1 = null
                                                                currentVideoRef1.value = null
                                                                currentAudioRefHighlight.value = null
                                                            }
                                                        },
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    if (isBuffering1){
                                                        ProgressIndicator()
                                                    } else {
                                                        FaPause(
                                                            size = IconSize.XL,
                                                            modifier = Modifier.color(Colors.White)
                                                        )
                                                    }
                                                }
                                            }
                                        }

                                        Box(modifier = Modifier.fillMaxSize()){
                                            if (isHovered1 && isVideoDurationPlaying1 ) {
                                                    VideoProgressSliderCustom(
                                                        modifier = Modifier.fillMaxWidth(97.percent)
                                                            .align(Alignment.BottomCenter)
                                                            .margin(bottom = 40.px)
                                                            .zIndex(9999),
                                                        progress = videoProgress1,
                                                        onProgressChange = { newProgress ->
                                                            val videoElement = document.getElementById("video_$sessionId1") as? HTMLVideoElement
                                                            val audioElement = currentAudioRefHighlight.value

                                                            videoElement?.let { video ->
                                                                val newTime = newProgress * video.duration

                                                                video.currentTime = newTime
                                                                audioElement?.currentTime = newTime

                                                                videoProgress1 = (newTime / video.duration).toFloat()

                                                                if (isVideoDurationPlaying1) {
                                                                    video.play()
                                                                    audioElement?.play()
                                                                }
                                                            }
                                                        }
                                                    )
                                            }

                                            LaunchedEffect(sessionId1) {
                                                val videoElement = document.getElementById("video_$sessionId1") as? HTMLVideoElement
                                                videoElement?.muted = true // Ensure it's always muted
                                            }
                                            Video(
                                                attrs = {
                                                    attr("id", "video_$sessionId1")
//                                                    attr("src", Res.Video.video_For_Demo)
                                                    attr("src", sessionVideo )
                                                    attr("muted", "true")
                                                    style {
                                                        property("width", "100%")
                                                        property("height", "95%")
                                                        property("border-radius", "8px")
                                                        property("object-fit", "cover")
                                                        property("align", "bottom center")
                                                    }
                                                }
                                            )
                                        }
                                    }
                                }
                                Row(
                                    modifier = Modifier.fillMaxWidth()
                                        .height(40.px)
                                        .borderRadius(8.px)
                                        .padding(8.px)
                                        .gap(8.px)
                                        .backgroundColor(sitePalette.searchBackground.rgb),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    if (loadingSessionIdFullSession == sessionData.sessionId) {
                                        Box(
                                            modifier = Modifier.size(30.px)
                                                .borderRadius(100.percent)
                                                .backgroundColor(sitePalette.primary.rgb),
                                            contentAlignment = Alignment.Center
                                        ) {
                                            ProgressIndicator(10.px)
                                        }
                                    } else if (currentlyPlayingIdFullSession == sessionData.sessionId) {
                                        Box(
                                            modifier = Modifier.size(30.px)
                                                .borderRadius(100.percent)
                                                .cursor(Cursor.Pointer)
                                                .backgroundColor(sitePalette.primary.rgb)
                                                .onClick {
                                                println("Pausing audio for session: ${sessionData.sessionId}")
                                                currentAudioRefFullSession.value?.pause()
                                                currentlyPlayingIdFullSession = null
                                                currentAudioRefFullSession.value = null
                                                fullPlayAudioProgress = 0f
                                            },
                                            contentAlignment = Alignment.Center
                                        ) {
                                            FaPause(
                                                size = IconSize.XXS,
                                                modifier = Modifier.color(Colors.White)
                                            )
                                        }
                                    } else {
                                        Image(
                                            src = Res.Images.play_all_button,
                                            modifier = Modifier.size(30.px)
                                                .cursor(Cursor.Pointer)
                                                .onClick {
                                                    println("full audio url ${sessionData.sessionId}")
                                                    currentAudioRefFullSession.value?.pause()
                                                    loadingSessionIdFullSession = fullPlaySessionId

                                                    fullAudio.onloadeddata = {

                                                        loadingSessionIdFullSession = null
                                                        fullAudio.play()
                                                        playAllAudio.pause()
                                                        getSentenceAudio.pause()
                                                        isPlayingAll = false
                                                        currentlyPlayingIdHighlight = null
                                                        currentlyPlayingIdFullSession =
                                                            fullPlaySessionId
                                                        fullAudio.also {
                                                            currentAudioRefFullSession.value = it
                                                        }
                                                    }
                                                    fullAudio.load()
                                                }
                                        )
                                    }
                                    AudioWaveform(
                                        modifier = Modifier.fillMaxWidth(),
                                        progress = fullPlayAudioProgress,
                                        audioDuration = currentAudioRefFullSession.value?.duration
                                            ?: 0.0,
                                        onClick = { time ->
                                            fullAudio.currentTime = time
                                        },
                                        barsCount = if (breakpoint >= Breakpoint.XL) 200 else if (window.screen.width < 375 ) 50 else 100
                                    )

                                }
                                Column(
                                    modifier = Modifier.fillMaxWidth().maxHeight(250.px)
                                        .padding(8.px)
                                        .overflow(
                                            overflowY = Overflow.Auto,
                                            overflowX = Overflow.Hidden
                                        )
                                ) {
                                    Row(
                                        modifier = Modifier.fillMaxWidth()
                                            .margin(topBottom = 10.px)
                                            .flexWrap(FlexWrap.Wrap)
                                            .overflow(
                                                overflowY = Overflow.Auto,
                                                overflowX = Overflow.Hidden
                                            )
                                    ) {
                                        // Create a map to store unique keywords and their total counts and categories
                                        val uniqueKeywords =
                                            mutableMapOf<String, Pair<Int, String>>()

                                        // Aggregate counts and preserve category for each unique keyword
                                        keywordExtraction.views.forEach { view ->
                                            view.properties.forEach { property ->
                                                val keyword =
                                                    property.aqlProperty.match.text.lowercase() // Normalize to lowercase
                                                val keywordCategory = property.aqlProperty.label
                                                val (currentCount, _) = uniqueKeywords[keyword]
                                                    ?: Pair(
                                                        0,
                                                        keywordCategory
                                                    )
                                                uniqueKeywords[keyword] =
                                                    Pair(currentCount + 1, keywordCategory)
                                            }
                                        }

                                        // Display unique keywords with their total counts
                                        uniqueKeywords.forEach { (keyword, countAndCategory) ->
                                            val (count, keywordCategory) = countAndCategory

                                            Box {
                                                Box(
                                                    modifier = Modifier.height(32.px)
                                                        .backgroundColor(
                                                            when (keywordCategory) {
                                                                "fitness" -> sitePalette.fitnessCategoryBackground.rgb
                                                                "mental" -> sitePalette.mentalCategoryBackground.rgb
                                                                "technical" -> sitePalette.technicalCategoryBackground.rgb
                                                                "tactical" -> sitePalette.tacticalCategoryBackground.rgb
                                                                "goalkeeper" -> sitePalette.goalKeeperCategoryBackground.rgb
                                                                else -> sitePalette.textErrorColor.rgb
                                                            }
                                                        )
                                                        .borderRadius(7.px)
                                                        .padding(leftRight = 8.px, topBottom = 4.px)
                                                        .border(
                                                            1.px, color =
                                                            when (keywordCategory) {
                                                                "fitness" -> sitePalette.fitnessCategoryBorder.rgb
                                                                "mental" -> sitePalette.mentalCategoryBorder.rgb
                                                                "technical" -> sitePalette.technicalCategoryBorder.rgb
                                                                "tactical" -> sitePalette.tacticalCategoryBorder.rgb
                                                                "goalkeeper" -> sitePalette.goalKeeperCategoryBorder.rgb
                                                                else -> sitePalette.textErrorColor.rgb
                                                            }, style = LineStyle.Solid
                                                        )
                                                        .margin(7.px),
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    FieldTitleTextNormalSimple(
                                                        keyword.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
                                                    )
                                                }
                                                Box(
                                                    modifier = Modifier.size(18.px)
                                                        .margin(right = (-5).px)
                                                        .align(Alignment.TopEnd)
                                                        .backgroundColor(
                                                            when (keywordCategory) {
                                                                "fitness" -> sitePalette.fitnessCategoryBorder.rgb
                                                                "mental" -> sitePalette.mentalCategoryBorder.rgb
                                                                "technical" -> sitePalette.technicalCategoryBorder.rgb
                                                                "tactical" -> sitePalette.tacticalCategoryBorder.rgb
                                                                "goalkeeper" -> sitePalette.goalKeeperCategoryBorder.rgb
                                                                else -> sitePalette.textErrorColor.rgb
                                                            }
                                                        )
                                                        .borderRadius(100.px),
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    FieldTitleTextNormalSimple(
                                                        text = count.toString(),
                                                        color = Colors.White,
                                                        fontSize = 10.px
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                                CustomDivider()
                                CustomSpacer(height = 15.px)
                                Row(
                                    modifier = Modifier.fillMaxWidth(),
                                    verticalAlignment = Alignment.CenterVertically,
                                    horizontalArrangement = Arrangement.SpaceBetween
                                ) {
                                    SectionTitle("Highlights")
                                    fun playAllHighlights(
                                        highlights: List<GetSentencesData>,
                                        onComplete: () -> Unit
                                    ) {
                                        // Cancel any existing playback safely
                                        playJob?.cancel()

                                        playJob = CoroutineScope(Dispatchers.Main).launch {
                                            try {
                                                for ((index, highlight) in highlights.withIndex()) {
                                                    if (!isPlayingAll) break // Stop playback if paused

                                                    val startTime = highlight.startTime
                                                    val endTime = highlight.endTime

                                                    println("Highlight $index -> StartTime: $startTime, EndTime: $endTime")

                                                    if (endTime <= startTime) {
                                                        println("Skipping highlight $index due to invalid duration")
                                                        continue
                                                    }

                                                    val duration =
                                                        (endTime - startTime) * 1000 // Convert seconds to ms

                                                    println("Playing highlight ${index + 1} of ${highlights.size} -> Duration: $duration ms")

                                                    playAllAudio.currentTime = startTime

                                                    try {
                                                        playAllAudio.play()
                                                    } catch (e: Exception) {
                                                        println("Error playing audio: ${e.message}")
                                                        break
                                                    }

                                                    delay(duration.toLong())

                                                    if (isPlayingAll) {
                                                        playAllAudio.pause()
                                                    }
                                                }
                                            } catch (e: CancellationException) {
                                                println("Playback was cancelled.")
                                            } catch (e: Exception) {
                                                e.printStackTrace()
                                            } finally {
                                                isPlayingAll = false
                                                onComplete()
                                            }
                                        }
                                    }
                                    Row(
                                        modifier = Modifier
                                            .width(150.px)
                                            .height(36.px)
                                            .borderRadius(4.px)
                                            .backgroundColor(sitePalette.searchBackground.rgb)
                                            .padding(topBottom = 10.px, leftRight = 8.px)
                                            .gap(8.px)
                                            .onClick {  },
                                        verticalAlignment = Alignment.CenterVertically
                                    ) {
                                        Box(contentAlignment = Alignment.Center) {
                                            if (isPlayingAll) {
                                                Box(
                                                    modifier = Modifier
                                                        .size(27.px)
                                                        .borderRadius(100.percent)
                                                        .backgroundColor(sitePalette.primary.rgb)
                                                        .cursor(Cursor.Pointer)
                                                        .onClick {
                                                            isPlayingAll = false
                                                            playJob?.cancel() // Cancel playback safely
                                                            playAllAudio.pause()

                                                        },
                                                    contentAlignment = Alignment.Center
                                                ) {
                                                    FaPause(
                                                        modifier = Modifier.color(Palette.White.rgbLight),
                                                        size = IconSize.XXS
                                                    )
                                                }
                                            } else {
                                                Image(
                                                    src = Res.Images.play_all_button,
                                                    modifier = Modifier.onClick {
                                                        isPlayingAll = true
                                                        getSentenceAudio.pause()
                                                        fullAudio.pause()
                                                        currentlyPlayingIdFullSession = null
                                                        currentlyPlayingIdHighlight = null
                                                        playAllAudio.currentTime = 0.0
                                                            playAllHighlights(
                                                                getSentences.filter { it.keywords?.size!! > 0 }.sortedByDescending { it.keywords?.size ?: 0 }
                                                            ) {
                                                                isPlayingAll = false // Reset when playback finishes
                                                            }
                                                    }
                                                        .size(27.px)
                                                        .cursor(Cursor.Pointer)
                                                )
                                            }
                                        }

                                        // Text Label for "Play All"
                                        FieldTitleTextNormalSimple(
                                            "Play All",
                                            fontWeight = 500,
                                            fontSize = 16.px,
                                            fontFamily = Constants.IBM_FONT
                                        )
                                    }

                                }
                                Column(
                                    modifier = Modifier.fillMaxWidth().height(if(sessionData.videoFile.isNotEmpty()) 880.px else 680.px)
                                        .margin(topBottom = 10.px)
                                        .overflow(
                                            overflowY = Overflow.Auto,
                                            overflowX = Overflow.Hidden
                                        )
                                ) {
                                    if (isSentenceLoading) {
                                        Box(
                                            modifier = Modifier.fillMaxSize(),
                                            contentAlignment = Alignment.Center
                                        ) {
                                            ProgressIndicator(size = 50.px)
                                        }
                                    } else {

                                        getSentences.sortedByDescending { it.keywords!!.size }
                                            .forEachIndexed { index, sentenceData ->
                                                val sessionId = sentenceData.id
                                                var audioProgress by remember { mutableStateOf(0f) }
                                                scope.launch {
                                                    val taggedPlayers = sentenceData.tagPlayers

                                                    if (taggedPlayers?.isNotEmpty() == true) {
                                                        val filteredList= mutableListOf<GetClubPlayerData>()
                                                        taggedPlayers.forEach { player ->
                                                            val filterPlayer =
                                                                players.filter { it.uid == player }
                                                            println("filterPlayer: $filterPlayer")

                                                            if (filterPlayer.isNotEmpty()) {
                                                                filteredList.add(filterPlayer.first())
                                                            }
                                                        }

                                                        selectedPlayers =
                                                            selectedPlayers.toMutableMap()
                                                                .apply {
                                                                    put(
                                                                        sentenceData.id,
                                                                        filteredList
                                                                    )
                                                                }


                                                    }
                                                }

                                                LaunchedEffect(currentlyPlayingIdHighlight) {
                                                    isThisPlaying = currentlyPlayingIdHighlight == sessionId

                                                    if (currentlyPlayingIdHighlight == sessionId) {
                                                        val audioPlay = currentAudioRefHighlight.value
                                                        audioPlay?.apply {
                                                            while (currentTime < sentenceData.endTime) {
                                                                if (currentlyPlayingIdHighlight != sessionId) {
                                                                    pause()
                                                                    currentlyPlayingIdHighlight = null
                                                                    currentAudioRefHighlight.value = null
                                                                    audioProgress = 0f
                                                                    isThisPlaying = false
                                                                    break
                                                                }

                                                                audioProgress = ((currentTime - sentenceData.startTime) / (sentenceData.endTime - sentenceData.startTime)).toFloat()
                                                                delay(100)

                                                                if (currentTime >= sentenceData.endTime) {
                                                                    pause()
                                                                    currentlyPlayingIdHighlight = null
                                                                    currentAudioRefHighlight.value = null
                                                                    audioProgress = 0f
                                                                    isThisPlaying = false
                                                                    break
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                LaunchedEffect(sessionId) {
                                                    if (currentlyPlayingIdHighlight != sessionId) {
                                                        currentAudioRefHighlight.value?.pause()
                                                        currentlyPlayingIdHighlight = null
                                                        currentAudioRefHighlight.value = null
                                                        audioProgress = 0f
                                                        isThisPlaying = false
                                                    }
                                                }

                                                DisposableEffect(Unit) {
                                                    onDispose {
                                                        currentAudioRefHighlight.value?.pause()
                                                        currentlyPlayingIdHighlight = null
                                                        currentAudioRefHighlight.value = null
                                                        audioProgress = 0f
                                                        isThisPlaying = false
                                                    }
                                                }

                                                if (sentenceData.keywords!!.isNotEmpty()) {
                                                    Column(
                                                        modifier = Modifier.margin(bottom = 10.px)
                                                            .fillMaxWidth()
                                                    ) {
                                                        Row(modifier = Modifier.fillMaxWidth().padding(leftRight = 20.px)
                                                            .flexWrap(FlexWrap.Wrap),
                                                            horizontalArrangement = Arrangement.SpaceBetween,
                                                            verticalAlignment = Alignment.CenterVertically) {
                                                            Column(modifier = Modifier.fillMaxWidth()) {
                                                               if(sessionData.videoFile.isEmpty()) {
                                                                   Row(
                                                                       modifier = Modifier.width(if (breakpoint >= Breakpoint.LG) 390.px else 100.percent)
                                                                           .height(40.px)
                                                                           .borderRadius(8.px)
                                                                           .padding(8.px)
                                                                           .gap(8.px)
                                                                           .backgroundColor(
                                                                               sitePalette.searchBackground.rgb
                                                                           ),
                                                                       verticalAlignment = Alignment.CenterVertically
                                                                   ) {
                                                                       Box(
                                                                           modifier = Modifier.size(
                                                                               30.px
                                                                           )
                                                                               .borderRadius(100.percent)
                                                                               .backgroundColor(if (currentlyPlayingIdHighlight == sessionId) sitePalette.primary.rgb else Colors.Transparent)
                                                                               .cursor(Cursor.Pointer)
                                                                               .onClick {
                                                                                   isPlayingAllHighlight = false  // Reset play all flag for individual play
                                                                                   if (currentlyPlayingIdHighlight == sessionId) {
                                                                                       // Stop current audio
                                                                                       getSentenceAudio.pause()
                                                                                       currentlyPlayingIdHighlight = null
                                                                                       currentlyPlayingIndexHighlight = null
                                                                                       currentAudioRefHighlight.value = null
                                                                                       audioProgress = 0f
                                                                                   } else {
                                                                                       // Stop any currently playing audio
                                                                                       currentAudioRefHighlight.value?.pause()
                                                                                       currentlyPlayingIdHighlight = null
                                                                                       currentAudioRefHighlight.value = null

                                                                                       // Play clicked audio
                                                                                       loadingSessionIdHighlight = sessionId

                                                                                       getSentenceAudio.onloadeddata = {
                                                                                           loadingSessionIdHighlight = null
                                                                                           getSentenceAudio.currentTime = sentenceData.startTime
                                                                                           getSentenceAudio.play()
                                                                                           fullAudio.pause()
                                                                                           playAllAudio.pause()
                                                                                           isPlayingAll = false
                                                                                           currentlyPlayingIdHighlight = sessionId
                                                                                           currentlyPlayingIndexHighlight = index
                                                                                           getSentenceAudio.also {
                                                                                               currentAudioRefHighlight.value = it
                                                                                           }
                                                                                       }
                                                                                       getSentenceAudio.load()
                                                                                   }
                                                                               },
                                                                           contentAlignment = Alignment.Center
                                                                       ) {
                                                                           if (loadingSessionIdHighlight == sessionId) {
                                                                               ProgressIndicator(10.px)
                                                                           } else if (currentlyPlayingIdHighlight == sessionId) {
                                                                               FaPause(
                                                                                   modifier = Modifier.color(
                                                                                       Palette.White.rgbLight
                                                                                   ),
                                                                                   size = IconSize.XXS
                                                                               )
                                                                           } else {
                                                                               Image(
                                                                                   src = Res.Images.play_all_button,
                                                                                   modifier = Modifier.size(
                                                                                       30.px
                                                                                   )
                                                                               )
                                                                           }
                                                                       }
                                                                       SentenceAudioWaveform(
                                                                           modifier = Modifier.fillMaxWidth(),
                                                                           progress = audioProgress,
                                                                           startTime = sentenceData.startTime,
                                                                           endTime = sentenceData.endTime,
                                                                           isPlaying = isThisPlaying,
                                                                           onProgressChange = { newProgress ->
                                                                               audioProgress =
                                                                                   newProgress
                                                                               currentAudioRefHighlight.value?.let { audio ->
                                                                                   audio.currentTime =
                                                                                       sentenceData.startTime + (sentenceData.endTime - sentenceData.startTime) * newProgress
                                                                               }
                                                                           }
                                                                       )
                                                                   }
                                                               }
                                                                if(sessionData.videoFile.isNotEmpty()) {
                                                                    var videoProgress by remember { mutableStateOf(0f) }
                                                                    var isBuffering by remember { mutableStateOf(false) }
                                                                    var isHovered by remember { mutableStateOf(false) }
                                                                    var currentVideoRef = remember { mutableStateOf<HTMLVideoElement?>(null) }
                                                                    var currentAudioRefHighlight = remember { mutableStateOf<HTMLAudioElement?>(null) }

                                                                    // Cleanup Effect when session changes or component unmounts
                                                                    DisposableEffect(sessionId) {
                                                                        val videoElement = document.getElementById("video_$sessionId") as? HTMLVideoElement
                                                                        videoElement?.muted = true // Ensure video is always muted

                                                                        onDispose {
                                                                            println("Cleanup triggered for session: $sessionId")
                                                                            currentVideoRef.value?.apply {
                                                                                pause()
                                                                                currentTime = sentenceData.startTime
                                                                                onwaiting = null
                                                                                onplaying = null
                                                                                onprogress = null
                                                                                ontimeupdate = null
                                                                            }
                                                                            currentAudioRefHighlight.value?.apply {
                                                                                pause()
                                                                                currentTime = sentenceData.startTime
                                                                            }
                                                                            videoProgress = 0f
                                                                            isBuffering = false
                                                                            currentVideoRef.value = null
                                                                            currentAudioRefHighlight.value = null
                                                                        }
                                                                    }

                                                                    // Handle video playback synchronization
                                                                    LaunchedEffect(currentlyPlayingIdHighlight, fullSessionVideo) {
                                                                        if (fullSessionVideo || currentlyPlayingIdHighlight != sessionId) {
                                                                            // Stop playback if full session is playing or another highlight is active
                                                                            currentVideoRef.value?.pause()
                                                                            currentAudioRefHighlight.value?.pause()
                                                                            videoProgress = 0f
                                                                            return@LaunchedEffect
                                                                        }

                                                                        val videoElement = document.getElementById("video_$sessionId") as? HTMLVideoElement
                                                                        videoElement?.let { video ->
                                                                            // Set up video event listeners
                                                                            video.onwaiting = {
                                                                                isBuffering = true
                                                                                currentAudioRefHighlight.value?.pause()
                                                                            }

                                                                            video.onplaying = {
                                                                                isBuffering = false
                                                                                currentAudioRefHighlight.value?.play()
                                                                            }

                                                                            video.onprogress = {
                                                                                if (video.buffered.length > 0) {
                                                                                    val bufferedEnd = video.buffered.end(0)
                                                                                    val bufferedSeconds = bufferedEnd - video.currentTime

                                                                                    if (bufferedSeconds > 1 && video.paused && !isBuffering) {
                                                                                        video.play()
                                                                                        currentAudioRefHighlight.value?.play()
                                                                                    }
                                                                                }
                                                                            }

                                                                            video.ontimeupdate = {
                                                                                if (video.currentTime >= sentenceData.endTime) {
                                                                                    video.pause()
                                                                                    currentAudioRefHighlight.value?.pause()
                                                                                    videoProgress = 0f
                                                                                    currentlyPlayingIdHighlight = null
                                                                                } else {
                                                                                    videoProgress = ((video.currentTime - sentenceData.startTime) /
                                                                                            (sentenceData.endTime - sentenceData.startTime)).toFloat()

                                                                                    // Keep audio synced
                                                                                    currentAudioRefHighlight.value?.let { audio ->
                                                                                        val timeDiff = kotlin.math.abs(video.currentTime - audio.currentTime)
                                                                                        if (timeDiff > 0.1) {
                                                                                            audio.currentTime = video.currentTime
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }

                                                                            currentVideoRef.value = video
                                                                        }
                                                                    }

                                                                    Box(
                                                                        modifier = Modifier.fillMaxWidth()
                                                                            .margin(topBottom = 10.px)
                                                                            .onMouseEnter { isHovered = true }
                                                                            .onMouseLeave { isHovered = false },
                                                                        contentAlignment = Alignment.Center
                                                                    ) {
                                                                        // Play button
                                                                        if (currentlyPlayingIdHighlight != sessionId) {
                                                                            Box(
                                                                                modifier = Modifier.size(40.px)
                                                                                    .zIndex(9999)
                                                                                    .borderRadius(100.percent)
                                                                                    .backgroundColor(sitePalette.primary.rgb)
                                                                                    .cursor(Cursor.Pointer)
                                                                                    .onClick {
                                                                                        fullSessionVideo = false
                                                                                        // Stop any currently playing video/audio
                                                                                        currentVideoRef.value?.pause()
                                                                                        currentAudioRefHighlight.value?.pause()

                                                                                        val videoElement = document.getElementById("video_$sessionId") as? HTMLVideoElement
                                                                                        videoElement?.let { video ->
                                                                                            video.currentTime = sentenceData.startTime
                                                                                            getSentenceAudio.currentTime = sentenceData.startTime

                                                                                            // Start playback
                                                                                            currentlyPlayingIdHighlight = sessionId
                                                                                            video.play()
                                                                                            getSentenceAudio.play()
                                                                                            currentAudioRefHighlight.value = getSentenceAudio
                                                                                        }
                                                                                    },
                                                                                contentAlignment = Alignment.Center
                                                                            ) {
                                                                                FaPlay(
                                                                                    size = IconSize.LG,
                                                                                    modifier = Modifier.color(Colors.White)
                                                                                )
                                                                            }
                                                                        } else {
                                                                            // Pause button or loading indicator
                                                                            if (isHovered || isBuffering) {
                                                                                Box(
                                                                                    modifier = Modifier.size(40.px)
                                                                                        .zIndex(9999)
                                                                                        .borderRadius(100.percent)
                                                                                        .backgroundColor(sitePalette.primary.rgb)
                                                                                        .cursor(Cursor.Pointer)
                                                                                        .onClick {
                                                                                            currentlyPlayingIdHighlight = null
                                                                                            currentVideoRef.value?.pause()
                                                                                            currentAudioRefHighlight.value?.pause()
                                                                                        },
                                                                                    contentAlignment = Alignment.Center
                                                                                ) {
                                                                                    if (isBuffering) {
                                                                                        ProgressIndicator()
                                                                                    } else {
                                                                                        FaPause(
                                                                                            size = IconSize.LG,
                                                                                            modifier = Modifier.color(Colors.White)
                                                                                        )
                                                                                    }
                                                                                }
                                                                            }
                                                                        }

                                                                        // Progress bar
                                                                        Box(
                                                                            modifier = Modifier.fillMaxWidth().align(Alignment.BottomCenter)
                                                                        ) {
                                                                            if (isHovered && currentlyPlayingIdHighlight == sessionId) {
                                                                                VideoProgressSliderCustom(
                                                                                    modifier = Modifier.fillMaxWidth(97.percent)
                                                                                        .align(Alignment.BottomCenter)
                                                                                        .margin(bottom = 20.px)
                                                                                        .zIndex(9999),
                                                                                    progress = videoProgress,
                                                                                    onProgressChange = { newProgress ->
                                                                                        val videoElement = currentVideoRef.value
                                                                                        val audioElement = currentAudioRefHighlight.value

                                                                                        videoElement?.let { video ->
                                                                                            val newTime = sentenceData.startTime +
                                                                                                    (newProgress * (sentenceData.endTime - sentenceData.startTime))
                                                                                            val clampedTime = newTime.coerceIn(sentenceData.startTime, sentenceData.endTime)

                                                                                            video.currentTime = clampedTime
                                                                                            audioElement?.currentTime = clampedTime
                                                                                            videoProgress = ((clampedTime - sentenceData.startTime) /
                                                                                                    (sentenceData.endTime - sentenceData.startTime)).toFloat()

                                                                                            if (currentlyPlayingIdHighlight == sessionId && !isBuffering) {
                                                                                                video.play()
                                                                                                audioElement?.play()
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                )
                                                                            }

                                                                            // Video element
                                                                            Video(
                                                                                attrs = {
                                                                                    attr("id", "video_$sessionId")
                                                                                    attr("src", sessionVideo)
                                                                                    attr("muted", "true")
                                                                                    attr("preload", "auto")
                                                                                    style {
                                                                                        property("width", "100%")
                                                                                        property("height", "100%")
                                                                                        property("border-radius", "8px")
                                                                                        property("object-fit", "cover")
                                                                                        property("align", "bottom center")
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    }

                                                                }

                                                                // Keywords Row
                                                                Row(
                                                                    modifier = Modifier.fillMaxWidth()
                                                                        .margin(topBottom = 10.px)
                                                                        .flexWrap(FlexWrap.Wrap)
                                                                        .overflow(
                                                                            overflowY = Overflow.Auto,
                                                                            overflowX = Overflow.Hidden
                                                                        )
                                                                ) {
                                                                    sentenceData.keywords.forEach { keyword ->
                                                                        val keywordCategory =
                                                                            keyword.keywordCategory
                                                                        val keywordText =
                                                                            keyword.text
                                                                        Box {
                                                                            Box(
                                                                                modifier = Modifier.height(
                                                                                    32.px
                                                                                )
                                                                                    .backgroundColor(
                                                                                        when (keywordCategory) {
                                                                                            "fitness" -> sitePalette.fitnessCategoryBackground.rgb
                                                                                            "mental" -> sitePalette.mentalCategoryBackground.rgb
                                                                                            "technical" -> sitePalette.technicalCategoryBackground.rgb
                                                                                            "tactical" -> sitePalette.tacticalCategoryBackground.rgb
                                                                                            "goalkeeper" -> sitePalette.goalKeeperCategoryBackground.rgb
                                                                                            else -> sitePalette.textErrorColor.rgb
                                                                                        }
                                                                                    )
                                                                                    .borderRadius(7.px)
                                                                                    .padding(
                                                                                        leftRight = 8.px,
                                                                                        topBottom = 4.px
                                                                                    )
                                                                                    .border(
                                                                                        1.px,
                                                                                        color =
                                                                                        when (keywordCategory) {
                                                                                            "fitness" -> sitePalette.fitnessCategoryBorder.rgb
                                                                                            "mental" -> sitePalette.mentalCategoryBorder.rgb
                                                                                            "technical" -> sitePalette.technicalCategoryBorder.rgb
                                                                                            "tactical" -> sitePalette.tacticalCategoryBorder.rgb
                                                                                            "goalkeeper" -> sitePalette.goalKeeperCategoryBorder.rgb
                                                                                            else -> sitePalette.textErrorColor.rgb
                                                                                        },
                                                                                        style = LineStyle.Solid
                                                                                    )
                                                                                    .margin(7.px),
                                                                                contentAlignment = Alignment.Center
                                                                            ) {
                                                                                FieldTitleTextNormalSimple(
                                                                                    keywordText
                                                                                )
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                // Tag Player
                                                                FieldTitleTextNormalSimple(
                                                                    "Tag Player",
                                                                    fontFamily = Constants.IBM_FONT,
                                                                    fontSize = 12.px,
                                                                    fontWeight = 600,
                                                                    marginBottom = 10.px,
                                                                    marginTop = 10.px
                                                                )
                                                                PlayerTokenSearch(
                                                                    sitePalette = sitePalette,
                                                                    breakpoint = breakpoint,
                                                                    players = players,
                                                                    selectedPlayersMap = selectedPlayers,
                                                                    onPlayerSelected = { sentenceId, player ->
                                                                        selectedPlayers =
                                                                            selectedPlayers.toMutableMap()
                                                                                .apply {
                                                                                    put(
                                                                                        sentenceId,
                                                                                        (this[sentenceId]
                                                                                            ?: emptyList()) + player
                                                                                    )
                                                                                }
                                                                    },
                                                                    onPlayerRemoved = { sentenceId, player ->
                                                                        selectedPlayers =
                                                                            selectedPlayers.toMutableMap()
                                                                                .apply {
                                                                                    put(
                                                                                        sentenceId,
                                                                                        (this[sentenceId]
                                                                                            ?: emptyList()) - player
                                                                                    )
                                                                                }
                                                                    },
                                                                    sentenceId = sentenceData.id
                                                                )


                                                            }

                                                        }
                                                        Row(
                                                            modifier = Modifier.fillMaxWidth().padding(leftRight = 20.px)
                                                                .margin(topBottom = 10.px),
                                                            horizontalArrangement = Arrangement.SpaceBetween,
                                                            verticalAlignment = Alignment.CenterVertically
                                                        ) {
                                                            Row {
                                                                FieldTitleTextNormalSimple(
                                                                    text = getEmoji(
                                                                        sentenceData.emotions
                                                                    ) + sentenceData.emotions.replaceFirstChar {
                                                                        if (it.isLowerCase()) it.titlecase() else it.toString()
                                                                    },
                                                                    fontSize = 12.px,
                                                                    marginLeft = 3.px,
                                                                    fontWeight = 500,
                                                                    fontFamily = Constants.IBM_FONT
                                                                )
                                                                FieldTitleTextNormalSimple(
                                                                    text = " . ",
                                                                    fontSize = 12.px,
                                                                    marginLeft = 3.px,
                                                                    fontWeight = 500,
                                                                    fontFamily = Constants.IBM_FONT
                                                                )
                                                                FieldTitleTextNormalSimple(
                                                                    text = getEmoji(
                                                                        sentenceData.tone
                                                                    ) + sentenceData.tone.replaceFirstChar {
                                                                        if (it.isLowerCase()) it.titlecase() else it.toString()
                                                                    },
                                                                    marginLeft = 3.px,
                                                                    fontSize = 12.px,
                                                                    fontWeight = 500,
                                                                    fontFamily = Constants.IBM_FONT
                                                                )
                                                            }
                                                            Row {
                                                                if (expandedIndex == index) {
                                                                    FaChevronUp(
                                                                        modifier = Modifier.color(
                                                                            Colors.Gray
                                                                        )
                                                                            .cursor(Cursor.Pointer)
                                                                            .onClick {
                                                                                expandedIndex =
                                                                                    -1
                                                                            } // Collapse
                                                                    )
                                                                } else {
                                                                    FaChevronDown(
                                                                        modifier = Modifier.color(
                                                                            Colors.Gray
                                                                        )
                                                                            .cursor(Cursor.Pointer)
                                                                            .onClick {
                                                                                expandedIndex =
                                                                                    index
                                                                            } // Expand this item
                                                                    )
                                                                }

                                                            }
                                                        }
                                                        // Expanded Content
                                                        if (expandedIndex == index) {
                                                            FieldTitleTextNormalSimple(
                                                                sentenceData.transcript,
                                                                fontFamily = Constants.IBM_FONT,
                                                                fontSize = 14.px,
                                                                marginBottom = 10.px,
                                                                color = Colors.Gray
                                                            )
                                                        }

                                                        CustomDivider()
                                                    }
                                                }

                                            }
                                    }
                                }
                            }
                        }
                    }
                }
//

                Input(
                    type = InputType.File,
                    attrs = {
                        id(Res.Id.fileInput)
                        style {
                            display(DisplayStyle.None)
                        }
                        onChange { event ->
                            val files = (event.target).files
                            if (files != null && files.length > 0) {
                                val file = files[0]
                                if (file != null) {
                                    val reader = FileReader()
                                    reader.onload = { e ->
                                        val result = e.target?.asDynamic()?.result as? String
                                        if (result != null) {

                                            uploadedVideoUrl = result
                                        }
                                    }
                                    reader.readAsDataURL(file)
                                }
                            }
                        }
                    }
                )
            }
        }
    } else {
        Box(
            modifier = Modifier
                .width(
                    if (breakpoint > Breakpoint.LG) 65.percent else if (breakpoint == Breakpoint.LG) 59.percent
                    else if (breakpoint >= Breakpoint.MD) 49.percent else 1360.px
                )
                .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px)
                .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                .borderRadius(15.px)
                .overflow(
                    overflowY = Overflow.Hidden,
                    overflowX = Overflow.Auto
                )
                .boxShadow(
                    offsetY = 1.px,
                    blurRadius = 3.px,
                    color = sitePalette.boxShadowColor.rgb
                )
                .padding(
                    leftRight = if (breakpoint >= Breakpoint.LG) 30.px else 20.px,
                    topBottom = if (breakpoint >= Breakpoint.LG) 20.px else 10.px
                )
        ) {
            Column(
                modifier = Modifier.width(if (breakpoint <= Breakpoint.LG) 1360.px else 100.percent)
                    .fillMaxHeight()
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    SectionTitle(sessionName.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() })
                }
                Row(
                    modifier = Modifier.fillMaxWidth().gap(36.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
//                    Image(
//                        src = Res.Images.coachPic,
//                        modifier = Modifier.size(30.px).margin(right = 8.px)
//                            .borderRadius(100.px).textAlign(TextAlign.Start)
//                            .border(
//                                width = 2.px,
//                                color = sitePalette.primary.rgb,
//                                style = LineStyle.Solid
//                            )
//                    )
                        FieldTitleTextNormalSimple(
                            text = "Coach:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = sessionData.coachName,
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }

                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(src = Res.Images.timeCircle, modifier = Modifier.size(17.px))
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Duration:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text =  "N/A",
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Image(src = Res.Images.calender, modifier = Modifier.size(17.px))
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Recorded:  ",
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                        FieldTitleTextNormalSimple(
                            text = "N/A",
                            fontWeight = 600,
                            fontFamily = Constants.IBM_FONT,
                            fontSize = 16.px
                        )
                    }
                }

                Row(
                    modifier = Modifier.fillMaxWidth()
                        .height(200.px),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    FieldTitleTextNormalSimple(
                        text = "No data found for this session",
                        color = sitePalette.textFieldTextColor.rgb,
                        fontSize = 16.px,
                        textAlign = TextAlign.Center,
                        width = 100.percent
                    )
                }
            }

        }
    }
}


val emojiMap = mapOf(
    "sad" to "\uD83D\uDE25",       // 😥
    "sadness" to "\uD83D\uDE25",       // 😥
    "frustrated" to "\uD83D\uDE21", // 😡
    "frustration" to "\uD83D\uDE21", // 😡
    "polite" to "\uD83D\uDE0C",    // 😌
    "impolite" to "\uD83D\uDE20",  // 😠
    "sympathetic" to "\uD83E\uDD17", // 🤗
    "satisfied" to "\uD83D\uDE0A", // 😊
    "excited" to "\uD83E\uDD29",   // 🤩
    "Excited" to "\uD83E\uDD29",   // 🤩
    "joy" to "\uD83E\uDD29",   // 🤩
    "positive" to "\uD83D\uDE0A", // 😊
    "negative" to "\uD83D\uDE41", // 🙁
    "neutral" to "\uD83D\uDE10",   // 😐
    "Neutral" to "\uD83D\uDE10",   // 😐
    "fear" to "\uD83D\uDE31",   //
    "informative" to "\uD83E\uDD13",   //
)

fun getEmoji(className: String): String {
    return emojiMap[className.lowercase()] ?: "\uD83D\uDE42" // Default to  if no match
}

