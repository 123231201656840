package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetClubCoachesResponse(
    val data: List<GetClubCoachesData>
)

@Serializable
data class GetClubCoachesData(
    val count: Int = 0,
    val emphais: String = "",
    val firstName: String = "",
    val imageUrl: String = "",
    val lastName: String = "",
    val recordTime: Int = 0,
    val speakingTime: Double = 0.0,
    val totalKeywords: Int = 0,
    var efficiency: Double = 0.0,
    @SerialName("userID")
    val userId: String = "",
)