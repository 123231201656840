package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetClubCoachesListResponse(
    val data: List<GetClubCoachesListData>,
)
@Serializable
data class GetClubCoachesListData(
    @SerialName("_id")
    val id: String = "",
    @SerialName("_rev")
    val rev: String = "",
    val dob: String = "",
    val email: String = "",
    val fcmToken: String = "",
    val firstName: String = "",
    val imageUrl: String = "",
    val isVerified: String = "",
    val job: String = "",
    val lastName: String = "",
    val organization: String = "",
    val role: String = "",
    val type: String = "",
    val uid: String = "",
    val verificationCode: String = "",
    val level: String? = "",
    val nationality: String? = "",
    val organization1: String? = "",
)