package org.example.zone360.style

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import org.example.zone360.theme.Palette

val DropdownStyleLight = CssStyle {
    hover {
        Modifier.backgroundColor(Palette.EditTextBorder.rgbLight)
    }
}
val DropdownStyleDark = CssStyle {
    hover {
        Modifier.backgroundColor(Palette.Primary.rgbDark)
    }
}