package org.example.zone360.pages.players_Details.playerRecentAssessment

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.left
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.top
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.HorizontalDivider
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import getPlayerAssessment
import org.example.zone360.components.CustomDivider
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.components.formatSessionDate
import org.example.zone360.components.formatSessionHours
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetSessionsData
import org.example.zone360.models.SessionDataDetailsResponse
import org.example.zone360.theme.toSitePalette
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun PlayersRecommendation(
    onDialogDismiss: (Long) -> Unit,
    breakpoint: Breakpoint,
    modalNumber: Long,
    data: GetSessionsData,
    userId: String
) {
    val scope = rememberCoroutineScope()
    val sitePalette = ColorMode.current.toSitePalette()
    val sessionSummary = remember { mutableStateListOf<GetSessionsData>() }
    var isLoading by remember { mutableStateOf(true) }
    val sessionObj = JsonConfig.default.decodeFromString<SessionDataDetailsResponse>(
        data.sessionData
    )
    val summaryData = sessionObj.response.summary

    LaunchedEffect(Unit) {
        try {
            isLoading = true
            val getSessionsResponse = getPlayerAssessment(
                playerID = userId,
                domainType = "soccer",
                language = "en"
            )

            if (getSessionsResponse.data.isNotEmpty()) {
                sessionSummary.clear()
                // Filter the sessions to only include ones from the current coach
                val filteredSessions = getSessionsResponse.data.filter { it.coachName == data.coachName }
                sessionSummary.addAll(filteredSessions)
            } else {
                println("No session data received from API.")
            }
        } catch (e: Exception) {
            println("Error fetching session data: ${e.message}")
        } finally {
            isLoading = false
        }
    }

    Column(
        modifier = Modifier.fillMaxSize(),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(
            modifier = Modifier
                .width(if (breakpoint > Breakpoint.LG) 50.percent else 75.percent)
                .height(529.px)
                .position(Position.Fixed)
                .id("recommendation-modal-$modalNumber")
                .zIndex(999999)
                .top(50.percent)
                .left(50.percent)
                .transform { translate((-50).percent, (-50).percent) },
            contentAlignment = Alignment.TopCenter,
        ) {
            Box(
                modifier = Modifier
                    .width(90.percent)
                    .height(90.percent)
                    .onClick { }
            )
            Box(
                modifier = Modifier
                    .padding(all = 24.px)
                    .width(90.percent)
                    .margin(top = 10.px)
                    .height(90.percent)
                    .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                    .border(
                        width = 1.px,
                        color = sitePalette.textFieldBorderColor.rgb,
                        style = LineStyle.Solid
                    )
                    .borderRadius(16.px)
            ) {
                Column(modifier = Modifier.fillMaxSize()) {

                    Row(
                        horizontalArrangement = Arrangement.SpaceBetween,
                        modifier = Modifier
                            .fillMaxWidth()
                            .margin(bottom = 20.px),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Column {
                            FieldTitleTextNormalSimple(
                                "Recommendation",
                                fontSize = 15.px,
                                fontWeight = 600,
                                onClick = {},
                                color = Colors.Black
                            )
                        }

                        FaXmark(
                            modifier = Modifier
                                .color(sitePalette.commonButtonSupportiveTextColor.rgb)
                                .cursor(Cursor.Pointer)
                                .onClick { onDialogDismiss(modalNumber) },
                            size = IconSize.LG
                        )
                    }

                    FieldTitleTextNormalSimple(
                        data.coachName,
                        fontSize = 24.px,
                        fontWeight = 600,
                        onClick = {},
                        color = Colors.Black
                    )

                    if (isLoading) {
                        Box(
                            modifier = Modifier.fillMaxSize(),
                            contentAlignment = Alignment.Center
                        ) {
                            ProgressIndicator(size = 40.px)
                        }
                    } else if (sessionSummary.isEmpty()) {
                        Box(
                            modifier = Modifier.fillMaxSize(),
                            contentAlignment = Alignment.Center
                        ) {
                            FieldTitleTextNormalSimple(
                                text = "No summary available for this coach",
                                fontSize = 14.px,
                            )
                        }
                    } else {
                        Column(
                            modifier = Modifier
                                .margin(topBottom = 7.px)
                                .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                        ) {

                                if (summaryData.isNotEmpty()) {
                                    val parts = data.sessionId.split(".")
                                    val dataParts = parts.first().split("_")
                                    val timeStamp = dataParts.last()

                                    Row(
                                        modifier = Modifier.fillMaxWidth()
                                            .margin(topBottom = 6.px, leftRight = 0.px),
                                        verticalAlignment = Alignment.CenterVertically,
                                        horizontalArrangement = Arrangement.SpaceBetween
                                    ) {
                                        FieldTitleTextNormalSimple(
                                            text = "${data.playerName}'s assessment",
                                            fontSize = 20.px,
                                            fontWeight = 500,
                                        )
                                        Row {
                                            FieldTitleTextNormalSimple(
                                                text = timeStamp.formatSessionHours(),
                                                fontSize = 14.px,
                                            )
                                            FieldTitleTextNormalSimple(
                                                text = " ${timeStamp.formatSessionDate()}",
                                                fontSize = 14.px,
                                            )
                                        }
                                    }

                                    Column {
                                        summaryData.split("\n").forEach{ line ->
                                            FieldTitleTextNormalSimple(
                                                text = line,
                                                fontSize = 13.px,
                                            )
                                        }
                                    }
                                    HorizontalDivider(modifier = Modifier.margin(topBottom = 10.px).fillMaxWidth()  )
                                }
                            }
                        }
                    }
                }
            }
        }
    }

