package org.example.zone360.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input

@Composable
fun AudioProgressSliderCustom(
    progress: Float, // Current audio progress as a percentage (0.0 to 1.0)
    onProgressChange: (Float) -> Unit, // Called when the user drags the slider
) {
    // Create a Slider UI element
    Input(
        type = InputType.Range,
        attrs = Modifier.height(2.px).toAttrs {
            attr("value", (progress * 100).toString()) // Multiply by 100 for percentage
            attr("min", "0")
            attr("max", "100")
            attr("step", "1")
            onInput {
                // Handle slider input change
                val newProgress = it.value?.toFloat()
                println("newProgress: $newProgress")
                if (newProgress != null) {
                    // Update the progress based on the slider position
                    onProgressChange(newProgress / 100) // Convert back to 0.0 - 1.0 range
                }
            }
        },
    )
}

@Composable
fun VideoProgressSliderCustom(
    progress: Float,
    onProgressChange: (Float) -> Unit,
    modifier: Modifier = Modifier,
) {
    Input(
        type = InputType.Range,
        attrs = modifier
            .height(2.px)
            .styleModifier {
                property("appearance", "none")
                property("background", "#e0e0e0")
                property("cursor", "pointer")

                property("&::-webkit-slider-thumb", """
                        -webkit-appearance: none !important;
                        appearance: none !important;
                        width: 15px;
                        height: 15px;
                        background: #FF0000 !important;
                        border-radius: 50%;
                        cursor: pointer;
                        border: none;
                    """.trimIndent())
            }
            .toAttrs {
                attr("value", (progress * 100).toString())
                attr("min", "0")
                attr("max", "100")
                attr("step", "1")
                onInput {
                    val newProgress = it.value?.toFloat()
                    if (newProgress != null) {
                        onProgressChange(newProgress / 100)
                    }
                }
            }
    )
}


