package org.example.zone360.pages.players_Details.assistAI

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import chatBot
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.flexWrap
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import kotlinx.browser.document
import kotlinx.coroutines.launch
import org.example.zone360.components.CustomDivider
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.ProgressIndicator
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.ChatBotMessage
import org.example.zone360.models.GetSinglePlayerData
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.example.zone360.utils.SelectedLanguage
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.outline
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement

@Composable
fun AssistAI(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    selectedSessionType: SelectedData,
    selectedLanguage: SelectedLanguage,
    getPlayerInfo: GetSinglePlayerData) {
    val scope = rememberCoroutineScope()
    var userInput by remember { mutableStateOf("") }
    var chatbotResponse by remember { mutableStateOf("") }
    var isLoading by remember { mutableStateOf(false) }
    val getChatBotMessage = remember { mutableStateListOf<ChatBotMessage>() }
    val apiLanguageType = when (selectedLanguage) {
        SelectedLanguage.English -> "en"
        SelectedLanguage.Italian -> "it"
    }
    val apiSessionType = when (selectedSessionType) {
        SelectedData.Matches -> "match"
        SelectedData.Training -> "training"
    }

    val quickActions = listOf(
        "Season Summary",
        "Trend Analysis",
        "Top Skills",
        "Defensive Stats",
        "Teamwork"
    )
    scope.launch {
        userInput = (document.getElementById(Res.Id.messageAiInput) as HTMLInputElement).value
    }
    val text = getPlayerInfo.latestAssessment.trimIndent()
    val singleParagraph = text.lines()
        .map { it.trim() }
        .filter { it.isNotEmpty() }
        .joinToString(" ")

    LaunchedEffect(getChatBotMessage.size) {
        val container = document.getElementById(Res.Id.scrollContainerId) as? HTMLElement
        container?.scrollTop = container?.scrollHeight!!.toDouble()
    }
    Box(modifier = Modifier
        .height(745.px)
        .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px)
        .fillMaxWidth()
        .backgroundColor(sitePalette.modalBackgroundColor.rgb)
        .boxShadow(
            offsetY = 1.px,
            blurRadius = 3.px,
            color = sitePalette.boxShadowColor.rgb
        )
        .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)
        .borderRadius(15.px)
        .padding(topBottom = 16.px, leftRight = 24.px)
    ) {
        Column(modifier = Modifier.fillMaxSize()) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                Image(
                    src = Res.Images.assistAi,
                    modifier = Modifier.size(52.px).borderRadius(100.px)
                )
                CustomSpacer(20.px)
                Column {
                    FieldTitleTextNormalSimple(
                        text = "AssistAI",
                        fontWeight = 600,
                        fontSize = 20.px
                    )
                    CustomSpacer(height = 4.px)
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Box(
                            modifier = Modifier.width(6.px).height(6.px).borderRadius(100.percent)
                                .backgroundColor(Palette.GreenShadeColor.rgbLight)
                        )
                        FieldTitleTextNormalSimple(
                            text = "Online",
                            fontWeight = 500,
                            fontSize = 14.px,
                            color = Palette.GreenShadeColor.rgbLight,
                            marginLeft = 5.px
                        )
                    }
                }
            }

            CustomSpacer(height = 15.px)
            CustomDivider()
            CustomSpacer(height = 15.px)

            Column(modifier = Modifier.fillMaxWidth()
                .id(Res.Id.scrollContainerId)
                .height(365.px)
                .overflow(Overflow.Auto)) {
                getChatBotMessage.forEach {
                    Row(
                        modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                        horizontalArrangement = if (it.isUserMessage) Arrangement.End else Arrangement.Start
                    ) {
                        if (!it.isUserMessage) {
                            Image(
                                src = Res.Images.assistAi,
                                modifier = Modifier.size(30.px).borderRadius(100.px)
                            )
                            CustomSpacer(width = 10.px)
                        }
                        Box(
                            modifier = Modifier.maxWidth(80.percent)
                                .backgroundColor(if (it.isUserMessage)sitePalette.lightGrey.rgb else Colors.Transparent)
                                .borderRadius(8.px)
                                .padding(8.px)
                        ) {
                            FieldTitleTextNormalSimple(
                                text = it.message,
                                width = 100.percent,
                                whiteSpace = WhiteSpace.PreWrap,
                                fontSize = 16.px,
                                fontFamily = Constants.IBM_FONT
                            )
                        }
                    }

                }
            }

            CustomSpacer(height = 15.px)
            CustomDivider()
            CustomSpacer(height = 15.px)

            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween) {
                FieldTitleTextNormalSimple(
                    text = "Quick Actions",
                    fontSize = 16.px,
                    fontWeight = 500
                )

                Row(modifier = Modifier.cursor(Cursor.Pointer),
                    verticalAlignment = Alignment.CenterVertically) {
                    Image(src = Res.Images.connect_Device, modifier = Modifier.height(24.px).width(24.px))
                    FieldTitleTextNormalSimple(
                        text = "Connect your Device",
                        fontSize = 14.px,
                        fontWeight = 500,
                        color = sitePalette.primary.rgb,
                        marginLeft = 2.px
                    )
                }
            }

            Row(modifier = Modifier.fillMaxWidth()
                .margin(topBottom = 10.px)
                .gap(10.px)
                .flexWrap(FlexWrap.Wrap)) {
                quickActions.forEach { title ->
                    Box(
                        modifier = Modifier
                            .height(36.px)
                            .backgroundColor(sitePalette.lightGrey.rgb)
                            .borderRadius(4.px)
                            .cursor(if (isLoading) Cursor.NotAllowed else Cursor.Pointer)
                            .padding(topBottom = 8.px, leftRight = 16.px)
                            .onClick {
                                if (!isLoading) {
                                    scope.launch {
                                        try {
                                            isLoading = true // Set loading state
                                            getChatBotMessage.add(ChatBotMessage(message = title, isUserMessage = true))
                                            if (title.isNotEmpty()) {
                                                chatBot(
                                                    text = singleParagraph,
                                                    domain_type = "soccer",
                                                    session_type = apiSessionType,
                                                    language = apiLanguageType,
                                                    query = title
                                                ).let {
                                                    chatbotResponse = it.response.trim()
                                                    getChatBotMessage.add(ChatBotMessage(message = chatbotResponse))
                                                }
                                            }
                                        } catch (e: Exception) {
                                            println("Error: ${e.message}")
                                        } finally {
                                            isLoading = false // Reset loading state
                                        }
                                    }
                                }
                            }
                            .border(
                                1.px,
                                style = LineStyle.Solid,
                                color = sitePalette.coachDetailsBoxBorder.rgb
                            ),
                        contentAlignment = Alignment.Center
                    ) {
                        FieldTitleTextNormalSimple(
                            title,
                            fontSize = 14.px,
                            fontWeight = 500,
                            isClickable = !isLoading,
                            isNotAllowed = isLoading
                        )
                    }
                }
            }

            Box(modifier = Modifier.fillMaxWidth().height(90.px)
                .borderRadius(8.px)
                .padding(topBottom = 8.px, leftRight = 16.px)
                .backgroundColor(sitePalette.lightGrey.rgb)
                .border(1.px, style = LineStyle.Solid, color = sitePalette.coachDetailsBoxBorder.rgb)) {
                Column(modifier = Modifier.fillMaxSize()) {
                    Input(
                        type = InputType.Text,
                        attrs = Modifier.fillMaxWidth()
                            .height(30.px)
                            .border(0.px)
                            .backgroundColor(Colors.Transparent)
                            .toAttrs {
                                placeholder("Message AssistAI")
                                id(Res.Id.messageAiInput)
                                style {
                                    outline("none")
                                }
                                attr("autocomplete", "off")
                            }
                    )
                    Row(modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.End) {
                        if (isLoading) {
                            Box (modifier = Modifier.size(30.px)
                                .borderRadius(100.percent)
                                .backgroundColor(sitePalette.primary.rgb),
                                contentAlignment = Alignment.Center){
                                ProgressIndicator(20.px)
                            }
                        }
                        else
                            Image(
                                src = Res.Images.send_Button,
                                modifier = Modifier
                                    .cursor(Cursor.Pointer)
                                    .onClick {
                                        scope.launch {
                                            try {
                                                isLoading = true
                                                val inputField = document.getElementById(Res.Id.messageAiInput) as HTMLInputElement
                                                val message = inputField.value
                                                getChatBotMessage.add(ChatBotMessage(message = message, isUserMessage = true))
                                                if (message.isNotEmpty()) {
                                                    chatBot(
                                                        text = singleParagraph,
                                                        domain_type = "soccer",
                                                        session_type = apiSessionType,
                                                        language = apiLanguageType,
                                                        query = message
                                                    ).let {
                                                        chatbotResponse = it.response.trim()
                                                        getChatBotMessage.add(ChatBotMessage(message = chatbotResponse))
                                                        for (i in getChatBotMessage) {
                                                            println("chat bot response ${i.message}")
                                                        }
                                                    }

                                                    inputField.value = ""
                                                }
                                            } catch (e: Exception) {
                                                isLoading = false
                                                println("Error: ${e.message}")
                                            } finally {
                                                isLoading = false
                                            }
                                        }
                                    }
                            )

                    }
                }
            }
        }
    }
}
