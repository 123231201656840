@file:Suppress("DEPRECATION")

package org.example.zone360.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.alignContent
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.GeneralKind
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.MenuModel
import org.example.zone360.style.DropdownStyleDark
import org.example.zone360.style.DropdownStyleLight
import org.example.zone360.theme.Palette
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants.FONT_FAMILY
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Ul

@Composable
fun MenuDropdown(
    data: List<MenuModel>,
    title: String? = null,
    textSpanColor: Color? = null,
    icon: (@Composable () -> Unit)? = null,
    onSelect: (String) -> Unit

) {
    val sitePalette = ColorMode.current.toSitePalette()
    val resolvedTextColor = textSpanColor ?: sitePalette.textFieldTextColor.rgb

    Box(
        modifier = Modifier
            .id("menuDropDown-$title")
            .classNames("dropdown")
            .fillMaxWidth().alignContent(AlignContent.Center)
            .color(Palette.White.rgbLight)
            .cursor(Cursor.Pointer)
            .attrsModifier {
                attr("data-bs-toggle", "dropdown")

            },

    ) {
        Box(modifier = Modifier.zIndex(0)) {
            icon?.let {
                it()
                CustomSpacer(width = 10.px)
            }
            FieldTitleTextNormalSimple(
                text = title ?: "",
                color = resolvedTextColor,
                onClick = {},
                fontSize = 14.px
            )
        }
        Box(modifier = Modifier.zIndex(999)) {
            Ul(
                attrs = Modifier
                    .maxWidth(250.px).id("leaderboardDropDown-$title")
                    .classNames("dropdown-menu")
                    .backgroundColor(sitePalette.modalBackgroundColor.rgb)
                    .toAttrs()
            ) {
                val colorMode by ColorMode.currentState
                val modifier =
                    if (colorMode == ColorMode.LIGHT) DropdownStyleLight else DropdownStyleDark

                data.forEach { item ->
                    Li {
                        menuItem(
                            text = item.text,
                            color = sitePalette.textFieldTextColor.rgb,
                            modifier = modifier
                        ) {
                            onSelect(item.text)

                        }
                    }
                }
            }
        }
    }
}

@Composable
fun menuItem(
    modifier: CssStyle<GeneralKind>,
    color: Color.Rgb,
    text: String,
    onSelect: (String) -> Unit
) {

    A(
        attrs = modifier.toModifier().margin(topBottom = 5.px)
            .classNames("dropdown-item")
            .fontFamily(FONT_FAMILY)
            .fontSize(16.px)
            .onClick { onSelect("") }
            .toAttrs()
    ) {

        SpanText(
            text = text,
            modifier = Modifier
                .fillMaxWidth()
                .color(color)
                .fontSize(13.px).fontWeight(400)
                .fontFamily(FONT_FAMILY)
        )

    }
}


