package org.example.zone360.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.icons.fa.FaPallet
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.example.zone360.theme.Palette
import org.example.zone360.theme.toSitePalette
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun CustomDivider(
    modifier: Modifier = Modifier,
    width: CSSLengthOrPercentageNumericValue = 100.percent,
    height: CSSLengthOrPercentageNumericValue = 1.px,
    color: com.varabyte.kobweb.compose.ui.graphics.Color? = null
){
    val sitePalette = ColorMode.current.toSitePalette()
    Box(modifier = modifier.width(width).height(height).backgroundColor(color ?: Colors.LightGray))
}