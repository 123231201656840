package org.example.zone360.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import kotlinx.browser.localStorage
import org.example.zone360.pages.authentication.LoginPage
import org.example.zone360.pages.dashboard.DashboardPage
import org.example.zone360.pages.players_Details.PlayersDetails
import org.example.zone360.utils.AppGlobal

@Page
@Composable
fun HomePage() {
//    UploadVideoComponent()
    val data = localStorage.getItem("userData")
    if (data != null)
    {if (AppGlobal.userData.role == "player") PlayersDetails() else DashboardPage() }
    else LoginPage()
}


