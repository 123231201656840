package org.example.zone360.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetSentencesResponse(
    val data: List<GetSentencesData>,
)
@Serializable
data class GetSentencesData(
    @SerialName("_id")
    val id: String = "",
    @SerialName("_rev")
    val rev: String = "",
    val domainType: String = "",
    val endTime: Double = 0.0,
    val keywords: List<Keyword> ? = emptyList(),
    val language: String = "",
    val organization: String = "",
    val sessionDateTimes: String = "",
    @SerialName("sessionID")
    val sessionId: String = "",
    val sessionType: String = "",
    val startTime: Double = 0.0,
    val totalTime: Double = 0.0,
    val transcript: String = "",
    val tagPlayers: List<String>?=emptyList(),
    val type: String = "",
    @SerialName("userID")
    val userId: String = "",
    val videoFile: String = "Screen Recording 2024-10-22 at 7.45.45PM.mov",
    val wordCount: Int = 0,
    val emotions: String = "",
    val sentiment: String = "",
    val tone: String = "",
)
@Serializable
data class Keyword(
    val domainType: String = "",
    val keyWordType: String = "",
    val keywordCategory: String = "",
    val language: String = "",
    val organization: String = "",
    val sessionDateTime: String = "",
    @SerialName("sessionID")
    val sessionId: String = "",
    val text: String = "",
    val usageCount: Int,
    @SerialName("userID")
    val userId: String = "",
)
