@file:Suppress("UNREACHABLE_CODE")

import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.example.zone360.models.CommonDataResponse
import org.example.zone360.models.CommonResponse
import org.example.zone360.models.DeleteClubPlayer
import org.example.zone360.models.GetAllTeamsResponse
import org.example.zone360.models.GetClubCoachesListResponse
import org.example.zone360.models.GetClubCoachesResponse
import org.example.zone360.models.GetClubKeywordResponse
import org.example.zone360.models.GetClubPerformanceResponse
import org.example.zone360.models.GetClubPlayerAssessmentsResponse
import org.example.zone360.models.GetClubPlayerResponse
import org.example.zone360.models.GetPlayerResponse
import org.example.zone360.models.GetProfileResponse
import org.example.zone360.models.GetSentencesResponse
import org.example.zone360.models.GetSessionSummaryResponse
import org.example.zone360.models.GetSessionsResponse
import org.example.zone360.models.GetSingleCoachResponse
import org.example.zone360.models.GetSinglePlayerResponse
import org.example.zone360.models.SessionResponse
import org.example.zone360.models.UpdateUserDataResponse
import org.example.zone360.models.UserData
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit

enum class APIMETHODS { GET, POST }
var accessToken: String? = null


fun saveAccessToken(token: String) {
    window.localStorage.setItem("accessToken", token)
}

fun loadAccessToken(): String? {
    return window.localStorage.getItem("accessToken")?.takeIf { it.isNotEmpty() }
}


suspend fun httpRequest(
    url: String,
    method: APIMETHODS,
    body: ByteArray? = null,
    headers: Map<String, String>? = null
): String? {
    return try {
        // Convert ByteArray to String if present
        val bodyString = body?.decodeToString()

        val requestInit = RequestInit(
            method = method.name, // Use HTTP method
            headers = Headers().apply {
                headers?.forEach { (key, value) -> append(key, value) }
            },
            body = bodyString?.takeIf { method != APIMETHODS.GET } // Only include body if not GET
        )

        // Make the request
        val response = window.fetch(url, requestInit).await()

        // Handle unauthorized status
        if (response.status == 401.toShort()) {
            redirectToLogin()
            return null
        }

        // Throw an error for non-success responses
        if (!response.ok) {
            throw IllegalStateException("HTTP error: ${response.status}")
        }

        // Return response text
        response.text().await()

    } catch (e: Exception) {
        console.error("HTTP request failed: ${e.message}")
        null
    }
}

fun redirectToLogin() {
    console.log("Redirecting to login page due to 401 Unauthorized.")
    window.location.href = "/login"
}

fun getHeaders(): Map<String, String> {
    return loadAccessToken()?.let {
        mapOf("Authorization" to "Bearer $it")
    } ?: emptyMap()
}


suspend fun login(email: String, password: String): UserData {
    val jsonData = """{
        "email": "$email",
        "password": "$password"
    }"""

    val response: UserData = httpRequest(
        APIEndPoints.Name.login,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray()
    ).parseData()

    accessToken = response.accessToken
    // Save the token to localStorage
    accessToken?.let { saveAccessToken(it) }

    return response
}

suspend fun getClubSession(organization: String, domainType: String, language: String, sessionType: String): SessionResponse {
    val jsonData = """{
        "organization": "$organization",
        "sessionType": "$sessionType",
        "domainType": "$domainType",
        "language": "$language"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Club_session,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getClubKeyword(organization: String, domainType: String,
                           sessionType: String,
                           language: String, keyword: String,
                           getSentences: Boolean): GetClubKeywordResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "sessionType": "$sessionType",
        "language": "$language",
        "keyword": "$keyword",
        "getSentences": $getSentences
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Club_Keyword,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
    println("HTTP Response: $jsonData")
}

suspend fun getClubCoaches(organization: String, domainType: String, sessionType: String, language: String): GetClubCoachesResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "sessionType": "$sessionType",
        "language": "$language"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Club_Coaches,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}


suspend fun getSessionSummary(userID: String, domainType: String, language: String, getAll: Boolean, isPlayer: Boolean, sessionType: String): GetSessionSummaryResponse {
    val jsonData = """{
        "userID": "$userID",
        "domainType": "$domainType",
        "language": "$language",
        "getAll": $getAll,
        "isPlayer": $isPlayer,
        "sessionType": "$sessionType"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Session_Summary,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
    println("HTTP Response: $jsonData")
}
suspend fun getPlayerAggregate(organization: String, domainType: String, language: String): GetPlayerResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "language": "$language"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Player_Aggregate,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun sendEmail(playerName: String, coachName: String, toEmail: String, sessionID: String): CommonResponse {
    val jsonData = """{
        "playerName": "$playerName",
        "coachName": "$coachName",
        "toEmail": "$toEmail",
        "sessionID": "$sessionID"
    }"""

    return httpRequest(
        APIEndPoints.Name.send_Email,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun createPlayer(email: String, firstName: String, lastName: String, imageUrl: String,
                         organization: String,  coachName: String, team: String): CommonResponse {
    val jsonData = """{
        "email": "$email",
        "firstName": "$firstName",
        "lastName": "$lastName",
        "imageUrl": "$imageUrl",
        "organization": "$organization",
        "coachName": "$coachName",
        "team": "$team"
    }"""

    return httpRequest(
        APIEndPoints.Name.create_player,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun getClubPerformance(organization: String, domainType: String, language: String, sessionType: String): GetClubPerformanceResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "language": "$language",
        "sessionType": "$sessionType"
    }"""

    return httpRequest(
        APIEndPoints.Name.club_performance,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
    suspend fun createCoach(email: String, firstName: String, lastName: String, imageUrl: String,  organization: String, teams: List<String>): CommonResponse {
        val jsonData = """{
            "email": "$email",
            "firstName": "$firstName",
            "lastName": "$lastName",
            "imageUrl": "$imageUrl",
            "organization": "$organization",
            "teams": ${Json.encodeToString(teams)}
        }"""

        return httpRequest(
            APIEndPoints.Name.create_Coach,
            APIMETHODS.POST,
            body = jsonData.encodeToByteArray(),
            headers = getHeaders()
        ).parseData()
    }
suspend fun getSessions(sessionID: String, userID: String, domainType: String, language: String,  getAll: Boolean, excludeAssessments: Boolean ): GetSessionsResponse {
    val jsonData = """{
        "sessionID": "$sessionID",
        "userID": "$userID",
        "domainType": "$domainType",
        "language": "$language",
        "getAll": $getAll,
         "excludeAssessments": $excludeAssessments
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Sessions,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun getSentences(sessionID: String, type: String): GetSentencesResponse {
    val jsonData = """{
        "sessionID": "$sessionID",
        "type": "$type"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Sentences,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getClubPlayer(organization: String,): GetClubPlayerResponse {
    val jsonData = """{
        "organization": "$organization"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Club_Players,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun getClubCoachesList(organization: String): GetClubCoachesListResponse {
    val jsonData = """{
        "organization": "$organization"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_Club_Coaches_List,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun tagPlayers(playerID: String, sentenceID: String, isRemove : Boolean): CommonResponse {
    val jsonData = """{
        "playerID": "$playerID",
        "sentenceID": "$sentenceID",
        "isRemove": $isRemove
    }"""

    return httpRequest(
        APIEndPoints.Name.tag_Player,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun updateUserData(jsonData:String): UpdateUserDataResponse {

    return httpRequest(
        APIEndPoints.Name.update_UserData,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun getProfile(userID: String): GetProfileResponse {
    val jsonData = """{
        "userID": "$userID"
    }"""

    return httpRequest(
        APIEndPoints.Name.get_profile,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}
suspend fun getSingleCoach(organization: String, domainType: String, sessionType: String, language: String, userID: String): GetSingleCoachResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "sessionType": "$sessionType",
        "language": "$language",
        "userID": "$userID"
    }"""

    return httpRequest(
        APIEndPoints.Name.getSingleCoach,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun chatBot(text: String, domain_type: String, session_type: String, language: String, query: String): CommonDataResponse {
    val jsonData = """{
        "text": "$text",
        "domain_type": "$domain_type",
        "session_type": "$session_type",
        "language": "$language",
        "query": "$query"
    }"""

    return httpRequest(
        APIEndPoints.Name.chatbot,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getSinglePlayer(domainType: String,language: String, userID: String): GetSinglePlayerResponse {
    val jsonData = """{
        "domainType": "$domainType",
        "language": "$language",
        "userID": "$userID"
    }"""

    return httpRequest(
        APIEndPoints.Name.getSinglePlayer,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getClubPlayerAssessmentAggregate(domainType: String, language: String, playerID: String): GetClubPlayerAssessmentsResponse {
    val jsonData = """{
        "domainType": "$domainType",
        "language": "$language",
        "playerID": "$playerID"
    }"""

    return httpRequest(
        APIEndPoints.Name.getClubPlayerAssessment,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun requestAssessment(coachID: String, coachEmail: String, coachName: String, playerEmail: String, playerName: String): CommonDataResponse {
    val jsonData = """{
        "coachID": "$coachID",
        "coachEmail": "$coachEmail",
         "playerEmail":"$playerEmail",
        "coachName": "$coachName",
        "playerName": "$playerName"
        
    }"""

    return httpRequest(
        APIEndPoints.Name.requestAssessment,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getPlayerAssessment(playerID: String, domainType: String, language: String ): GetSessionsResponse {
    val jsonData = """{
        "playerID": "$playerID",
        "domainType": "$domainType",
        "language": "$language"
    }"""

    return httpRequest(
        APIEndPoints.Name.getPlayerAssessments,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getTagSentences(organization: String, domainType: String, language: String, playerID: String): GetSentencesResponse {
    val jsonData = """{
        "organization": "$organization",
        "domainType": "$domainType",
        "language": "$language",
        "playerID": "$playerID"
    }"""

    return httpRequest(
        APIEndPoints.Name.getTagPlayerSentences,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun getAllTeams(clubName: String): GetAllTeamsResponse {

    return httpRequest(
        "${APIEndPoints.Name.get_All_Teams}?clubName=$clubName",
        APIMETHODS.GET,
        headers = getHeaders()
    ).parseData()
}

suspend fun createClubTeam(clubName: String, teamName: String): CommonResponse {
    val jsonData = """{
        "clubName": "$clubName",
        "teamName": "$teamName" 
    }"""

    return httpRequest(
        APIEndPoints.Name.create_Club_Team,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

suspend fun deleteClubTeam(team_id: String): DeleteClubPlayer {

    return httpRequest(
        "${APIEndPoints.Name.delete_Club_Team}?team_id=$team_id",
        APIMETHODS.GET,
        headers = getHeaders()
    ).parseData()
}

suspend fun updateUserWithTeam(teamName: String, userID: String, isRemove: Boolean): CommonResponse {
    val jsonData = """{
        "teamName": "$teamName",
        "userID": "$userID",
        "isRemove": $isRemove
    }"""

    return httpRequest(
        APIEndPoints.Name.update_User_With_Team,
        APIMETHODS.POST,
        body = jsonData.encodeToByteArray(),
        headers = getHeaders()
    ).parseData()
}

//

object JsonConfig {
    val default: Json = Json {
        ignoreUnknownKeys = true
        coerceInputValues = true
    }
}


inline fun <reified T> String?.parseData(): T {
    if (this == null) throw IllegalArgumentException("Response is null")
    return JsonConfig.default.decodeFromString(this)
}
