package org.example.zone360.pages.coachDetails.coachSession

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaVideo
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import getSessions
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.example.zone360.components.AudioProgressSliderCustom
import org.example.zone360.components.CustomSpacer
import org.example.zone360.components.SearchBar
import org.example.zone360.components.formatSessionDate
import org.example.zone360.components.formatSessionHours
import org.example.zone360.components.formatSessionMinutes
import org.example.zone360.componentss.FieldTitleTextNormalSimple
import org.example.zone360.models.GetSessionsData
import org.example.zone360.pages.dashboard.SectionTitle
import org.example.zone360.theme.Palette
import org.example.zone360.theme.SitePalette
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.AppGlobal
import org.example.zone360.utils.Constants
import org.example.zone360.utils.Divider
import org.example.zone360.utils.Res
import org.example.zone360.utils.SelectedData
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.Audio
import org.w3c.dom.HTMLAnchorElement

@Composable
fun CoachesSession(
    breakpoint: Breakpoint,
    sitePalette: SitePalette,
    sessionDataType: SelectedData,
    userId: String,
    onSessionTypeChange: (SelectedData) -> Unit,
    onSessionSelect: (GetSessionsData) -> Unit,
    updatedSession: GetSessionsData?,
) {
    val scope = rememberCoroutineScope()
    var searchText by remember { mutableStateOf("") }
//    val sessionDataType by remember { mutableStateOf(SelectedData.Training) }
    val getSessions = remember { mutableStateListOf<GetSessionsData>() }
    val sessionSingleData by remember { mutableStateOf(GetSessionsData()) }
    var selectedSession by remember { mutableStateOf(getSessions.firstOrNull()) }

    val filteredData = remember { mutableStateListOf<GetSessionsData>() }

// Update filteredData whenever getSessions changes
    LaunchedEffect(getSessions) {
        if (filteredData.isEmpty() && getSessions.isNotEmpty()) {
            filteredData.clear()
            filteredData.addAll(getSessions)
        }
    }

// Update filteredData when searchText changes
    LaunchedEffect(searchText) {
        filteredData.clear()
        filteredData.addAll(
            if (searchText.isEmpty()) getSessions
            else getSessions.filter { it.sessionId.contains(searchText, ignoreCase = true) }
        )
    }

    println("updatedSession: $updatedSession")
    scope.launch {
        if (updatedSession != null) {
            val filData = filteredData.filter { it.sessionId == updatedSession.sessionId }
            val index = filteredData.indexOf(filData.first())
            filteredData[index] = updatedSession

            println("filteredData: ${filteredData[index]}")

        }
    }

    scope.launch {
        if (getSessions.isNotEmpty()) {
            val dt =
                getSessions.filter { it.sessionType == if (sessionDataType.name.lowercase() == SelectedData.Matches.name.lowercase()) "match" else "training" }
            if (dt.isNotEmpty()) {
                (if (selectedSession == null) dt.first() else if (selectedSession?.sessionType == if (sessionDataType.name.lowercase() == SelectedData.Matches.name.lowercase()) "match" else "training") selectedSession else dt.first())?.let {
                    onSessionSelect(
                        it
                    )
                }
                selectedSession =
                    if (selectedSession == null) dt.first() else if (selectedSession?.sessionType == if (sessionDataType.name.lowercase() == SelectedData.Matches.name.lowercase()) "match" else "training") selectedSession else dt.first()

            }
        }
    }
// Fetch session data and populate getSessions
    LaunchedEffect(Unit) {
        val getSessionsResponse = getSessions(
            sessionID = sessionSingleData.sessionId,
            userID = userId,
            domainType = "soccer",
            language = "en",
            getAll = true,
            excludeAssessments = true
        )
        val sortedData = getSessionsResponse.data.sortedByDescending { sessionData ->
            sessionData.sessionDateTime
        }

        getSessions.clear()
        getSessions.addAll(sortedData)

        // **Ensure filteredData is updated immediately**
        filteredData.clear()
        filteredData.addAll(sortedData)

        if (getSessions.isNotEmpty()) {
            val dt = getSessions.filter {
                it.sessionType == if (sessionDataType.name.lowercase() == "matches") "match" else "training"
            }
            if (dt.isNotEmpty()) {
                onSessionSelect(dt.first())
                selectedSession = dt.first()
            }
        }
    }
    Box(
        modifier = Modifier.height(900.px)
            .margin(topBottom = if (breakpoint <= Breakpoint.MD) 10.px else 0.px).width(
                if (breakpoint > Breakpoint.LG) 33.percent
                else if (breakpoint >= Breakpoint.LG) 39.percent
                else if (breakpoint >= Breakpoint.MD) 49.percent
                else 100.percent
            ).backgroundColor(sitePalette.modalBackgroundColor.rgb).borderRadius(15.px)
            .padding(20.px)
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            SectionTitle("Sessions")
            Row(
                modifier = Modifier.fillMaxWidth().height(42.px).margin(topBottom = 10.px)
                    .borderRadius(6.px).backgroundColor(sitePalette.lightGrey.rgb)
                    .padding(leftRight = 5.px, topBottom = 4.px).border(
                        width = 1.px, style = LineStyle.Solid, color = Colors.LightGray
                    ), verticalAlignment = Alignment.CenterVertically
            ) {
                Box(
                    modifier = Modifier.fillMaxWidth(50.percent).height(34.px).borderRadius(4.px)
                        .padding(leftRight = 12.px, topBottom = 6.px).cursor(Cursor.Pointer)
                        .backgroundColor(if (sessionDataType == SelectedData.Training) Palette.White.rgbLight else Colors.Transparent)
                        .onClick { onSessionTypeChange(SelectedData.Training) }.boxShadow(
                            offsetX = 0.px,
                            offsetY = if (sessionDataType == SelectedData.Training) 2.px else 0.px,
                            blurRadius = if (sessionDataType == SelectedData.Training) 16.px else 0.px,
                            spreadRadius = null,
                            color = sitePalette.boxShadowColor.rgb
                        ), contentAlignment = Alignment.Center
                ) {
                    FieldTitleTextNormalSimple(
                        text = "Training",
                        textAlign = TextAlign.Center,
                        fontWeight = 500,
                        fontSize = 16.px,
                        isClickable = true
                    )
                }
                Box(
                    modifier = Modifier.fillMaxWidth(50.percent).height(34.px).borderRadius(4.px)
                        .padding(leftRight = 12.px, topBottom = 6.px).cursor(Cursor.Pointer)
                        .backgroundColor(if (sessionDataType != SelectedData.Training) Palette.White.rgbLight else Colors.Transparent)
                        .onClick {
                            onSessionTypeChange(SelectedData.Matches)
                        }.boxShadow(
                            offsetX = 0.px,
                            offsetY = if (sessionDataType == SelectedData.Matches) 2.px else 0.px,
                            blurRadius = if (sessionDataType == SelectedData.Matches) 16.px else 0.px,
                            spreadRadius = null,
                            color = sitePalette.boxShadowColor.rgb
                        ), contentAlignment = Alignment.Center
                ) {
                    FieldTitleTextNormalSimple(
                        text = "Matches",
                        textAlign = TextAlign.Center,
                        fontWeight = 500,
                        fontSize = 16.px,
                        isClickable = true
                    )
                }

            }
            SearchBar(
                placeholder = "Search...",
                id = "session_Searchbar",
                breakpoint = Breakpoint.LG,
                onEnterClick = {},
                onChange = { newSearchText ->
                    searchText = newSearchText
                },
                onClick = {},
                width = 100.percent,
                showClearButton = false,
                height = 48.px,
                borderColor = Colors.Transparent
            )

            Column(
                modifier = Modifier.fillMaxWidth().margin(topBottom = 15.px)
                    .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
            ) {
                println("filteredData: ${filteredData.size}")
                if (filteredData.isNotEmpty()) {
                    filteredData.forEach { session ->
                        CoachAssessmentSession(
                            data = listOf(session),
                            isSelected = session == selectedSession,
                            onClick = {
                                selectedSession = session
                                onSessionSelect(session)
                            },
                            selectedSessionType = sessionDataType
                        )
                    }
                } else {
                        FieldTitleTextNormalSimple(
                            text = "No Sessions Found",
                            fontSize = 16.px,
                            width = 100.percent,
                            fontWeight = 500,
                            textAlign = TextAlign.Center
                        )
                }
            }
        }
    }
}


@Composable
fun CoachAssessmentSession(
    data: List<GetSessionsData>,
    isSelected: Boolean,
    onClick: () -> Unit,
    selectedSessionType: SelectedData
) {
    val sitePalette = ColorMode.current.toSitePalette()
    var currentlyPlayingId by remember { mutableStateOf<String?>(null) }
    var loadingSessionId by remember { mutableStateOf<String?>(null) }
    val currentAudioRef = remember { mutableStateOf<Audio?>(null) }

    data.forEach { sessionData ->
        val filterData = when (selectedSessionType) {
            SelectedData.Training -> sessionData.sessionType == "training"
            SelectedData.Matches -> sessionData.sessionType == "match"
        }

        if (filterData && sessionData.organization == AppGlobal.userData.organization) {
            println("organization ${sessionData.organization}")
            println("Global Organization ${AppGlobal.userData.organization}")
            val sessionId = sessionData.sessionId
            val parts = sessionData.sessionId.removeSuffix(".aac").split("_")
            val sessionName = parts.first()
            val audio = remember { Audio("${Res.Audio.base_Audio_URL}${sessionData.sessionId}") }
            var audioProgress by remember { mutableStateOf(0f) }
            val downloadAudio: () -> Unit = {
                val audioUrl = "${Res.Audio.base_Audio_URL}${sessionData.sessionId}"
                val downloadLink = document.createElement("a") as HTMLAnchorElement
                downloadLink.href = audioUrl
                downloadLink.download = "${sessionName}_${sessionData.sessionDateTime}.aac"
                document.body?.appendChild(downloadLink)
                downloadLink.click()
                document.body?.removeChild(downloadLink)
            }

            DisposableEffect(Unit) {
                onDispose {
                    if (currentlyPlayingId == sessionData.sessionId) {
                        audio.pause()
                        currentlyPlayingId = null
                        audioProgress = 0f
                    }
                }
            }

            LaunchedEffect(currentlyPlayingId) {
                if (currentlyPlayingId != null) {
                    val audioPlay = currentAudioRef.value
                    audioPlay?.apply {
                        while (currentTime < duration) {
                            audioProgress = (currentTime / duration).toFloat()
                            delay(100)
                        }
                    }
                }
            }

            Box(
                modifier = Modifier.fillMaxWidth().borderRadius(8.px)
                    .backgroundColor(if (isSelected) sitePalette.selectedSessionBackground.rgb else Colors.Transparent)
                    .padding(topBottom = 12.px, leftRight = 8.px).onClick {
                        onClick()
                    }.border(
                        2.px,
                        style = LineStyle.Solid,
                        color = if (isSelected) sitePalette.primary.rgb else Colors.Transparent
                    )
            ) {

                Column(
                    modifier = Modifier.fillMaxSize()
                ) {
                    FieldTitleTextNormalSimple(
                        text = sessionName, fontSize = 16.px, fontWeight = 500, marginBottom = 20.px
                    )

                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceBetween
                    ) {
                        FieldTitleTextNormalSimple(
                            text = sessionData.coachName, width = 110.px
                        )

                        Row(
                            modifier = Modifier.width(100.px),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Image(src = Res.Images.timeCircle, modifier = Modifier.size(17.px))
                            CustomSpacer(5.px)

                            FieldTitleTextNormalSimple(sessionData.recordTime.toLongOrNull()?.let {
                                (it / 1000).formatSessionMinutes()
                            } ?: "Invalid Time", marginRight = 3.px)
                        }

                        Row(
                            modifier = Modifier.width(150.px),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Image(src = Res.Images.calender, modifier = Modifier.size(17.px))
                            CustomSpacer(5.px)
                            FieldTitleTextNormalSimple(
                                "${sessionData.sessionDateTime.formatSessionDate()} ${sessionData.sessionDateTime.formatSessionHours()}"
                            )
                        }
                        Row(
                            modifier = Modifier.margin(top = (-30).px),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Box(
                                modifier = Modifier.width(30.px).height(30.px).borderRadius(100.px)
                                    .margin(right = 10.px), contentAlignment = Alignment.Center
                            ) {
                                Image(src = Res.Images.downloadPrimary,
                                    modifier = Modifier.size(26.px).cursor(Cursor.Pointer)
                                        .onClick { downloadAudio() })
                            }
//                                if (loadingSessionId == sessionData.sessionId) {
//                                    Box(contentAlignment = Alignment.Center) {
//                                        Image(
//                                            src = Res.Images.blueCircle,
//                                            modifier = Modifier.size(30.px).borderRadius(100.px)
//                                        )
//                                        ProgressIndicator(10.px)
//                                    }
//                                } else if (currentlyPlayingId == sessionData.sessionId) {
//                                    Image(
//                                        src = Res.Images.pausebutton,
//                                        modifier = Modifier.size(30.px)
//                                            .cursor(Cursor.Pointer)
//                                            .onClick {
//                                                audio.pause()
//                                                currentlyPlayingId = null
//                                                currentAudioRef.value = null
//                                                audioProgress = 0f
//                                            }
//                                    )
//                                } else {
//                                    Image(
//                                        src = Res.Images.playbutton,
//                                        modifier = Modifier.size(30.px)
//                                            .cursor(Cursor.Pointer)
//                                            .onClick {
//
//                                                currentAudioRef.value?.pause()
//                                                loadingSessionId = sessionId
//
//                                                audio.onloadeddata = {
//                                                    loadingSessionId = null
//                                                    audio.play()
//                                                    currentlyPlayingId = sessionId
//                                                    audio.also { currentAudioRef.value = it }
//                                                }
//                                                audio.load()
//                                            }
//                                    )
//                                }
                        }
                    }
                    Row(
                        modifier = Modifier.fillMaxWidth().margin(topBottom = 10.px),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        FaVideo(
                            modifier = Modifier.color(sitePalette.primary.rgb).width(15.px)
                                .height(12.px)
                        )
                        CustomSpacer(5.px)
                        FieldTitleTextNormalSimple(
                            text = "Session_video.mp4",
                            fontSize = 12.px,
                            fontFamily = Constants.IBM_FONT,
                            marginRight = 7.px,
                            marginLeft = 7.px
                        )
                        if (sessionData.videoFile.isNotEmpty()) Image(src = Res.Images.checkmark)
                        else Image(src = Res.Images.cross_Red, modifier = Modifier.size(12.px))

                    }
                    if (currentlyPlayingId == sessionData.sessionId) {
                        Row(
                            modifier = Modifier.fillMaxWidth().margin(topBottom = 4.px),
                            horizontalArrangement = Arrangement.End
                        ) {
                            AudioProgressSliderCustom(
                                progress = audioProgress,
                                onProgressChange = { newProgress ->
                                    audioProgress = newProgress
                                    currentAudioRef.value?.currentTime =
                                        (currentAudioRef.value?.duration ?: 0.0) * newProgress
                                },
                            )
                        }
                    }
                    if (!isSelected) {
                        Divider()
                    }
                }
            }
        }
    }
}

