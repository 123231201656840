package org.example.zone360.pages.coachDetails.charts

import JsonConfig
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.toAttrs
import kotlinx.browser.document
import org.example.zone360.models.GetSessionsData
import org.example.zone360.models.SessionDataDetailsResponse
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Canvas
import org.w3c.dom.HTMLCanvasElement


@Composable
fun RadarChartPage(sessionData: GetSessionsData) {
    var datasets by remember { mutableStateOf(emptyList<Pair<List<Int>, String>>()) }
    var isDataReady by remember { mutableStateOf(false) }
    val obj = JsonConfig.default.decodeFromString<SessionDataDetailsResponse>(sessionData.sessionData)
    val emotionAnalysis = obj.response.emotionAnalysis

    // Static x-axis labels
    val categories = listOf("\uD83D\uDE14 Sadness", "\uD83E\uDD29 Joy", "\uD83D\uDE21 Anger", "\uD83D\uDE16 Disgust", "\uD83D\uDE31 Fear")

    LaunchedEffect(Unit) {
        val emotionData = emotionAnalysis.emotionPredictions.map { prediction ->
            val emotions = listOf(
                (prediction.emotion.sadness * 100).toInt(),
                (prediction.emotion.joy * 100).toInt(),
                (prediction.emotion.anger * 100).toInt(),
                (prediction.emotion.disgust * 100).toInt(),
                (prediction.emotion.fear * 100).toInt()
            )
            emotions to "#3FA1FFE5"
        }

        datasets = emotionData
        isDataReady = true
    }


    Canvas(
        Modifier.fillMaxWidth()
            .height(250.px)
            .id("radarChart")
            .toAttrs()
    ) {}

    LaunchedEffect(Unit) {
        val canvas = document.getElementById("radarChart") as? HTMLCanvasElement
        if (canvas != null) {
            val dataArrays = datasets.joinToString(",\n") { dataset ->
                """
                {
                    data: ${dataset.first}, 
                    fill: true,
                    backgroundColor: '${dataset.second}',
                    pointBackgroundColor: '#FAA002'
                }
                """.trimIndent()
            }

            val script = """
                if (window.categories === undefined) {
                    window.categories = [${categories.joinToString(",") { "\"$it\"" }}];
                }

                new Chart("radarChart", {
                    type: "radar",
                    data: {
                        labels: window.categories,
                        datasets: [
                            $dataArrays
                        ]
                    },
                    options: {
                        legend: { display: false },
                        title: { display: false },
                        scales: {
                            r: { 
                                beginAtZero: false, 
                                pointLabels: { fontSize: 9 }
                            }
                        },
                        responsive: false,
                        maintainAspectRatio: true
                    }
                });
            """.trimIndent()

            val scriptElement = document.createElement("script")
            scriptElement.textContent = script
            scriptElement.setAttribute("id", "radarChartScript")
            val existingScript = document.getElementById("radarChartScript")
            if (existingScript == null) {
                document.body?.appendChild(scriptElement)
            } else {
                existingScript.replaceWith(scriptElement)
            }
        } else {
            console.error("Canvas element not found")
        }
    }
}




