package org.example.zone360.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.style.KobwebComposeStyleSheet.attr
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onFocusIn
import com.varabyte.kobweb.compose.ui.modifiers.onFocusOut
import com.varabyte.kobweb.compose.ui.modifiers.onKeyDown
import com.varabyte.kobweb.compose.ui.modifiers.onKeyUp
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaMagnifyingGlass
import com.varabyte.kobweb.silk.components.icons.fa.FaX
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.example.zone360.theme.toSitePalette
import org.example.zone360.utils.Constants.FONT_FAMILY
import org.example.zone360.utils.noBorder
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.HTMLInputElement

@Composable
fun SearchBar(
    placeholder: String = "",
    id: String,
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier,
    onEnterClick: () -> Unit,
    onChange: (String) -> Unit,
    onClick: () -> Unit,
    borderColor: com.varabyte.kobweb.compose.ui.graphics.Color? = null,
    width: CSSLengthOrPercentageNumericValue?,
    height: CSSLengthOrPercentageNumericValue? = 40.px,
    showClearButton: Boolean = true
) {
    var focused by remember { mutableStateOf(false) }
//    var showCrossButton by remember { mutableStateOf(false) }
    val sitePalette = ColorMode.current.toSitePalette()


//    if (text.isNotEmpty()) {
//        showCrossButton = true
//    }
    Row(
        modifier = modifier
            .width(width ?: 260.px)
            .padding(left = 20.px)
            .height(height!!)
            .backgroundColor(sitePalette.searchBackground.rgb)
            .borderRadius(r = 6.px)
            .border(
                width = 1.px,
                style = LineStyle.Solid,
                color = if (focused) sitePalette.primary.rgb
                else if (!focused) borderColor ?: Colors.Transparent
                else sitePalette.textFieldBorderColor.rgb
            )
            .transition(CSSTransition(property = "border", duration = 200.ms)),
        verticalAlignment = Alignment.CenterVertically
    ) {
        FaMagnifyingGlass(
            modifier = Modifier
                .margin(right = 14.px)
                .color(if (focused) sitePalette.primary.rgb else sitePalette.searchbarBorderColorPlaceholder.rgb)
                .transition(CSSTransition(property = "color", duration = 200.ms)),
            size = IconSize.LG
        )
        Input(
            type = InputType.Text,
            attrs = Modifier
                .id(id)
                .fillMaxSize()
                .fillMaxWidth()
                .color(sitePalette.textFieldTextColor.rgb)
                .backgroundColor(Colors.Transparent)
                .noBorder()
                .fontFamily(FONT_FAMILY).fontSize(13.px)
                .onFocusIn {
                    focused = true

                }
                .onFocusOut {
                    focused = false
                    val text1 =
                        (document.getElementById(id) as HTMLInputElement).value
                    println("onFocusOut: $text1")
                }
                .onKeyDown {
                    if (it.key == "Enter") {
                        onEnterClick()
                    }
                }.onKeyUp {
                    val text1 =
                        (document.getElementById(id) as HTMLInputElement).value
                    onChange(text1)
//                        showCrossButton = text1.isNotEmpty()
                }.onClick {
                    onClick()
                }

                .toAttrs {
                    attr("placeholder", placeholder)
                }
        )
        if(showClearButton){
            FaX(
                modifier = Modifier
                    .margin(right = 14.px)
                    .color(if (focused) sitePalette.primary.rgb else sitePalette.searchbarBorderColorPlaceholder.rgb)
                    .transition(CSSTransition(property = "color", duration = 200.ms)).cursor(
                        Cursor.Pointer
                    ).onClick {
                        (document.getElementById(id) as HTMLInputElement).value = ""
                        onChange("")
                    },
                size = IconSize.SM
            )
        }

    }

}